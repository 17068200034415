import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import DashboardWidget from "../../../DashboardWidget";
import {
    getManagementOnboardingLocation,
    resetOnboardingListingFilters,
    setLocationForListing
} from "../../../HomePageActions";

function OnboardingLocation(props) {
    const [locationState, setLocationState] = useState({
        onboardingLocation: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        const {profile} = props;
        const {advisoryId} = profile;

        setLocationState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getManagementOnboardingLocation(advisoryId).then(res => {
            if (res.success) {
                setLocationState(prevState => ({
                    ...prevState,
                    onboardingLocation: res.data,
                    isShowLoader: false,
                }));
            } else {
                setLocationState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetOnboardingListingFilters();
        setLocationForListing([item.key]);
        props.history.push('/onboardings');
    };


    return (
        <DashboardWidget
            {...props}
            {...locationState}
            detailsObj={locationState.onboardingLocation}
            setFilters={setFilters}
            title={"Summary by Location"}
        />
    );
}


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(OnboardingLocation);