export const stepperScreens = [
    {
        screenSequence: 100,
        screenLevel: 1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'UPLOAD_DOCUMENTS',
        screenName: 'Documents',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: false
    },
    {
        screenSequence: 200,
        screenLevel: 1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'PERSONAL_PROFILE',
        screenName: 'Personal Profile',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        screenSequence: 300,
        screenLevel: 1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'FINANCIAL_PROFILE',
        screenName: 'Financial Profile',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        parentScreenKey: '',
        screenSequence: 400,
        screenLevel: 1,
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'BANK_ACCOUNT',
        screenName: 'Bank Account',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        screenSequence: 500,
        screenLevel: 1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'NOMINEE',
        screenName: 'Nominees',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    }
];

export const  stepperJointAccountScreens     = [
    {
        screenSequence: 100,
        screenLevel: 1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'UPLOAD_DOCUMENTS',
        screenName: 'Documents',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: false
    },
    {
        screenSequence: 200,
        screenLevel:1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'PERSONAL_PROFILE',
        screenName: 'Personal Profile',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: false
    },
    {
        screenSequence: 210,
        screenLevel:2,
        parentScreenKey: 'PERSONAL_PROFILE',
        applicantKey: 1,
        applicantName: 'Applicant 1',
        screenKey: '',
        screenName: '',
        personId: '',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        screenSequence: 220,
        screenLevel:2,
        parentScreenKey: 'PERSONAL_PROFILE',
        applicantKey: 2,
        applicantName: 'Applicant 2',
        screenKey: '',
        screenName: '',
        personId: '',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        screenSequence: 300,
        screenLevel:1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        screenKey: 'FINANCIAL_PROFILE',
        screenName: 'Financial Profile',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        screenSequence: 400,
        screenLevel:1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'BANK_ACCOUNT',
        screenName: 'Bank Account',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        screenSequence: 500,
        screenLevel: 1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'NOMINEE',
        screenName: 'Nominee',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    }
];

export const  stepperMultipleJointAccountScreens     = [
    {
        screenSequence: 100,
        screenLevel: 1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'UPLOAD_DOCUMENTS',
        screenName: 'Documents',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: false
    },
    {
        screenSequence: 200,
        screenLevel:1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'PERSONAL_PROFILE',
        screenName: 'Personal Profile',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: false
    },
    {
        screenSequence: 210,
        screenLevel:2,
        parentScreenKey: 'PERSONAL_PROFILE',
        applicantKey: 1,
        applicantName: 'Applicant 1',
        screenKey: '',
        screenName: '',
        personId: '',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        screenSequence: 220,
        screenLevel:2,
        parentScreenKey: 'PERSONAL_PROFILE',
        applicantKey: 2,
        applicantName: 'Applicant 2',
        screenKey: '',
        screenName: '',
        personId: '',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        screenSequence: 230,
        screenLevel:2,
        parentScreenKey: 'PERSONAL_PROFILE',
        applicantKey: 2,
        applicantName: 'Applicant 3',
        screenKey: '',
        screenName: '',
        personId: '',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        screenSequence: 300,
        screenLevel:1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        screenKey: 'FINANCIAL_PROFILE',
        screenName: 'Financial Profile',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        screenSequence: 400,
        screenLevel:1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'BANK_ACCOUNT',
        screenName: 'Bank Account',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    },
    {
        screenSequence: 500,
        screenLevel: 1,
        parentScreenKey: '',
        applicantKey: '',
        applicantName: '',
        personId: '',
        screenKey: 'NOMINEE',
        screenName: 'Nominee',
        processInitiated: false,
        processCompleted: false,
        hasOwnUI: true
    }
];

export const modeOfHolding =[
    {label:'Anyone / Survivor', value:'ANYONE / SURVIVOR'},
    {label:'Either / Survivor', value:'EITHER / SURVIVOR'},
    {label:'Joint', value:'JOINT'},
    {label:'Single', value:'SINGLE'},
    {label:'Anyone / Survivor', value:'ANYONE / SURVIVOR', val: 'AS'},
    {label:'Either / Survivor', value:'EITHER / SURVIVOR', val:'ES'},
    {label:'Joint', value:'JOINT', val: 'JO'},
    {label:'Single', value:'SINGLE', val: 'SI'},
];

export const onboardingStatuses =[
    {label:'Activated', value:'ACTIVATED'},
    {label:'Awaiting client confirmation', value:'AWAITING_CONFIRMATION'},
    {label:'Draft', value:'DRAFT'},
    {label:'Not ready for submission', value:'NOT_READY_FOR_SUBMISSION'},
    {label:'NSE error', value:'ERRORED'},
    {label:'Pending activation', value:'CONFIRMED'},
    {label:'Ready for submission', value:'READY_FOR_SUBMISSION'},
    {label:'Submission failed', value:'SUBMISSION_FAILED'},
    {label:'Submission in progress', value:'SUBMISSION_IN_PROGRESS'}
];

export const cashFlowTypes =[
    {label:'Regular', value:'REGULAR'},
    {label:'Uneven', value: 'UNEVEN'}
];

export const financialBehaviours =[
    {label:'Saver', value:'SAVER'},
    {label:'Spender', value: 'SPENDER'}
];

export const detailOriented =[
    {label:'Yes', value: true},
    {label:'No', value: false}
];
