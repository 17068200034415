import React, {useState} from 'react';
import DLButton from "../../../../core/components/DLButton/DLButton";
import "./NomineeList.css"
import DLModal from "../../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLText from "../../../../core/components/DLText/DLText";
import DLModalActions from "../../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLLoader from "../../../../core/components/DLLoader/DLLoader";
import {removeNominee} from "../../../OnboardingActions";
import {isEmpty} from "../../../../utils/validations";

function DeleteAllNominee(props) {

    const {history, closeDeleteAllNomineeDialog, setNomineeOpted, isDeleteAllNomineeDialogOpen, nominees, getListing} = props;

    const [deleteAllNomineeState, setDeleteAllNomineeState] = useState({
        isShowLoader: false
    })

    const handleDeleteAllNominee = () => {
        const {profile, accountId} = props;
        let advisoryId = profile?.advisoryId;

        setDeleteAllNomineeState(() => ({
            isShowLoader: true
        }))

        if (!isEmpty(nominees)) {
            nominees.forEach((item) => {
                removeNominee(accountId, item?.id, advisoryId)
                    .then(res => {
                        if (res.success) {
                            console.log("Your Nominee is deleted");
                        }
                    });
            })
        }

        setTimeout(() => {
            setDeleteAllNomineeState(() => ({
                isShowLoader: false
            }))
            getListing()
            setNomineeOpted("NO")
            closeDeleteAllNomineeDialog()
        }, 800)
    };

    const renderDeleteModal = () => {
        return (
            <div>
                <DLModal
                    history={history}
                    maxWidth={"xs"}
                    onClose={() => closeDeleteAllNomineeDialog()}
                    open={isDeleteAllNomineeDialogOpen}
                    id={'delete-menu-modal'}>
                    <DLModalTitle
                        id={'delete-menu-title'}
                        onClose={() => closeDeleteAllNomineeDialog()}
                        title={'Confirmation'}/>
                    <DLModalContainer
                        id={'delete-menu-container'}>
                        <div className="remove-nominee">
                            <DLText id={'delete-menu-container'}
                                    text={"You have opted not to designate any nominees."}
                            />
                            <DLText id={'delete-menu-container'}
                                    text={"This will delete your existing nominees."}
                            />
                        </div>

                    </DLModalContainer>
                    <DLModalActions id={'remove-action'}>
                        <div className='mb-4' style={{paddingTop: '7px'}}>
                            <DLButton
                                id={'cancel-Btn'}
                                history={history}
                                label={'Cancel'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={"sm"}
                                onClick={() => closeDeleteAllNomineeDialog()}
                            />
                            <DLButton
                                id={'delete-Btn'}
                                history={props.history}
                                label={'Remove & Continue'}
                                buttonSize={"sm"}
                                fontSize={"md"}
                                sideMargin={"sm"}
                                style={{
                                    background: "var(--danger)",
                                    color: "var(--white)",
                                }}
                                onClick={() => {
                                    handleDeleteAllNominee()
                                }}
                            />
                        </div>
                    </DLModalActions>
                </DLModal>
            </div>
        );
    };

    const renderLoader = () => {
        const {isShowLoader} = deleteAllNomineeState;
        return (
            <DLLoader type={"screen"} isVisible={isShowLoader}/>
        )
    }

    return (
        <div>
            {renderLoader()}
            {renderDeleteModal()}
        </div>

    );
}

export default DeleteAllNominee;