import React from 'react';
import DLButton from "../../../core/components/DLButton/DLButton";
import {isEmpty} from "../../../core/components/DLComponentHelper";
import DLMenuOptions from "../../../core/components/DLMenuOptions/DLMenuOptions";
import DLTable from "../../../core/components/DLTable/DLTable";
import {cellConfig, headingData, cellConfigMobile, cellConfigTab} from "./NomineeModal";
import './nomineeList/NomineeList.css';
import {headingMobileData} from "./NomineeModal";
import moment from "moment";
import RemoveNominee from "./nomineeList/RemoveNominee";
import DLText from "../../../core/components/DLText/DLText";
import NomineeCreateOrUpdate from "./NomineeCreateOrUpdate";
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLModalActions from "../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLRadioGroup from "../../../core/components/DLRadioGroup/DLRadioGroup";
import DeleteAllNominee from "./nomineeList/DeleteAllNominee";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const NomineeListView = (props) => {
    const {
        listingCount, nominees, isMobileView, isDeleteNomineeDialogOpen, isAddNomineeModalOpen,
        isFromOnboardingFlow, setNomineeOpted, isNomineeOpted, isDeleteAllNomineeDialogOpen, isShowLoader
    } = props;


    const nomineeMobileNameSection = (nominee) => {
        return(
            <div className="py-2">
                <div>
                    {nominee.name}
                </div>
                <div>
                    {nominee.relation}
                </div>
            </div>
        )
    };


    const renderNomineeOptionMenu = (id) => {
        const {selectedOption} = props;
        return <div className="d-flex justify-content-center">
            <div className="px-3">
                <DLMenuOptions
                    onSelect={(value) => selectedOption(value, id)}
                    options={[{label: "Edit", value: "EDIT"}, {label: "Remove", value: "DELETE"}]}
                    id={"nomineeMenuOption"}
                />
            </div>
        </div>
    };

    const minorFunction = (date, type) => {
        if (isEmpty(date)) {
            return '';
        }
        return (
            <div className={isMobileView? "py-2" : "d-flex align-content-center"}>
                <div className="m-1 subtext">{date}</div>
                {type === "Y" &&
                <div className={"minor-button rounded-4 d-flex justify-content-center  px-2"}>
                    <span className="d-flex align-items-center">Minor</span>
                </div>
                }
            </div>
        )
    };

    const getPANNumber = (nominee) => {
        if (nominee.type === "Y") {
            return '-';
        } else {
            return nominee?.PANNumber;
        }
    }

    const getFormattedTableData = (nominees) => {
        let tableData = [];
        const {isXSView} = props;
        if (isEmpty(nominees)) {
            return tableData
        }

        nominees.map((nominee) => {
            let nomineeRecords = [];
            let nomineeDetails = {};
            if (!isXSView) {
                nomineeRecords.push(nominee.name);
                nomineeRecords.push({
                    isCustomUI: true,
                    customUI: minorFunction(nominee?.dateOfBirth ? moment(nominee?.dateOfBirth).format("DD-MM-YYYY") : '', nominee.type)
                });
                nomineeRecords.push(getPANNumber(nominee));
                nomineeRecords.push(nominee.relation);
                nomineeRecords.push(nominee.percentageAllocation);
                nomineeRecords.push({
                    isCustomUI: true,
                    customUI: renderNomineeOptionMenu(nominee.id)
                });

            } else {
                // Rendering custom ui here for the below column
                nomineeRecords.push({
                    isCustomUI: true,
                    customUI: nominee.firstName
                });
                nomineeRecords.push(nominee.firstName);
            }

            nomineeDetails.id = nominee.id;
            nomineeDetails.data = nomineeRecords;
            tableData.push(nomineeDetails)
        });
        return tableData

    };

    const getFormattedMobileTableData = (nominees) => {
        let tableData = [];
        if (isEmpty(nominees)) {
            return tableData
        }

        nominees.map((nominee) => {
            let nomineeRecords = [];
            let nomineeDetails = {};
            nomineeRecords.push({
                isCustomUI: true,
                customUI: nomineeMobileNameSection(nominee)
            });
            nomineeRecords.push({
                isCustomUI: true,
                customUI: minorFunction(nominee?.dateOfBirth ? moment(nominee?.dateOfBirth).format("DD-MM-YYYY") : '', nominee.type)
            });
            nomineeRecords.push(nominee.percentageAllocation);
            nomineeRecords.push({
                isCustomUI: true,
                customUI: renderNomineeOptionMenu(nominee.id)
            });


            nomineeDetails.id = nominee.id;
            nomineeDetails.data = nomineeRecords;
            tableData.push(nomineeDetails)
        });
        return tableData

    };

    const renderMobileTable = () => {

        const {setSelectedNominee, isShowFilterMobile, isXSView} = props;
        let tableData = getFormattedMobileTableData(nominees);
        return (
            <div>
                <div className="w-100 pagination-container">
                    <div>
                        <DLTable
                            id={''}
                            tableMaxHeight={isShowFilterMobile ? 'auto' : 'auto'}
                            tableMinHeight={isShowFilterMobile ? 'auto' : 'auto'}
                            isRowClickable={true}
                            onRowClick={(row) => {
                                setSelectedNominee(row)
                            }}
                            // onRowClick={}
                            cellConfig={isXSView ? cellConfigMobile : cellConfigTab}
                            headerData={headingMobileData}
                            tableData={tableData}
                            isShowPagination={false}
                        />
                    </div>
                </div>
                {renderButtonUi()}
            </div>
        )
    };

    const renderNomineeRadioOption = () => {
        const {openDeleteAllNomineeDialog} = props;
        if(isMobileView){
            return (
                <div>
                    <div className={"mb-3"} style={{paddingLeft: 10}}>
                        <DLRadioGroup
                            id={'hours-radioBtn'}
                            options={[
                                {
                                    label: 'Yes, I want to nominate someone',
                                    value: 'YES',
                                }]}
                            label={''}
                            value={isNomineeOpted}
                            helperMessage={"Proceed to assign a nominee for your account."}
                            fontWeight={"semi-bold"}
                            type={'inline'}
                            labelFontSize={'sm'}
                            removePadding={true}
                            labelFontColor={'black'}
                            onChangeValue={(e) => {
                                setNomineeOpted(e)
                            }}
                            minHeight={"30"}
                            disableHelperMessageHeight={true}
                            disableDescriptionMessageHeight={true}
                        />
                    </div>
                    <div className={"mb-3"} style={{paddingLeft: 10}}>
                        <DLRadioGroup
                            id={'hours-radioBtn'}
                            options={[
                                {
                                    label: 'No, I’ll do this later',
                                    value: 'NO',
                                }]}
                            label={''}
                            value={isNomineeOpted}
                            removePadding={true}
                            type={'inline'}
                            labelFontSize={'sm'}
                            fontWeight={"semi-bold"}
                            labelFontColor={'black'}
                            helperMessage={"You can always set up a nomination at a later time from your account settings."}
                            onChangeValue={() => {
                                openDeleteAllNomineeDialog()
                            }}
                            minHeight={"30"}
                            disableHelperMessageHeight={true}
                            disableDescriptionMessageHeight={true}
                        />
                    </div>
                </div>
            )
        }
        return (
            <div className='d-flex'>
                <div style={{paddingLeft: 10}}>
                    <DLRadioGroup
                        id={'hours-radioBtn'}
                        options={[
                            {
                                label: 'Yes, I want to nominate someone',
                                value: 'YES',
                            }]}
                        label={''}
                        value={isNomineeOpted}
                        helperMessage={"Proceed to assign a nominee for your account."}
                        fontWeight={"semi-bold"}
                        type={'inline'}
                        labelFontSize={'sm'}
                        removePadding={true}
                        labelFontColor={'black'}
                        onChangeValue={(e) => {
                            setNomineeOpted(e)
                        }}
                        minHeight={"30"}
                        disableHelperMessageHeight={true}
                        disableDescriptionMessageHeight={true}
                    />
                </div>
                <div className='ps-5'>
                    <DLRadioGroup
                        id={'hours-radioBtn'}
                        options={[
                            {
                                label: 'No, I’ll do this later',
                                value: 'NO',
                            }]}
                        label={''}
                        value={isNomineeOpted}
                        removePadding={true}
                        type={'inline'}
                        labelFontSize={'sm'}
                        fontWeight={"semi-bold"}
                        labelFontColor={'black'}
                        helperMessage={"You can always set up a nomination at a later time from your account settings."}
                        onChangeValue={() => {
                            openDeleteAllNomineeDialog()
                        }}
                        minHeight={"30"}
                        disableHelperMessageHeight={true}
                        disableDescriptionMessageHeight={true}
                    />
                </div>
            </div>
        )
    };

    const renderNomieeInfo = () => {
        return (
            <div className={isFromOnboardingFlow ? '' : 'mt-2 pt-1'}>
                <DLText id={'nominee-info-heading'}
                        text={'Secure Your Investments with a Nomination'}
                        fontWeight={"semi-bold"}
                        fontSize={"md"}
                        marginBottom={'sm'}
                />
                <DLText id={'nominee-info'}
                        text={'A nomination allows you to designate who will receive or manage your investments in case of any unexpected events.'}
                />
                <DLText id={'nominee-info'}
                        text={'This is an important step in ensuring your assets are handled according to your wishes.'}
                        marginBottom={'lg'}
                />
                <DLText id={'nominee-info'}
                        text={'Would you like to set up a nomination now?'}
                        marginBottom={'xs'}
                />
                <div>
                    {renderNomineeRadioOption()}
                </div>
            </div>
        )
    };


    const renderCount = () => {
        const {isXSView} = props;

        if (isXSView) {
            return <div className='d-flex justify-content-between align-items-center page-container'>
                <div className='count-label '>
                    {!isEmpty(listingCount) && 'Showing ' + (listingCount) + ' records'}
                </div>
            </div>
        }
        return <div className='count-label'>
            {!isEmpty(listingCount) && 'Showing ' + (listingCount) + ' records'}
        </div>

    };

    const renderDesktopTable = () => {
        const {isShowFilterMobile} = props;
        let tableData = getFormattedTableData(nominees);
        if (isEmpty(tableData)) {
            return (
                <div className='w-100 d-flex align-items-center justify-content-center panel'
                     style={{maxHeight: 'calc(100dvh - 301px)', minHeight: 'calc(100dvh - 346px)'}}>
                    No Data found.
                </div>
            )
        }
        return (
            <div className="w-100 ">
                <DLTable
                    id={''}
                    tableMaxHeight={isShowFilterMobile ? 'calc(100dvh - 486px)' : 'calc(100dvh - 331px)'}
                    tableMinHeight={isShowFilterMobile ? 'auto' : 'auto'}
                    isShowPagination={false}
                    // isRowClickable={true}
                    // onRowClick={(row) => {
                    //     handleArrowClick(row)
                    // }}
                    cellConfig={cellConfig}
                    headerData={headingData}
                    tableData={tableData}
                />

            </div>
        )
    };

    const renderHeaderButton = () => {
        const {handleOpenCreateModal, isMobileView, isXSView, profile} = props;
        const {isManagement} = profile;

        if (isXSView) {
            return (
                <div className={'d-flex justify-content-end mx-3 my-2'}>
                    {!isEmpty(nominees) && !isManagement &&
                    <DLButton
                        id={'create-button'}
                        styleType={'outlined'}
                        type={'icon'}
                        padding={'none'}
                        isDisabled={nominees?.length >= 3}
                        onClick={() => {
                            if (nominees?.length < 3) {
                                handleOpenCreateModal()
                            }
                        }}
                        startIcon={<i className="fa-solid fa-plus"/>}
                    />
                    }
                </div>
            );
        }
        return (
            <div className={'d-flex my-3 gap-0'}>
                {!isManagement &&
                <DLButton
                    id={'delete-button'}
                    buttonSize={'sm'}
                    reduceWidth={isMobileView}
                    fontSize={'sm'}
                    isDisabled={nominees?.length >= 3}
                    sideMargin={'none'}
                    type={"black"}
                    padding={'none'}
                    styleType={"outlined"}
                    backgroundColor={"grey"}
                    onClick={() => {
                        handleOpenCreateModal()
                    }}
                    label={"Add Nominee"}/>}
            </div>
        )
    };

    const renderHeaderMessageAndButton = () => {
        return (
            <div>
                {!isEmpty(nominees) &&
                <div className='d-flex justify-content-between align-items-center'>
                    <div className='my-3'>
                        <DLText id={''}
                                text={"Nominees"}
                                fontSize={'lg'}
                                fontWeight={"semi-bold"}
                                marginBottom={"none"}
                        />
                    </div>
                    <div>
                        {renderHeaderButton()}
                    </div>
                </div>
                }
            </div>
        )
    };

    const renderRemoveNomineeModal = () => {
        if (isDeleteNomineeDialogOpen) {
            return (
                <RemoveNominee
                    {...props}
                    history={props.history}/>
            );
        }
    };

    const renderDeleteAllNomineeModal = () => {
        if (isDeleteAllNomineeDialogOpen) {
            return (
                <DeleteAllNominee
                    {...props}
                    history={props.history}/>
            );
        }
    };

    const renderNomineeTable = () => {
        if (isMobileView) {
            return (
                <div>
                    {renderCount()}
                    {renderMobileTable()}
                </div>
            )
        }
        return (
            <div>
                {renderCount()}
                {renderDesktopTable()}
            </div>
        )
    };

    const renderButtonUi = () => {
        const {handleOpenFinishDialog, isMobileView, isFromOnboardingFlow} = props;
        return (
            <div className='sticky-bottom-buttons justify-content-end'
                 style={{zIndex: 100}}>
                <DLButton id={'delete-button'}
                          buttonSize={'sm'}
                          fontSize={'sm'}
                          sideMargin={'none'}
                          hidden={!isFromOnboardingFlow}
                          style={{
                              width: isMobileView ? '154px' : '',
                              marginleft: isMobileView ? '8px' : '',
                              marginRight: isMobileView ? '16px' : '',
                              background: "var(--black)",
                              color: "var(--white)",
                              fontWeight: '500'
                          }}
                          onClick={() => {
                              handleOpenFinishDialog()
                          }}
                          label={"Finish"}/>
            </div>
        )
    };

    const renderUi = () => {
        if (isMobileView) {
            return (
                <div>
                    {renderNomineeTable()}
                </div>
            );
        }

        return (
            <div className={"d-flex"}>
                <div className={'w-100 h-100'}>
                    {renderNomineeTable()}
                </div>
            </div>
        );
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className='my-3'>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='py-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='py-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    const renderFinishModalUI = () => {

        const {isFinishDialogOpen, handleCloseFinishDialog, isXSView, onboardingObj, redirectToDetails} = props;
        return (
            <div>
                <DLModal
                    history={props.history}
                    maxWidth={"sm"}
                    onClose={() =>
                        handleCloseFinishDialog()
                    }
                    open={isFinishDialogOpen}
                    id={'kyc-modal'}>
                    <DLModalTitle
                        id={'kyc-title'}
                        onClose={() =>
                            handleCloseFinishDialog()
                        }
                        title={'Onboarding Complete!'}/>
                    <DLModalContainer
                        id={'kyc-container'}>
                        <div className='text-center px-4'>
                            <div className='pb-4'>
                                <DLText id={''}
                                        text={'Thank you for updating onboarding details.'}
                                        isInline={true}
                                />
                            </div>
                            <DLText id={''}
                                    text={'If you would like to review the information now, click "Review Now". You can also return to the listing screen and review it later.'}
                            />
                        </div>
                    </DLModalContainer>
                    <DLModalActions id='add-holder-action'>
                        <div className='mt-2 mb-4' style={{paddingTop: '7px', minHeight: '43px'}}>
                            <DLButton
                                id={'add-holder-cancelBtn'}
                                history={props.history}
                                label={'Return to Listing '}
                                buttonSize={"sm"}
                                styleType={"outlined"}
                                backgroundColor={"grey"}
                                sideMargin={isXSView ? 'none' : "sm"}
                                onClick={() => {
                                    redirectToDetails('/onboardings')
                                }}/>
                            <DLButton
                                id={'add-holder-saveBtn'}
                                history={props.history}
                                label={'Review Now'}
                                buttonSize={"sm"}
                                style={{minWidth: isXSView ? 140 : 166}}
                                sideMargin={"sm"}
                                onClick={() => {
                                    if (!isEmpty(onboardingObj?.onboardingApplicationId)) {
                                        redirectToDetails('/onboardings/' + onboardingObj?.onboardingApplicationId);
                                    }
                                }}/>
                        </div>
                    </DLModalActions>

                </DLModal>
            </div>
        );
    };

    const renderLoader = () =>{
        return (
            <DLLoader isVisible={isShowLoader} type={'screen'}/>
        )
    }

    const emptyList = () => {
        const {handleOpenCreateModal, profile} = props;
        const {isManagement} = profile;
        return (
            <div>
                {renderErrorUI()}
                <div>
                    {renderNomieeInfo()}
                </div>
                {isNomineeOpted === "YES" && !isManagement && <div>
                    <div className={'d-flex my-3 gap-0'}>
                        <DLButton
                            id={'delete-button'}
                            buttonSize={'sm'}
                            reduceWidth={isMobileView}
                            fontSize={'sm'}
                            sideMargin={'none'}
                            type={"black"}
                            padding={'none'}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            onClick={() => {
                                handleOpenCreateModal()
                            }}
                            label={"Add Nominee"}/>
                    </div>
                </div>}
            </div>
        )
    };

    if (isAddNomineeModalOpen) {
        return (
            <NomineeCreateOrUpdate
                {...props}
            />
        )
    }

    if (!nominees) {
        return (
            <div className={'page-container'}>
                <div className={'h-100 pt-4 mt-2'}>
                    <div style={{
                        minHeight: isFromOnboardingFlow ? 'calc(100dvh - 200px)' : 'calc(100dvh - 168px)',
                        maxHeight: 'calc(100dvh - 886px)',
                        overflowY: 'auto'
                    }}>
                        {renderFinishModalUI()}
                        {emptyList()}
                        {renderButtonUi()}
                        {renderDeleteAllNomineeModal()}
                        {renderLoader()}
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={isMobileView? 'block-padding' : 'page-container'}>
            <div>
                <div style={{
                    minHeight: isFromOnboardingFlow ? 'calc(100dvh - 100px)' : 'calc(100dvh - 168px)',
                    overflowY: 'auto'
                }}>
                    {renderErrorUI()}
                    {renderNomieeInfo()}
                    {renderHeaderMessageAndButton()}
                    {renderUi()}
                    {renderButtonUi()}
                    {renderRemoveNomineeModal()}
                    {renderFinishModalUI()}
                    {renderDeleteAllNomineeModal()}
                    {renderLoader()}
                </div>
            </div>
        </div>
    );
};

export default NomineeListView;