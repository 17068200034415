import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {stepperJointAccountScreens, stepperMultipleJointAccountScreens, stepperScreens} from "../../OnboardingConsts";
import store from '../../../store';

import {isEmpty} from "../../../utils/validations";
import {showToastMessage} from "../../../AppComponents/AppActions";
import {
    getAllRequiredDocuments,
    getOnboardingDetails,
    getProceedWithErrors,
    setProceedWithErrors
} from "../../OnboardingActions";

import OnboardingWizardView from "./OnboardingWizardView";
import {setAccountId, setAccountType, setOnboardingData} from "../../onboardingFlowReducer";


const OnboardingWizard = (props) => {
    const {accountType} = props;
    const [onboardingWizardObj, setOnboardingWizardObj] = useState({
        screenDetails: '',
        selectedScreenObj: {},
        stepperSteps : [],
        totalApplicants: [],
        isProceedWithErrors: false,
        accountType: accountType,
        isFromOnboardingFlow: true,
        saveFailureErrorMessage : 'Your data could not be saved due to the errors displayed.',
        isShowLoader: false
    });

    useEffect(() => {
        getOnboardingApplicationDetails();
    }, []);

    const getApplicationId = () => {
        return props.match?.params?.onboardingApplicationId
    };

    const setAccountData = (resData) =>{
        store.dispatch(setOnboardingData(resData?.account));
        store.dispatch(setAccountId(resData?.account?.id));
        store.dispatch(setAccountType(resData?.account?.accountType));
    };


    const getOnboardingApplicationDetails = () =>{
        let applicationId = getApplicationId();
        const {profile} = props;

        if(isEmpty(applicationId)){
            return
        }
        setOnboardingWizardObj((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        getOnboardingDetails(applicationId, profile.advisoryId)
            .then((res) => {

                if (res?.success) {
                    getProceedWithErrorsVal(applicationId);
                    getRequiredDocuments(res?.data?.account?.id, res?.data?.account?.accountType);
                    setAccountData(res?.data)
                }else{
                    setOnboardingWizardObj((prevState) => ({
                        ...prevState,
                        isShowLoader: false
                    }));
                }
            })
    };

    const getProceedWithErrorsVal=(applicationId)=>{
        const {profile} = props;

        getProceedWithErrors(applicationId,profile.advisoryId)
            .then(res=>{
                if(res.success){
                    setOnboardingWizardObj(prevStep => ({
                        ...prevStep,
                        isProceedWithErrors: res.data.shouldProceedWithErrors
                    }));
                }else{
                    store.dispatch(showToastMessage('danger',res.__error))
                }
            });
    };

    const addDocumentsToState = (allDocuments, accountType) =>{


        let commonDocs = allDocuments?.accountDocuments;
        let documentByPerson = allDocuments?.accountHolderDocuments;

        let applicantDocuments = [];
        documentByPerson.map((person, personId) => {
            //add document by person

            let documentsRequired = person?.documentsRequired;
            let docPersonId = person.personId;

            if(isEmpty(documentsRequired)){
                return
            }
            documentsRequired.sort((a, b) => a.id - b.id); //sort document by id from backend

            documentsRequired.map((doc, index) => {
                applicantDocuments.push({
                    screenSequence: 110 + index * 10,
                    screenKey: doc.documentCategory.id,
                    screenName: doc.documentCategory.name,
                    screenLevel:3,
                    personId: docPersonId,
                    parentScreenKey: 'UPLOAD_DOCUMENTS',
                    applicantKey: (personId+1),
                    applicantName: 'Applicant '+(personId+1),
                    processInitiated: false,
                    processCompleted: false,
                    hasOwnUI: true,
                    documentRequirementId : doc.id
                })

            });
        });

        let personDocumentsLastId = 110 + (applicantDocuments.length) * 10;

        const commonDocuments = commonDocs.map((item, index) => {
            //add common documents
            return {
                screenSequence: personDocumentsLastId + index * 10,
                screenKey: item.documentCategory.id,
                screenName: item.documentCategory.name,
                screenLevel:3,
                personId: '',
                parentScreenKey: 'UPLOAD_DOCUMENTS',
                applicantKey: '',
                applicantName: 'Common',
                processInitiated: false,
                processCompleted: false,
                hasOwnUI: true,
                documentRequirementId : item.id
            };
        });

        let newObjects = applicantDocuments.concat(commonDocuments);

        if (newObjects.length > 0) {
            newObjects[0].processInitiated = true;
            for (let i = 1; i < newObjects.length; i++) {
                newObjects[i].processInitiated = false;
            }
        }

        // Find index of the "UPLOAD_DOCUMENTS" object
        let newScreenDetails = (accountType === "INDIVIDUAL") ? stepperScreens :
            (documentByPerson?.length === 2 || isEmpty(documentByPerson)) ? stepperJointAccountScreens : stepperMultipleJointAccountScreens;

        let updatedArray = [];

        let selectedScreen = {};
        for(let i = 0; i< newScreenDetails.length; i++) {
            if(newScreenDetails[i].screenKey === "UPLOAD_DOCUMENTS") {
                let obj = newScreenDetails[i];
                if(i === 0){
                    selectedScreen = newObjects[0];
                    obj.processInitiated = true;
                    obj.processCompleted= false;
                }
                updatedArray.push(obj);
                updatedArray = updatedArray.concat(newObjects);

            } else {
                let obj = newScreenDetails[i];
                obj.processInitiated = (i === 0);
                obj.processCompleted= false;
                updatedArray.push(obj);
                if(i === 0){
                    selectedScreen = newScreenDetails[i]
                }
            }
        }

        let updatedScreenDetails =  addPersonIdToProfile(updatedArray, documentByPerson, accountType);
        const uniqueApplicantNames = [...new Set(updatedScreenDetails.map(item => (item.applicantName)).filter(name => name))];

        setOnboardingWizardObj(prevState => ({
            ...prevState,
            screenDetails: updatedScreenDetails,
            selectedScreenObj: selectedScreen,
            stepperSteps : getCreateStepperArray(updatedScreenDetails),
            isShowLoader: false,
            totalApplicants: uniqueApplicantNames
        }));
    };

    const addPersonIdToProfile = (allScreens, documentByPerson, accountType) =>{
        // Create a map of personId to their respective screenKeys

        let allPersonIds = [];
        documentByPerson.reduce((acc, person) => {
            person?.documentsRequired.forEach(doc => {
                allPersonIds.push(person.personId)
            });
            return acc;
        }, {});
        allPersonIds = [...new Set(allPersonIds)];
        // Update screens based on the personId map
        allScreens.forEach(screen => {
            let profilePersonId  = allPersonIds;
            if(accountType === 'INDIVIDUAL'){
                //logic to assign personId to Individual account
                if (screen.screenKey === 'PERSONAL_PROFILE') {
                    screen.personId = profilePersonId[0];
                    allPersonIds.shift(); //remove assign id from array
                }
            }else{
                //logic to assign personId to joint account
                if (screen.parentScreenKey === 'PERSONAL_PROFILE') {
                    screen.personId = profilePersonId[0];
                    profilePersonId.shift(); //remove assign id from array
                }
            }
        });
        return allScreens;
    };

    const getRequiredDocuments = (accountId, accountType) =>{
        const {profile} = props;
        if(isEmpty(accountId)){
            return
        }

        getAllRequiredDocuments(accountId, profile.advisoryId)
            .then((res) => {
                if (res?.success) {
                    addDocumentsToState(res?.data, accountType)
                } else {
                    let newScreenDetails = (accountType === "INDIVIDUAL") ? stepperScreens : stepperJointAccountScreens;
                    const selectedScreen = newScreenDetails.find(screen => !screen.processInitiated && screen.hasOwnUI);
                    updateOnboardingWizardObj({},selectedScreen, newScreenDetails)
                }
            })
    };
    const getCreateStepperArray = (screens) =>{
        return screens
            .filter(screen => isEmpty(screen.parentScreenKey))
            .map(screen => ({
                stepName: screen.screenName,
                // status: screen.processCompleted ? 'completed' : 'pending'
                status: screen.processInitiated ? 'IN_PROGRESS' : (screen.processCompleted ? 'SUCCESS' : 'PENDING' )
            }));
    };

    const handleClickSaveAndClose = () => {
        props.history.replace('/onboardings')
    };

    const updateOnboardingWizardObj = (prevSelectedScreen, currentSelectedScreen, screenDetails) => {

        if (!isEmpty(prevSelectedScreen)) { //finish previous step and mark complete
            const prevScreenIndex = screenDetails.findIndex(screen => (screen.screenKey === prevSelectedScreen.screenKey) && ((screen.applicantKey === prevSelectedScreen.applicantKey)));

            if (prevScreenIndex !== -1) {
                screenDetails[prevScreenIndex] = {
                    ...screenDetails[prevScreenIndex],
                    processInitiated: false,
                    processCompleted: true
                };
            }
        }


        if (!isEmpty(currentSelectedScreen)) {    //start next step
            currentSelectedScreen.processInitiated = true;

            const parentScreen = screenDetails.find(screen => (screen.screenKey === currentSelectedScreen.parentScreenKey));
            //to get parent of current step

            if (parentScreen) {   //mark parent of current step initiated if exist
                parentScreen.processInitiated = true;
            }

            setOnboardingWizardObj(prevState => ({
                ...prevState,
                screenDetails: [...screenDetails],
                selectedScreenObj: currentSelectedScreen
            }));
        }

        getMarkParentCompleted(prevSelectedScreen?.parentScreenKey, currentSelectedScreen?.parentScreenKey, screenDetails);
    };

    const getMarkParentCompleted = (prevParentKey, currentParentKey, screenDetails) => {
        //mark parent is completed if all childs process is completed


        if (isEmpty(prevParentKey) && isEmpty(currentParentKey)) {
            // if not parent mark curret step complete
            const currentScreenIndex = screenDetails.findIndex(screen => screen.screenKey === currentParentKey);
            if (currentScreenIndex !== -1) {
                screenDetails[currentScreenIndex].processCompleted = true;
            }
        }

        // get all Unique applicant names
        const uniqueApplicantNames = [...new Set(screenDetails.map(item => (item.applicantName)).filter(name => name))];

        if ((!isEmpty(prevParentKey) && isEmpty(currentParentKey)) || (!isEmpty(prevParentKey) && !isEmpty(currentParentKey) && (prevParentKey !== currentParentKey))) {
            // Update prev screen as complete
            const prevParentIndex = screenDetails.findIndex(screen => screen.screenKey === prevParentKey);
            if (prevParentIndex !== -1) {
                screenDetails[prevParentIndex].processCompleted = true;
                screenDetails[prevParentIndex].processInitiated = false;
            }
        }
        setOnboardingWizardObj(prevState => ({
            ...prevState,
            screenDetails: [...screenDetails],
            stepperSteps : getCreateStepperArray(screenDetails),
            totalApplicants : uniqueApplicantNames
        }));

    };

    const handleClickNext = () => {
        const {screenDetails, selectedScreenObj} = onboardingWizardObj;
        setOnboardingWizardObj((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));
        const currentIndex = screenDetails.indexOf(selectedScreenObj);

        let nextScreen = screenDetails[currentIndex + 1];

        while (nextScreen && nextScreen.hasOwnUI === false) {
            // Loop to find the next screen with hasOwnUI: true
            nextScreen = screenDetails[screenDetails.indexOf(nextScreen) + 1];
        }
        updateOnboardingWizardObj(selectedScreenObj, nextScreen, screenDetails);
        setTimeout(()=>{
            //added delay to show loader for data saving
            setOnboardingWizardObj((prevState) => ({
                ...prevState,
                isShowLoader: false
            }));
        },1000)
    };

    const getScreenKey = () =>{
        //get current parent step
        const{selectedScreenObj} = onboardingWizardObj;
        let screenKey = '';
        if(isEmpty(selectedScreenObj?.parentScreenKey)){
            screenKey =  isEmpty(selectedScreenObj?.screenKey) ? '' : selectedScreenObj?.screenKey
        }else{
            screenKey = selectedScreenObj?.parentScreenKey
        }
        return screenKey
    };

    const getSubScreenKey = () =>{
        //get current parent sub step
        const{selectedScreenObj} = onboardingWizardObj;
        let subScreenKey = '';
        if(!isEmpty(selectedScreenObj?.screenKey)){
            subScreenKey =  selectedScreenObj?.screenKey
        }
        return subScreenKey

    };
    const setProceedWithError = () =>{
        const {profile,onboardingObj} = props;

        const obj = {
            onboardingApplicationId: onboardingObj?.onboardingApplicationId,
            shouldProceedWithErrors: onboardingWizardObj.isProceedWithErrors,
        };

        setProceedWithErrors(obj, profile.advisoryId)
            .then(res => {
                if (res.success) {
                    setOnboardingWizardObj(prevStep => ({
                        ...prevStep,
                        isProceedWithErrors: !prevStep.isProceedWithErrors
                    }));
                    store.dispatch(showToastMessage('success','Proceed with error updated!'))
                } else {
                    store.dispatch(showToastMessage('danger',res.__error))
                }
            });

    };

    const getUplodedDocuments = (screenId, selectedPersonId) =>{
        const {profile, accountId} = props;

        if(isEmpty(accountId)){
            return
        }
        const {screenDetails} = onboardingWizardObj;
        let updatedScreenDetails = screenDetails;

        getAllRequiredDocuments(accountId, profile.advisoryId)
            .then((res) => {
                if (res?.success) {
                    let documentByPerson = res.data?.accountHolderDocuments;

                    documentByPerson.map((person) => {
                        //add document by person
                        let documentsRequired = person?.documentsRequired;
                        let currentDocPersonId = person?.personId;
                        if(!isEmpty(documentsRequired)){
                            documentsRequired.map((document) => {
                                let currentDocumentCategory = document?.documentCategory;
                                if(!isEmpty(currentDocumentCategory)){
                                    updatedScreenDetails.map((screen) => {
                                        if ((screen.screenKey === currentDocumentCategory.id) && (screen?.personId === currentDocPersonId)) { //associate all docs with respected object
                                            screen.documentTypes = currentDocumentCategory.documentTypes;
                                        }
                                    });
                                }

                            })
                        }
                    });

                    let commonDocs = res.data?.accountDocuments;
                    commonDocs.map((document) => {
                        let currentDocumentCategory = document?.documentCategory;
                        if(!isEmpty(currentDocumentCategory)){
                            updatedScreenDetails.map((screen) => {
                                if (screen.screenKey === document.documentCategory.id) {
                                    screen.documentTypes = document.documentCategory.documentTypes;
                                }
                            });
                        }
                    });

                    let selectedScreenObj =  updatedScreenDetails.find((screen) => ((screen?.screenKey === screenId)  && (screen?.personId === selectedPersonId)));

                    setOnboardingWizardObj(prevStep => ({
                        ...prevStep,
                        screenDetails: updatedScreenDetails,
                        selectedScreenObj: selectedScreenObj
                    }));
                }
            })

    };

    return (
        <OnboardingWizardView
            {...props}
            {...onboardingWizardObj}
            setOnboardingWizardObj={setOnboardingWizardObj}
            handleClickNext={handleClickNext}
            getScreenKey={getScreenKey}
            getSubScreenKey={getSubScreenKey}
            setProceedWithError={setProceedWithError}
            getUplodedDocuments={getUplodedDocuments}
            handleClickSaveAndClose={handleClickSaveAndClose}
        />
    );
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    onboardingObj: state.onboardingFlowState.onboardingObj,
    accountType: state.onboardingFlowState.accountType,
    accountId: state.onboardingFlowState.accountId,
});

export default connect(mapStateToProps, {})(OnboardingWizard);