import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import ViewActivitiesView from "./ViewActivitiesView";
import {isEmpty} from "../../../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../../../AppComponents/AppConstants";
import {getViewActivityData} from "../../../FormsAndReviewActions";
import {getOnboardingStatus} from "../../../../../OnboardingHelper";
import {viewActivityStatuses} from "../../../FormAndReviewModel";

const ViewActivities = (props) => {
    const [viewActivityState, setViewActivityState] = useState({
        events: [],
        isShowLoader: false,
        UIState: '',
    });
    const {isViewActivityModelOpen, CRMDetails} = props;

    useEffect(() => {
        getData()
    }, [isViewActivityModelOpen]);

    const getData = () => {

        const {profile} = props;
        let advisoryId = profile?.advisoryId;

        setViewActivityState((preState) => ({
                ...preState,
                isShowLoader: false,
            }
        ));
        getViewActivityData(props.formId, advisoryId)
            .then(res => {
                if (res?.success) {
                    setViewActivityState((preState) => ({
                            ...preState,
                            events: res?.data?.events,
                            isShowLoader: false,
                            UIState: APP_CONST.CONTENT_AVAILABLE,
                        }
                    ))
                } else {
                    setViewActivityState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                    }));
                }
            })
    };

    const getAdvisoryUser = (id) => {
        const usersName = CRMDetails.find((item) => {
            if (item?.id === id) {
                return item;
            }
        });
        if (isEmpty(usersName)) {
            return '';
        } else {
            let firstName = isEmpty(usersName?.firstName) ? '' : usersName?.firstName + ' ';
            let lastName = isEmpty(usersName?.lastName) ? '' : usersName?.lastName + ' ';
            return firstName + lastName;
        }
    };

    const getDateCategory = (date) => {
        const today = new Date();
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        const beforeYesterday = new Date(today);
        beforeYesterday.setDate(today.getDate() - 2);
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay());

        if (date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear()) {
            return 'Today';
        } else if (date.getDate() === yesterday.getDate() &&
            date.getMonth() === yesterday.getMonth() &&
            date.getFullYear() === yesterday.getFullYear()) {
            return 'Yesterday';
        } else if (date.getDate() === beforeYesterday.getDate() &&
            date.getMonth() === beforeYesterday.getMonth() &&
            date.getFullYear() === beforeYesterday.getFullYear()) {
            return 'Before Yesterday';
        } else if (date >= startOfWeek && date < today) {
            return 'Earlier This Week';
        } else {
            return 'Other';
        }
    };

    const getSentenceCase = (str) => {
        if(isEmpty(str)){
            return '-'
        }
        return getOnboardingStatus(str, viewActivityStatuses);
    };


    return (
        <ViewActivitiesView
            {...props}
            {...viewActivityState}
            getAdvisoryUser={getAdvisoryUser}
            getSentenceCase={getSentenceCase}
        />
    )
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    CRMDetails: state.onboardingFlowState.CRMDetails,
    isXSView: state.appState.deviceInfo.isXSView,
    isLGView: state.appState.deviceInfo.isLGView,
    accountId: state.onboardingFlowState.accountId
});

export default connect(mapStateToProps, {})(ViewActivities);