import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import AccountHolderCreateView from "./accountHolderCreateView";

import {isEmpty} from "../../../utils/helperCommon";

import {searchPersonByPAN} from "../../OnboardingActions";

const AccountHolderCreate = (props) => {
    const [accountHolder, setAccountHolder] = useState({
        isShowLoader: false,
        verifyPANResponse: {}, //PAN_Response
        isVerifyCalled: false, //initialState:true
        PAN_Number: '',
        PAN_NumberError: ''
    });

    useEffect(() => {
        //here destroying the modal at de-mounting
        if(!isEmpty(props.error)){
            setAccountHolder((prevStep) => ({
                ...prevStep,
                PAN_NumberError: props.error
            }));
        }
        return () => {
            props.closeCreateModal();
        };
    }, []);

    const verifyPAN = () => {
        const {profile, PANDetails} = props;
        const {PAN_Number} = accountHolder;

        const advisoryId = profile?.advisoryId;
        let panExists = false;
        if(!isEmpty(PAN_Number)){
            panExists = PANDetails.some(item => (item.PAN_Number).toUpperCase() === (PAN_Number).toUpperCase() && !isEmpty(PAN_Number));
        }
        if (isEmpty(PAN_Number) || panExists) {
            setAccountHolder((prevStep) => ({
                ...prevStep,
                PAN_NumberError: panExists ? 'Duplicate PAN number' : 'Please enter PAN number',
                isVerifyCalled: true,
                // verifyPANResponse: {}
            }));
            return
        }

        setAccountHolder((prevStep) => ({
            ...prevStep,
            isShowLoader: true,
            isVerifyCalled: true,
            verifyPANResponse: {}
        }));

        const searchObj = {
            PANNumber: PAN_Number.toUpperCase()
        };

        searchPersonByPAN(searchObj, advisoryId, true)
            .then((res) => {
                if (res.success) {
                    // const response = res.statusCode === 204 ? {existingPerson: false} : {};
                    setAccountHolder((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                        verifyPANResponse: isEmpty(res.data) ? {existingPerson: false} : {...res.data, existingPerson: true},
                    }));
                } else {
                    //statusCode >= 400 and < 500. >500 are handled in http helper
                    setAccountHolder((prevStep) => ({
                        ...prevStep,
                        isShowLoader: false,
                        PAN_NumberError: res.__error,
                        verifyPANResponse:{}
                    }));
                }
            })
    };

    const handleSelectOrAddPANDetails = () => {

        const {verifyPANResponse, PAN_Number} = accountHolder;
        const {handleAddPAN} = props;

        let PAN_Data = {...verifyPANResponse, PAN_Number: PAN_Number};
        setAccountHolder((prevStep) => ({
            ...prevStep,
            verifyPANResponse: {},
            PAN_Number: isEmpty(props.error) ? '' : accountHolder.PAN_Number,
            isVerifyCalled: false
        }));

        //handleAddPAN update PAN details to parent
        // handleAddPAN(PAN_Data)
        handleAddPAN(PAN_Data);
    };

    const onChangePANData = (value) => {
        setAccountHolder(prevStep => ({
            ...prevStep,
            PAN_Number: value,
            PAN_NumberError: ''
        }));
    };
    //modal rename
    const closeAccountModal = () => {
        //setting default state
        setAccountHolder((prevStep) => ({
            ...prevStep,
            verifyPANResponse: {},
            isVerifyCalled: false,
            PAN_Number: '',
            PAN_NumberError: ''
        }));
        props.closeCreateModal()
    };

    return (
        <AccountHolderCreateView
            {...props}
            {...accountHolder}
            handleSelectOrAddPANDetails={handleSelectOrAddPANDetails}
            verifyPAN={verifyPAN}
            onChangePANData={onChangePANData}
            closeAccountModal={closeAccountModal}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXSView: state.appState.deviceInfo.isXSView,
    profile: state.userState.profile,
});

export default connect(mapStateToProps, {})(AccountHolderCreate);
