import {isEmpty} from "../utils/validations";
import React from "react";

export const setValuesForDropdown = (data, labelToken, valueToken) => {
    if (isEmpty(data)) {
        return;
    }
    let obj = [];

    obj = data.map((item) => {
        return {label: item?.[labelToken], value: item?.[valueToken]}
    });
    return obj
};

export const renderOnboardingStatusDot = (status) => {
    if (isEmpty(status)) {
        return '-'
    }
    switch (status) {
        case 'ACTIVATED':
            return <span className="status-dot success"/>;

        case 'ERRORED':
            return <span className="status-dot danger"/>;

        default:
            return <span className="status-dot warning"/>;
    }
};

export const formatDataForDropDown = (data) => {
    if (isEmpty(data)) {
        return {}
    }
    return data.map(item => ({
        label: item.name,
        value: item.id
    }));
};

export const getFullName = (first_name, middle_name, last_name) => {
    if (isEmpty(first_name) && isEmpty(middle_name) && isEmpty(last_name)) {
        return '-';
    }
    const firstName = first_name || "";
    const middleName = middle_name || "";
    const lastName = last_name || "";
    return `${firstName} ${middleName} ${lastName}`.trim().replace(/\s+/g, ' ');
};

export const getOnboardingStatus = (selectedStatus, onboardingStatuses) => {
    if (isEmpty(selectedStatus) || isEmpty(onboardingStatuses)) {
        return '-'
    }
    const status = onboardingStatuses.find(status => status.value === selectedStatus);
    return status ? status.label : '-';
};

