import React from 'react';
import DLButton from "../../../../core/components/DLButton/DLButton";
import {handleFileError} from "../../../../utils/FileHelper";
import DLLoader from "../../../../core/components/DLLoader/DLLoader";
import {isEmpty} from "../../../../utils/validations";
import DLDocUploader from "../../../../core/components/DLDocUploader/DLDocUploader";

const PhotographView = (props) => {

    const {isMobileView, isLoaderVisible, isMultipleApplicants, isFromOnboardingFlow} = props;

    const renderDocumentUploader = () => {
        const {associateDocument, document, profile} = props;

        return (
            <DLDocUploader
                id={'resource-doc-uploader'}
                history={props.history}
                key={'doc-uploader'}
                label={''}
                boxLabel={'Upload Photograph'}
                onChangeValue={(type, value) => {
                    associateDocument(type, value);
                }}
                downloadFileName={document?.fileName}
                imageHeight={'calc(-200px + 100dvh)'}
                isRequired={true}
                isShowLabel={false}
                disableHelperMessageHeight={false}
                helperMessage={document.photographError}
                filePath={document?.filePath}
                uploadedFileName={document?.fileName}
                documentId={document?.id}
                advisoryId={profile?.advisoryId}
                isDisableDelete={props.profile?.isManagement}
                handleError={(error) => handleFileError(error, 62914560, 1)}
            />
        )
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isLoaderVisible}/>);
    };

    const renderUi = () => {
        if (isMobileView) {
            return (
                <div className={"mx-3 mt-2 pt-1"}>
                    {renderErrorUI()}
                    {renderDocumentUploader()}
                </div>
            )
        }

        return (
            <div className='row mt-2 pt-1 p-0 m-0'>
                {renderErrorUI()}
                <div className={"col-12 p-0 m-0"}>
                    <div className='col-6 px-5'>
                        {renderDocumentUploader()}
                    </div>
                </div>
            </div>
        )
    };

    const renderButtonUi = () => {

        const {onNextButtonClick, isFromOnboardingFlow, onSaveAndCloseClick} = props;
        return (
            <div className='sticky-bottom-buttons'>
                {isFromOnboardingFlow ?
                    <>
                        <DLButton
                            id={'cancel-button'}
                            styleType={"outlined"}
                            borderType={'square'}
                            buttonSize={'sm'}
                            fontSize={'sm'}
                            sideMargin={'none'}
                            onClick={() => {
                                onSaveAndCloseClick()
                            }}
                            label={"Save & Close"}
                        />
                        <DLButton id={'delete-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  onClick={() => {
                                      onNextButtonClick()
                                  }}
                                  label={"Next"}/>
                    </>
                    :
                    <DLButton id={'delete-button'}
                              buttonSize={'sm'}
                              fontSize={'sm'}
                              sideMargin={'none'}
                              onClick={() => {
                                  onNextButtonClick()
                              }}
                              label={"Save"}/>
                }

            </div>
        );
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;
        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className='mb-3 pt-2 mt-1 px-5'>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='py-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='py-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className='w-100' style={{
            overflowY: 'auto',
            minHeight: isMultipleApplicants ?
                isFromOnboardingFlow ? 'calc(100dvh - 260px)' :'calc(100dvh - 260px)' : 'calc(100dvh - 218px)',
            maxHeight: isMultipleApplicants ?
                isFromOnboardingFlow ? 'calc(100dvh - 260px)' : 'calc(100dvh - 272px)' :
                isFromOnboardingFlow ? 'calc(100dvh - 250px)': 'calc(100dvh - 218px)'
        }}>
            {renderUi()}
            <div className='bg-light p-0 m-0 w-100'>
                {renderButtonUi()}
            </div>
            {renderLoader()}
        </div>
    );
};

export default PhotographView;