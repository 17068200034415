import React, {Component} from 'react';
import restricted from '../../../assets/img/restricted.jpg';
import DLText from "../DLText/DLText";
import DLButton from "../DLButton/DLButton";
import {isEmpty} from "../../../utils/helperCommon";


class PageNotAccessibleView extends Component {
  render() {
  const {handleClick,profile} = this.props;
    return (
      <div className={""}>
        <div className={"py-2"}>
          <div className={" bg-white m-md-5 p-3"}>
            <div className={"pt-3 text-center"}>
              <DLText id='oops' text={'Oops!'} marginBottom={"md"} type={"normal"}
                      fontSize={"xl"} fontWeight={"bold"} />
              <DLText id='access-denied' text={'Access Denied'} marginBottom={"sm"} type={"normal"}
                      fontSize={"lg"} fontWeight={"semi-bold"} fontColor={'gray'}/>
              <DLText
                  id={'no-privilege'}
                text={'You dont have privilege to access this page.'}
                type={"normal"}
                marginBottom={"md"}
                alignText={"center"}
                fontSize={"sm"} fontWeight={"semi-bold"} fontColor={'gray'}/>
            </div>
            <div className={"pb-3 justify-content-center d-flex align-item-center"}>
              <img src={restricted} alt={'img'} className={'img-fluid'} width={'300px'} height={'200px'}/>
            </div>
            <div style={{minWidth: '150px', width: 'max-content'}}
                 className=' d-grid mt-2 mx-auto text-center'>
              <DLButton history={this.props.history}
                        id={'home'}
                        borderType={'rounded'} buttonSize={'sm'}
                        type={'primary'}
                        hidden={isEmpty(profile?.SystemUser)}
                        onClick={() => handleClick()}
                        label={'Home'}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

PageNotAccessibleView.propTypes = {};

export default PageNotAccessibleView;
