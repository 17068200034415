import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import FormsAndReviewView from "./FormsAndReviewView";
import {getFormAndReviewData, validateFormAndReview, getGenerateForms, getIINFormData} from "./FormsAndReviewActions";

import {APP_CONST} from "../../../AppComponents/AppConstants";
import {isEmpty} from "../../../utils/validations";

const FormsAndReview = (props) => {
    const [formAndReviewState, setFormAndReviewState] = useState({
        formAndReviewList: [],
        errorCount: '',
        UIState: '',
        responseErrors: '',
        isShowLoader: false,
        isFromGenerated: false,
        generatedFormDetails: [],
        IINNumber: '',
        isManagementUser: false
    });

    useEffect(() => {
        handleValidateForm();
    }, [props.selectedTab]);

    const getFormAndReviewListing = () => {
        const {profile, onboardingApplicationId} = props;

        setFormAndReviewState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getFormAndReviewData(onboardingApplicationId, profile.advisoryId)
            .then(res => {
                if (res?.success) {
                    setFormAndReviewState(prevState => ({
                        ...prevState,
                        formAndReviewList: isEmpty(res.data?.errors)? [] : res.data?.errors,
                        errorCount: res.data?.count,
                        isShowLoader: false,
                        responseErrors: '',
                        isManagementUser: profile?.isManagement,
                        UIState: isEmpty(res.data?.errors)? '' : APP_CONST.CONTENT_AVAILABLE,
                    }));
                    getGeneratedFormDetails()
                } else {

                    setFormAndReviewState(prevState => ({
                        ...prevState,
                        responseErrors: isEmpty(res?.__error) ? '' : (res?.__error),
                        UIState: APP_CONST.CONTENT_UNAVAILABLE,
                        isShowLoader: false,
                        isManagementUser: profile?.isManagement
                    }));
                }
            })
    };

    const getGeneratedFormDetails = () =>{
        const {profile, accountId} = props;
        let advisoryId = profile?.advisoryId;
        setFormAndReviewState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getIINFormData(accountId, advisoryId).then(res => {
            if (res?.success) {
                if (isEmpty(res.data)) {
                    setFormAndReviewState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        generatedFormDetails: [],
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                } else {
                    setFormAndReviewState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        generatedFormDetails: res.data?.accountForms,
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                    }));
                }
            } else {
                setFormAndReviewState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    generatedFormDetails: [],
                    UIState: APP_CONST.CONTENT_AVAILABLE,
                }));
            }
        })
    };

    const handleValidateForm = () => {
        const {profile, onboardingApplicationId} = props;

        const payload = {
            onboardingApplicationId: onboardingApplicationId
        };

        setFormAndReviewState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        validateFormAndReview(payload, profile.advisoryId)
            .then(res => {
                if (res?.success) {
                    setFormAndReviewState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseErrors: '',
                        isManagementUser: profile?.isManagement
                    }));
                    getFormAndReviewListing();
                } else {
                    setFormAndReviewState(prevState => ({
                        ...prevState,
                        responseErrors: isEmpty(res?.__error) ? '' : (res?.__error),
                        isShowLoader: false,
                        isManagementUser: profile?.isManagement
                    }));
                }
            });

    };

    const generateForm = () => {
        const {profile, accountId, onboardingApplicationId} = props;

        let advisoryId = profile?.advisoryId;
        setFormAndReviewState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        let payload = {
            accountId: accountId,
            onboardingApplicationId: onboardingApplicationId,
            formDetails: {
                KYC: true,
                IIN: true,
                ECS: false
            }
        };

        getGenerateForms(payload, advisoryId).then(res => {
            if (res?.success) {
                setFormAndReviewState((preState) => ({
                        ...preState,
                        isShowLoader: false,
                        isFromGenerated: true
                    }
                ));
                getGeneratedFormDetails()
            } else {
                setFormAndReviewState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                    isFromGenerated: false
                }));
            }
        })
    };

    const isShowGenerateFormButton = () => {
        const {formAndReviewList, generatedFormDetails} = formAndReviewState;
        if((isEmpty(formAndReviewList) && (isEmpty(generatedFormDetails)))) {
            return true
        }
        return false
    };

    const setIINForm = (IINNumber) => {
        setFormAndReviewState(prevState => ({
            ...prevState,
            IINNumber: IINNumber
        }));
    };

    const updateGeneratedForms = (accountForms) => {
        setFormAndReviewState(prevState => ({
            ...prevState,
            generatedFormDetails: accountForms,
        }));
    };

    return (
        <FormsAndReviewView
            {...props}
            {...formAndReviewState}
            handleValidateForm={handleValidateForm}
            generateForm={generateForm}
            isShowGenerateFormButton={isShowGenerateFormButton}
            setIINForm={setIINForm}
            updateGeneratedForms={updateGeneratedForms}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXLView: state.appState.deviceInfo.isXLView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXSView: state.appState.deviceInfo.isXSView,
    accountId: state.onboardingFlowState.accountId
});

export default connect(mapStateToProps, {})(FormsAndReview);
