import React, {useEffect} from 'react';
import DLText from "../../../../core/components/DLText/DLText";
import DLInputTextField from "../../../../core/components/DLInputTextField/DLInputTextField";
import DLDatePicker from "../../../../core/components/DLDatePicker/DLDatePicker";
import {isEmpty} from "../../../../utils/validations";
import DLButton from "../../../../core/components/DLButton/DLButton";
import DLLoader from "../../../../core/components/DLLoader/DLLoader";
import {debounce} from "../../../../core/components/DLComponentHelper";
import {handleFileError} from "../../../../utils/FileHelper";
import DLDocUploader from "../../../../core/components/DLDocUploader/DLDocUploader";

const PANView = (props) => {

    const {isMobileView, scrollToFieldId, errors,profile, isMultipleApplicants, isFromOnboardingFlow, PANNumberByPerson} = props;

    useEffect(() => {

        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [props.scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {
        props.handleChange('scrollToFieldId', '')
    }, 1000);

    const renderDocumentUploader = () => {
        const {associateDocument, document, getDownloadImageFileName, PANInformation} = props;

        return (
            <DLDocUploader
                id={'resource-doc-uploader'}
                history={props.history}
                key={'doc-uploader'}
                label={''}
                boxLabel={'Upload PAN Card'}
                onChangeValue={(type, value) => {
                    associateDocument(type, value);
                }}
                downloadFileName = {getDownloadImageFileName(PANInformation?.firstName,PANInformation?.lastName)}
                imageHeight={'548px'}
                isShowLabel={false}
                disableHelperMessageHeight={false}
                helperMessage={errors.document?.PAN_CARD}
                isRequired={true}
                filePath={document.filePath}
                uploadedFileName={document.fileName}
                documentId={document?.id}
                advisoryId={profile?.advisoryId}
                isDisableDelete={props.profile?.isManagement}
                handleError={(error) => handleFileError(error, 62914560, 1)}
            />
        )
    };

    const renderUpLoaderUi = () => {
        return (<div id={'pan-information'}>
            <div className={'py-2 my-1'}>
                <DLText id={''}
                        text={'PAN: '}
                        fontColor={"grayDark"}
                        fontSize={"lg"}
                        isInline={true}
                />
                <DLText id={''}
                        text={isEmpty(PANNumberByPerson) ? '-': PANNumberByPerson}
                        fontWeight={"semi-bold"}
                        fontSize={"lg"}
                        isInline={true}
                />
            </div>
            {renderDocumentUploader()}
        </div>);
    };

    const renderTextFieldUI = () => {
        const {PANInformation, fathersDetails, errors, handleChange} = props;
        let PANInformationError = errors.PANInformation;
        let fathersDetailsError = errors.fathersDetails;

        return (
            <div>
                <div className='me-3'>
                    <div className='section-title' id={'fathers-information'}>
                        PAN Information
                    </div>
                    <div className='panel'>
                        <DLText
                            id={'pan-information'}
                            type={'normal'}
                            text={"This information will be automatically added to the personal profile."}
                            marginBottom={'md'}
                            fontColor={'black'}
                        />

                        <div id='PANInformation-PANNumber'>
                            <DLInputTextField id={'PANNumber'}
                                              label={"PAN Number"}
                                              value={(PANInformation?.PANNumber)?.toLocaleUpperCase()}
                                              isDisabled={false}   //PAN edit is always disable
                                              maxCharLength={20}
                                              inputType={"text"}
                                              labelFontColor={'grayDark'}
                                              isClearable={false}
                                              isRequired={true}
                                              disableHelperMessageHeight={true}
                                              error={PANInformationError?.PANNumber !== ""}
                                              helperMessage={PANInformationError?.PANNumber}
                                              marginBottom={'sm'}
                                              onChangeValue={(e) => {
                                                  handleChange("PANNumber", e, "PANInformation")
                                              }}
                            />
                        </div>

                        <div id='PANInformation-nameAsPerPAN-id'>
                            <DLInputTextField id={'full-name'}
                                              label={"Name as per PAN"}
                                              value={PANInformation.nameAsPerPAN?.toUpperCase()}
                                              maxCharLength={60}
                                              inputType={"text"}
                                              labelFontColor={'grayDark'}
                                              isClearable={false}
                                              isRequired={true}
                                              disableHelperMessageHeight={true}
                                              error={PANInformationError.nameAsPerPAN !== ""}
                                              helperMessage={PANInformationError.nameAsPerPAN}
                                              marginBottom={'sm'}
                                              onChangeValue={(e) => {
                                                  handleChange("nameAsPerPAN", e, "PANInformation")
                                              }}
                            />
                        </div>

                        <div id='PANInformation-firstName-id'>
                            <DLInputTextField id={'first-name'}
                                              label={"First Name"}
                                              value={PANInformation.firstName?.toUpperCase()}
                                              maxCharLength={20}
                                              inputType={"text"}
                                              labelFontColor={'grayDark'}
                                              isClearable={false}
                                              isRequired={true}
                                              disableHelperMessageHeight={true}
                                              error={PANInformationError.firstName !== ""}
                                              helperMessage={PANInformationError.firstName}
                                              marginBottom={'sm'}
                                              onChangeValue={(e) => {
                                                  handleChange("firstName", e, "PANInformation")
                                              }}
                            />
                        </div>

                        <div id='PANInformation-middleName-id'>
                            <DLInputTextField id={'middle-name'}
                                              label={"Middle Name (Optional)"}
                                              value={PANInformation.middleName?.toUpperCase()}
                                              maxCharLength={20}
                                              inputType={"text"}
                                              labelFontColor={'grayDark'}
                                              isClearable={false}
                                              isRequired={true}
                                              disableHelperMessageHeight={true}
                                              error={PANInformationError.middleName !== ""}
                                              helperMessage={PANInformationError.middleName}
                                              marginBottom={'sm'}
                                              onChangeValue={(e) => {
                                                  handleChange("middleName", e, "PANInformation")
                                              }}
                            />
                        </div>

                        <div id='PANInformation-lastName-id'>
                            <DLInputTextField id={'last-name'}
                                              label={"Last Name"}
                                              value={PANInformation.lastName?.toUpperCase()}
                                              maxCharLength={20}
                                              inputType={"text"}
                                              labelFontColor={'grayDark'}
                                              isClearable={false}
                                              isRequired={true}
                                              disableHelperMessageHeight={true}
                                              error={PANInformationError.lastName !== ""}
                                              helperMessage={PANInformationError.lastName}
                                              marginBottom={'sm'}
                                              onChangeValue={(e) => {
                                                  handleChange("lastName", e, "PANInformation")
                                              }}
                            />
                        </div>

                        <div className={'mb-4'}>
                            <DLText
                                id={'date-of-birth'}
                                type={'normal'}
                                text={"Date of Birth"}
                                fontSize={'xs'}
                                fontColor={isEmpty(PANInformationError.dateOfBirth) ? 'grayDark' : 'danger'}
                            />
                            <div id='PANInformation-dateOfBirth-id'>
                                <DLDatePicker id={"date-of-birth"}
                                              value={PANInformation.dateOfBirth}
                                              maximumDate={new Date()}
                                              disableHelperMessageHeight={true}
                                              error={PANInformationError.dateOfBirth !== ""}
                                              helperMessage={PANInformationError.dateOfBirth}
                                              minimumDate={'1900-12-30'}
                                              minWidth={"100%"}
                                              onChangeValue={(e) => {
                                                  handleChange("dateOfBirth", e, "PANInformation")
                                              }}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={isFromOnboardingFlow ? 'mt-2 me-3' : 'mt-2 me-3'}>
                    <div className='section-title' id={'fathers-information'}>
                        Father's Information
                    </div>

                    <div className='panel'>
                        <div id='fathersDetails-firstName-id'>
                            <DLInputTextField id={'father-first-name'}
                                              label={"First Name"}
                                              value={fathersDetails.firstName?.toUpperCase()}
                                              maxCharLength={20}
                                              inputType={"text"}
                                              labelFontColor={'grayDark'}
                                              isClearable={false}
                                              isRequired={true}
                                              disableHelperMessageHeight={true}
                                              error={fathersDetailsError.firstName !== ""}
                                              helperMessage={fathersDetailsError.firstName}
                                              marginBottom={'sm'}
                                              onChangeValue={(e) => {
                                                  handleChange("firstName", e, "fathersDetails")
                                              }}
                            />
                        </div>

                        <div id='fathersDetails-middleName-id'>
                            <DLInputTextField id={'father-middle-name'}
                                              label={"Middle Name (Optional)"}
                                              value={fathersDetails.middleName?.toUpperCase()}
                                              maxCharLength={20}
                                              inputType={"text"}
                                              labelFontColor={'grayDark'}
                                              isClearable={false}
                                              isRequired={true}
                                              disableHelperMessageHeight={true}
                                              error={fathersDetailsError.middleName !== ""}
                                              helperMessage={fathersDetailsError.middleName}
                                              marginBottom={'sm'}
                                              onChangeValue={(e) => {
                                                  handleChange("middleName", e, "fathersDetails")
                                              }}
                            />
                        </div>

                        <div id='fathersDetails-lastName-id'>
                            <DLInputTextField id={'father-last-name'}
                                              label={"Last Name"}
                                              value={fathersDetails.lastName?.toUpperCase()}
                                              maxCharLength={20}
                                              inputType={"text"}
                                              labelFontColor={'grayDark'}
                                              isClearable={false}
                                              isRequired={true}
                                              disableHelperMessageHeight={true}
                                              error={fathersDetailsError.lastName !== ""}
                                              helperMessage={fathersDetailsError.lastName}
                                              marginBottom={'sm'}
                                              onChangeValue={(e) => {
                                                  handleChange("lastName", e, "fathersDetails")
                                              }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderTextFieldMobileUI = () => {
        const {PANInformation, fathersDetails, errors, handleChange} = props;
        let PANInformationError = errors.PANInformation;
        let fathersDetailsError = errors.fathersDetails;

        return (
            <div>
                <div id={'pan-information'}>
                    <DLText
                        id={'mobile-pan-information'}
                        type={'normal'}
                        text={"PAN Information"}
                        fontSize={'sm'}
                        fontWeight={'semi-bold'}
                        marginBottom={'md'}
                        fontColor={'black'}
                    />
                    <DLText
                        id={'pan-information'}
                        type={'normal'}
                        text={"This information will be automatically added to the personal profile."}
                        marginBottom={'md'}
                        fontColor={'black'}
                    />
                    <DLInputTextField
                        id={'mobile-pan-number'}
                        label={'PAN Number'}
                        value={(PANInformation.PANNumber)?.toLocaleUpperCase()}
                        isDisabled={false}   //PAN edit is always disable
                        marginBottom={"sm"}
                        inputType={"text"}
                        maxCharLength={101}
                        disableHelperMessageHeight={true}
                        hideCharLength={true}
                        isClearable={false}
                        isRequired={true}
                        onChangeValue={(e) => {
                            handleChange("PANNumber", e, "PANInformation")
                        }}
                        error={PANInformationError.PANNumber !== ""}
                        helperMessage={PANInformationError.PANNumber}
                    />

                    <DLInputTextField
                        id={'PANInformation-nameAsPerPAN-name'}
                        label={'Name as per PAN'}
                        value={PANInformation.nameAsPerPAN?.toUpperCase()}
                        marginBottom={"sm"}
                        inputType={"text"}
                        maxCharLength={60}
                        disableHelperMessageHeight={true}
                        hideCharLength={true}
                        isClearable={false}
                        isRequired={true}
                        onChangeValue={(e) => {
                            handleChange("nameAsPerPAN", e, "PANInformation")
                        }}
                        error={PANInformationError.nameAsPerPAN !== ""}
                        helperMessage={PANInformationError.nameAsPerPAN}
                    />
                    <DLInputTextField
                        id={'mobile-first-name'}
                        label={'First Name'}
                        value={PANInformation.firstName?.toUpperCase()}
                        marginBottom={"sm"}
                        inputType={"text"}
                        maxCharLength={101}
                        disableHelperMessageHeight={true}
                        hideCharLength={true}
                        isClearable={false}
                        isRequired={true}
                        onChangeValue={(e) => {
                            handleChange("firstName", e, "PANInformation")
                        }}
                        error={PANInformationError.firstName !== ""}
                        helperMessage={PANInformationError.firstName}
                    />

                    <DLInputTextField
                        id={'mobile-middle-name'}
                        label={'Middle Name'}
                        value={PANInformation.middleName?.toUpperCase()}
                        marginBottom={"sm"}
                        inputType={"text"}
                        maxCharLength={101}
                        disableHelperMessageHeight={true}
                        hideCharLength={true}
                        isClearable={false}
                        isRequired={true}
                        onChangeValue={(e) => {
                            handleChange("middleName", e, "PANInformation")
                        }}
                        error={PANInformationError.middleName !== ""}
                        helperMessage={PANInformationError.middleName}
                    />

                    <DLInputTextField
                        id={'mobile-last-name'}
                        label={'Last Name'}
                        value={PANInformation.lastName?.toUpperCase()}
                        marginBottom={"sm"}
                        inputType={"text"}
                        maxCharLength={101}
                        disableHelperMessageHeight={true}
                        hideCharLength={true}
                        isClearable={false}
                        isRequired={true}
                        onChangeValue={(e) => {
                            handleChange("lastName", e, "PANInformation")
                        }}
                        error={PANInformationError.lastName !== ""}
                        helperMessage={PANInformationError.lastName}
                    />



                    <div className='mt-3 pt-1 mb-3'>
                        <div>
                            <DLDatePicker id={"mobile-date-of-birth"}
                                          label={'Date of Birth'}
                                          value={PANInformation.dateOfBirth}
                                          maximumDate={new Date()}
                                          minWidth={"100%"}
                                          isRequired={true}
                                          error={PANInformationError.dateOfBirth !== ""}
                                          helperMessage={PANInformationError.dateOfBirth}
                                          disableHelperMessageHeight={true}
                                          minimumDate={'1900-12-30'}
                                          onChangeValue={(e) => {
                                              handleChange("dateOfBirth", e, "PANInformation")
                                          }}/>
                        </div>
                    </div>
                    <div>
                        <DLText
                            id={'mobile-fathers-information'}
                            type={'normal'}
                            text={"Father's Information"}
                            fontSize={'sm'}
                            fontWeight={'semi-bold'}
                            marginBottom={'md'}
                            fontColor={'black'}
                        />
                        <div className='mt-3 pt-1'>

                            <DLInputTextField
                                id={'mobile-father-first-name'}
                                label={"First Name"}
                                value={fathersDetails.firstName?.toUpperCase()}
                                marginBottom={"sm"}
                                inputType={"text"}
                                maxCharLength={101}
                                disableHelperMessageHeight={true}
                                hideCharLength={true}
                                isClearable={false}
                                onChangeValue={(e) => {
                                    handleChange("firstName", e, "fathersDetails")
                                }}
                                error={fathersDetailsError.firstName !== ""}
                                helperMessage={fathersDetailsError.firstName}
                            />

                            <DLInputTextField
                                id={'mobile-father-middle-name'}
                                label={"Middle Name"}
                                value={fathersDetails.middleName?.toUpperCase()}
                                marginBottom={"sm"}
                                inputType={"text"}
                                maxCharLength={101}
                                disableHelperMessageHeight={true}
                                hideCharLength={true}
                                isClearable={false}
                                onChangeValue={(e) => {
                                    handleChange("middleName", e, "fathersDetails")
                                }}
                                error={fathersDetailsError.middleName !== ""}
                                helperMessage={fathersDetailsError.middleName}
                            />

                            <DLInputTextField
                                id={'mobile-father-last-name'}
                                label={"Last Name"}
                                value={fathersDetails.lastName?.toUpperCase()}
                                marginBottom={"sm"}
                                inputType={"text"}
                                maxCharLength={101}
                                disableHelperMessageHeight={true}
                                hideCharLength={true}
                                isClearable={false}
                                onChangeValue={(e) => {
                                    handleChange("lastName", e, "fathersDetails")
                                }}
                                error={fathersDetailsError.lastName !== ""}
                                helperMessage={fathersDetailsError.lastName}
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? 'py-2 my-1 px-5' : 'py-2 px-5'}>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='pb-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='py-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    const renderDesktopUI = () => {
        return (
            <div style={{
                overflowY: 'auto',
                maxHeight: isMultipleApplicants ?
                    !isFromOnboardingFlow ? 'calc(100dvh - 260px)' : 'calc(100dvh - 260px)' :
                    !isFromOnboardingFlow ? 'calc(100dvh - 220px)' : 'calc(100dvh - 218px)'
            }}>
                {renderErrorUI()}
                <div className={"row m-0 p-0" }>
                    <div className='col-6 pe-0 px-5'>
                        {renderUpLoaderUi()}
                    </div>
                    <div className='col-6 p-0 px-5'>
                        {renderTextFieldUI()}
                        {renderButtonUI()}
                    </div>
                </div>
            </div>
        )
    };

    const renderButtonUI = () => {
        const {onNextClick, isFromOnboardingFlow, onSaveAndCloseClick} = props;
        return (
            <div className='sticky-bottom-buttons'>
                {isFromOnboardingFlow ?
                    <>
                        <DLButton
                            id={'cancel-button'}
                            styleType={"outlined"}
                            borderType={'square'}
                            buttonSize={'sm'}
                            fontSize={'sm'}
                            sideMargin={'none'}
                            onClick={() => {
                                onSaveAndCloseClick()
                            }}
                            label={"Save & Close"}
                        />
                        <DLButton id={'delete-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  onClick={() => {
                                      onNextClick()
                                  }}
                                  label={"Next"}/>
                    </>
                    :
                    <DLButton id={'delete-button'}
                              buttonSize={'sm'}
                              fontSize={'sm'}
                              sideMargin={'none'}
                              onClick={() => {
                                  onNextClick()
                              }}
                              label={"Save"}/>
                }
            </div>
        );
    };

    const renderMobileUI = () => {
        return (
            <div className={"mx-3"}>
                {renderErrorUI()}
                <div className={"mb-3"}>
                    {renderUpLoaderUi()}
                </div>
                <div>
                    {renderTextFieldMobileUI()}
                    {renderButtonUI()}
                </div>
            </div>
        )
    };

    return (
        <>
            {props.isMobileView ? renderMobileUI() : renderDesktopUI()}
            <DLLoader isVisible={props.isLoaderVisible} type={'screen'}/>
        </>
    );
};

PANView.propTypes = {};

export default PANView;