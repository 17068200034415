import {httpGet, httpPost} from "../utils/httpHelper";
import {downloadS3BucketFile, uploadData} from "../utils/FileHelper";


export const getCategoryOptions = (advisoryId) => {
    return httpGet('/service/advisory/categoryType/all', advisoryId).then(res => {
        return res;
    });
};

export const getAccountTypeOptions = (advisoryId) => {
    return httpGet('/service/advisory/accountType/all', advisoryId).then(res => {
        return res;
    });
};

export const getCRMDetails = (advisoryId) => {
    return httpGet('/service/advisory/advisoryUser/all', advisoryId).then(res => {
        return res;
    })
};

export const getAdvisoryUsers = (advisoryId) => {
    return httpGet('/service/advisory/advisoryUser/all', advisoryId).then(res => {
        return res;
    })
};

export const getCRMs = (advisoryId) => {
    return httpGet('/service/advisory/advisoryUser/all?role=CRM', advisoryId).then(res => {
        return res;
    })
};

export const getLocations = (advisoryId) => {
    return httpGet('/service/advisory/location/all', advisoryId).then(res => {
        return res;
    })
};