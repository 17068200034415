import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import DashboardWidget from "../../../DashboardWidget";
import {getManagementLeadsAgeing, resetLeadFilters, setFromDate, setToDate} from "../../../HomePageActions";

const NotContactedLeads = (props) => {
    const [notContactedState, setNotContactedState] = useState({
        notContactedLeads: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        const {profile} = props;
        const advisoryId = profile?.advisoryId;

        setNotContactedState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getManagementLeadsAgeing(advisoryId).then(res => {
            if (res.success) {
                setNotContactedState(prevState => ({
                    ...prevState,
                    notContactedLeads: res.data,
                    isShowLoader: false,
                }));
            } else {
                setNotContactedState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetLeadFilters();
        setFromDate(item.fromDate);
        setToDate(item.toDate);
        props.history.push('/leads')
    };

    return (
        <DashboardWidget
            {...props}
            {...notContactedState}
            detailsObj={notContactedState.notContactedLeads}
            setFilters={setFilters}
            title={"Not Contacted Leads-Ageing "}
        />
    );
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(NotContactedLeads);
