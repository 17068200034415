import {httpGet} from "../utils/httpHelper";
import {
    resetAllFilters as resetAllLeadsFilters,
    setCRMValue as setLeadsCRMValue,
    setFromDateValue as setLeadsFromDateValue,
    setStatusValue as setLeadsStatusValue,
    setToDateValue as setLeadsToDateValue
} from "../leads/leadsReducer";
import store from "../store";
import {
    resetAllFilters as resetAllOnboardingFilters,
    setAccountTypeValue as setOnboardingAccountTypeValue,
    setCategoryValue as setOnboardingCategoryValue,
    setCRMValue as setOnboardingCRMValue,
    setFromDateValue as setOnboardingFromDateValue, setLocationValue,
    setStatusValue as setOnboardingStatusValue,
    setToDateValue as setOnboardingToDateValue,
} from "../onboarding/listing/onboardingReducer";

export const getMyLeads = (advisoryId) => {
    return httpGet('/service/advisory/summary/crm/myLeads', advisoryId).then(res => {
        return res;
    })
};

export const getLeadsAgeing = (advisoryId) => {
    return httpGet('/service/advisory/summary/crm/leadsByAgeing', advisoryId).then(res => {
        return res;
    })
};

export const getUnassignedLeads = (advisoryId) => {
    return httpGet('/service/advisory/summary/crm/unassignedLeads', advisoryId).then(res => {
        return res;
    })
};

export const getOnboardingCategory = (advisoryId) => {
    return httpGet('/service/advisory/summary/crm/onboardingByCategory', advisoryId).then(res => {
        return res;
    })
};

export const getOnboardingAccountType = (advisoryId) => {
    return httpGet('/service/advisory/summary/crm/onboardingByType', advisoryId).then(res => {
        return res;
    })
};

export const getOnboardingAgeing = (advisoryId) => {
    return httpGet('/service/advisory/summary/crm/onboardingByAgeing', advisoryId).then(res => {
        return res;
    })
};

export const getOnboardingStatus = (advisoryId) => {
    return httpGet('/service/advisory/summary/crm/onboardingByStatus', advisoryId).then(res => {
        return res;
    })
};

export const getClientCategory = (advisoryId) => {
    return httpGet('/service/advisory/summary/crm/clientByCategory', advisoryId).then(res => {
        return res;
    })
};

export const getClientAccountType = (advisoryId) => {
    return httpGet('/service/advisory/summary/crm/clientByAccountType', advisoryId).then(res => {
        return res;
    })
};

// Management Dashboard
export const getManagementMyLeads = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/leadStatus', advisoryId).then(res => {
        return res;
    })
};

export const getManagementLeadsAgeing = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/notContactedLeads', advisoryId).then(res => {
        return res;
    })
};

export const getManagementCRMLeads = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/crmLeads', advisoryId).then(res => {
        return res;
    })
};

export const getManagementOnboardingCategory = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/onboardingByCategory', advisoryId).then(res => {
        return res;
    })
};

export const getManagementOnboardingAccountType = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/onboardingByAccountType', advisoryId).then(res => {
        return res;
    })
};

export const getManagementOnboardingLocation = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/onboardingByLocation', advisoryId).then(res => {
        return res;
    })
};

export const getManagementOnboardingCRM = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/onboardingBycrm', advisoryId).then(res => {
        return res;
    })
};

export const getManagementOnboardingAgeing = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/onboardingByAgeing', advisoryId).then(res => {
        return res;
    })
};

export const getManagementOnboardingStatus = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/onboardingByStatus', advisoryId).then(res => {
        return res;
    })
};

export const getManagementClientCategory = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/clientByCategory', advisoryId).then(res => {
        return res;
    })
};

export const getManagementClientAccountType = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/clientByAccountType', advisoryId).then(res => {
        return res;
    })
};

export const getManagementClientByLocation = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/clientByLocation', advisoryId).then(res => {
        return res;
    })
};

export const getManagementClientCRM = (advisoryId) => {
    return httpGet('/service/advisory/summary/management/clientBycrm', advisoryId).then(res => {
        return res;
    })
};

export const setCRM = (data) => {
    store.dispatch(setLeadsCRMValue(data))
};

export const setStatus = (data) => {
    store.dispatch(setLeadsStatusValue(data))
};

export const setFromDate = (data) => {
    store.dispatch(setLeadsFromDateValue(data))
};

export const setToDate = (data) => {
    store.dispatch(setLeadsToDateValue(data))
};

export const resetLeadFilters = (data) => {
    store.dispatch(resetAllLeadsFilters(data))
};

export const setOnboardingFromDate = (data) => {
    store.dispatch(setOnboardingFromDateValue(data))
};

export const setOnboardingToDate = (data) => {
    store.dispatch(setOnboardingToDateValue(data))
};

export const setOnboardingStatus = (data) => {
    store.dispatch(setOnboardingStatusValue(data))
};

export const setOnboardingCategory = (data) => {
    store.dispatch(setOnboardingCategoryValue(data))
};

export const setOnboardingAccountType = (data) => {
    store.dispatch(setOnboardingAccountTypeValue(data))
};

export const resetOnboardingListingFilters = (data) => {
    store.dispatch(resetAllOnboardingFilters(data))
};

export const setLocationForListing = (data) => {
    store.dispatch(setLocationValue(data))
};

export const setCRMForListing = (data) => {
    store.dispatch(setOnboardingCRMValue(data))
};