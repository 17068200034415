import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";

import {getTimeFromNow} from "../../../../../utils/helper";
import {isEmpty} from "../../../../../core/components/DLComponentHelper";
import {APP_CONST} from "../../../../../AppComponents/AppConstants";

import {
    downloadAndViewForm,
    eConsent,
    getIINFormData,
    getAllBankChannelType,
    regenerate,
    submitAcheData,
    setBankMandateChannelType
} from "../../FormsAndReviewActions";
import BankMandateView from "./BankMandateView";
import {getBankData} from "../../../../OnboardingActions";

const BankMandate = (props) => {

    const {onboardingApplicationId} = props;

    const [bankMandateState, setBankMandateState] = useState({
        formId: '',
        fileName: '',
        filePath: '',
        timeStamp: '',
        generatedTime: '',
        uploadedTime: '',
        isReviewed: false,
        isMarkAsReviewedModalOpen: false,
        isUploadECSFormModalOpen: false,
        isAddRemarkModalOpen: false,
        isViewActivitiesModalOpen: false,
        UIState: APP_CONST.CONTENT_AVAILABLE,
        responseErrors: '',
        isShowLoader: false,
        isFormReviewed: false,
        isECSSubmitted: false,
        electronicConsent: true,
        isManagementUser: false,
        bankMandateStatus: '',
        channelTypeOpt: [],
        selectedChannelType : '',
        bankAccountId: '',
        escFormSubmitStatus: ''
    });

    useEffect(() => {
        getBankChannelType()
    }, [props.generatedFormDetails]);

    const getBankChannelType = () =>{
        const {profile, accountId} = props;
        getAllBankChannelType(profile?.advisoryId)
            .then(res => {
                if(isEmpty(res?.data?.channelTypes)){
                    getData([],'','','')
                }else{
                    getBankData(accountId,profile?.advisoryId)
                        .then(bankResData => {
                            if (bankResData?.success) {
                                let channelTypes = res?.data?.channelTypes;
                                let channelTypesFormattedObj = channelTypes.map((item) => {
                                    return {label: item?.["name"], value: item?.["id"]}
                                });
                               let escFormSubmitStatus= bankResData?.data?.ACHMandateReport?.achMandateStatus;
                                getData(channelTypesFormattedObj, bankResData?.data?.id, bankResData?.data?.bankMandate?.channelType, escFormSubmitStatus)
                            }else{
                                getData([],'','','')
                            }
                        });
                }
            })
    };

    const getData = (channelTypeOpt, bankAccountId, selectedChannelType, escFormSubmitStatus) => {
        const {generatedFormDetails, profile} = props;
        if (isEmpty(generatedFormDetails)) {
            setBankMandateState(prevState => ({
                ...prevState,
                formId: '',
                fileName: '',
                filePath: '',
                timeStamp: '',
                generatedTime: '',
                uploadedTime: '',
                UIState: APP_CONST.CONTENT_AVAILABLE,
                isReviewed: false,
                electronicConsent: true,
                isManagementUser: profile?.isManagement,
                channelTypeOpt: channelTypeOpt,
                bankAccountId: bankAccountId,
                selectedChannelType: selectedChannelType,
                escFormSubmitStatus: escFormSubmitStatus
            }));
        } else {
            let details = sortECSData(generatedFormDetails);
            setBankMandateState((preState) => ({
                ...preState,
                formId: details?.formId,
                fileName: details?.fileName,
                filePath: details?.filePath,
                generatedTime: details?.generationTimestamp,
                uploadedTime: details?.uploadTimestamp,
                timeStamp: isEmpty(details?.updatedAt) ? '' : getTimeFromNow(details?.updatedAt, 'DD-MM-YYYY'),
                UIState: APP_CONST.CONTENT_AVAILABLE,
                isReviewed: details?.isReviewed,
                electronicConsent: details?.eConsent,
                isManagementUser: profile?.isManagement,
                channelTypeOpt: channelTypeOpt,
                bankAccountId: bankAccountId,
                selectedChannelType: selectedChannelType,
                escFormSubmitStatus: escFormSubmitStatus
            }))
        }
    };

    const refreshData = () => {
        const {profile, accountId, updateGeneratedForms} = props;
        let advisoryId = profile?.advisoryId;
        let details = [];
        setBankMandateState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));
        getIINFormData(accountId, advisoryId).then(res => {
            if (res?.success) {
                if (isEmpty(res.data)) {
                    setBankMandateState((preState) => ({
                            ...preState,
                            UIState: APP_CONST.CONTENT_AVAILABLE,
                            isShowLoader: false,
                        }
                    ))
                } else {
                    let details = sortECSData(res?.data?.accountForms);
                    updateGeneratedForms(res?.data?.accountForms);
                    setBankMandateState((preState) => ({
                        ...preState,
                        formId: details?.formId,
                        fileName: details?.fileName,
                        filePath: details?.filePath,
                        generatedTime: details?.generationTimestamp,
                        uploadedTime: details?.uploadTimestamp,
                        timeStamp: isEmpty(details?.updatedAt) ? '' : getTimeFromNow(details?.updatedAt, 'DD-MM-YYYY'),
                        UIState: APP_CONST.CONTENT_AVAILABLE,
                        isReviewed: details?.isReviewed,
                        isShowLoader: false,
                    }))
                }
            } else {
                setBankMandateState(prevState => ({
                    ...prevState,
                    formId: '',
                    fileName: '',
                    filePath: '',
                    timeStamp: '',
                    generatedTime: '',
                    uploadedTime: '',
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                    isReviewed: false,
                    isShowLoader: false,
                }));
            }
        })
    };

    const sortECSData = (obj) => {
        let ECSDetails = [];
        if (isEmpty(obj)) {
            return ECSDetails;
        }

        ECSDetails = obj.find((item) => {
            return item?.formType === "ECS";
        });

        return ECSDetails;
    };

    const regenerateForm = () => {
        const {profile, accountId} = props;
        let advisoryId = profile?.advisoryId;

        setBankMandateState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload = {
            accountId: accountId,
            onboardingApplicationId: onboardingApplicationId,
            formDetails: {
                ECS: true
            }
        };

        regenerate(payload, advisoryId).then(res => {
            if (res?.success) {
                setBankMandateState((preState) => ({
                        ...preState,
                        isShowLoader: false
                    }
                ))
            } else {
                setBankMandateState(prevState => ({
                    ...prevState,
                    isShowLoader: false
                }));
            }
            refreshData();
        })
    };

    const handleOpenMarkAsReviewCheckBox = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isMarkAsReviewedModalOpen: true
        }))
    };

    const handleCloseMarkAsReviewCheckBox = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isMarkAsReviewedModalOpen: false,
            responseErrors: '',
        }))
    };

    const setReviewedFlag = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isReviewed: true
        }))
    };

    const handleOpenUploadModal = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isUploadECSFormModalOpen: true
        }))
    };

    const handleCloseUploadModal = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isUploadECSFormModalOpen: false,
            responseErrors: '',
        }));
        refreshData()
    };

    const handleOpenAddRemarkModal = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isAddRemarkModalOpen: true,
        }))
    };

    const handleCloseAddRemarkModal = () => {
        setBankMandateState((prevState) => ({
            ...prevState,
            isAddRemarkModalOpen: false,
            responseErrors: '',
        }))
    };

    const selectedOption = (option) => {
        if (option === "Upload") {
            handleOpenUploadModal()
        } else if (option === "Add a Remark") {
            handleOpenAddRemarkModal()
        } else if (option === "View Activities") {
            handleOpenViewActivitiesModal()
        }
    };

    const downloadAndViewDocument = (action) => {
        const {filePath, fileName} = bankMandateState;
        const {profile} = props;
        setBankMandateState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));
        downloadAndViewForm(fileName, filePath, profile?.advisoryId, action);
        setTimeout(() => {
            setBankMandateState((preState) => ({
                    ...preState,
                    isShowLoader: false,
                }
            ))
        }, 500)
    };

    const handleOpenViewActivitiesModal = () => {
        setBankMandateState((prevStep) => ({
            ...prevStep,
            isViewActivitiesModalOpen: true,
        }));
    };

    const handleCloseViewActivitiesModal = () => {
        setBankMandateState((prevStep) => ({
            ...prevStep,
            isViewActivitiesModalOpen: false,
            responseErrors: '',
        }));
    };

    const submitECS = () => {
        const {onboardingApplicationId, profile} = props;
        setBankMandateState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));
        let payloadData = {
            onboardingApplicationId: onboardingApplicationId
        };
        submitAcheData(payloadData, profile.advisoryId)
            .then(res => {
                if (res?.success) {
                    setBankMandateState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        responseErrors: '',
                        isECSSubmitted: true,
                    }));
                } else {
                    setBankMandateState(prevState => ({
                        ...prevState,
                        isShowLoader: false,
                        isECSSubmitted: false,
                        responseErrors: res?.__error
                    }));
                }
            })
    };
    const resetECSSubmissionStatus = () => {

        setBankMandateState(prevState => ({
            ...prevState,
            isECSSubmitted: false
        }));
    };
    const setActivityStatus = (error) => {
        setBankMandateState(prevState => ({
            ...prevState,
            responseErrors: error
        }));
    };

    const setEConsent = (value) => {
        const {profile} = props;
        let advisoryId = profile?.advisoryId;
        setBankMandateState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload = {
            id: bankMandateState.formId,
            eConsent: value
        };

        eConsent(payload, advisoryId).then(res => {
            if (res?.success) {
                if(!value){
                    selectChannelType('')
                }
            }
            setTimeout(() => {
                setBankMandateState((preState) => ({
                        ...preState,
                        isShowLoader: false,
                    }
                ))
            }, 500)
        })
    };

    const setElectronicConsent = (value) => {
        setBankMandateState((prevState) => ({
            ...prevState,
            electronicConsent: value
        }));
        setEConsent(value)
    };
    const setBankMandateStatus = (status) =>{
        setBankMandateState((prevState) => ({
            ...prevState,
            bankMandateStatus: status
        }));
    };

    const setEscFormSubmitStatus = () =>{
        const {accountId, profile} = props;
        getBankData(accountId,profile?.advisoryId)
            .then(bankResData => {
                if (bankResData?.success) {
                    setBankMandateState((prevState) => ({
                        ...prevState,
                        escFormSubmitStatus: bankResData?.data?.ACHMandateReport?.achMandateStatus
                    }));
                }
            });
    };

    const selectChannelType = (value) =>{
        const {accountId, profile} = props;
        const {bankAccountId, channelTypeOpt, selectedChannelType} = bankMandateState;
        let channelType = channelTypeOpt?.find(dropdownVal=> dropdownVal.value === value);
        channelType = channelType?.value;
        setBankMandateState((preState) => ({
            ...preState,
            isShowLoader: true
        }));

        let payload = {
            accountId: accountId,
            bankAccountId: bankAccountId,
            channelType: channelType
        };
        setBankMandateChannelType(payload,profile?.advisoryId)
            .then(bankResData => {
                if(bankResData?.success){
                    setBankMandateState((preState) => ({
                        ...preState,
                        selectedChannelType: channelType,
                        isShowLoader: false,
                        responseErrors: ''
                    }))
                }else{
                    setBankMandateState((preState) => ({
                        ...preState,
                        selectedChannelType: selectedChannelType,
                        isShowLoader: false,
                        responseErrors: bankResData?.__error
                    }))
                }
            });
    };

    return (
        <BankMandateView
            {...props}
            {...bankMandateState}
            handleOpenUploadModal={handleOpenUploadModal}
            handleCloseUploadModal={handleCloseUploadModal}
            handleOpenAddRemarkModal={handleOpenAddRemarkModal}
            handleCloseAddRemarkModal={handleCloseAddRemarkModal}
            regenerateForm={regenerateForm}
            handleOpenMarkAsReviewCheckBox={handleOpenMarkAsReviewCheckBox}
            handleCloseMarkAsReviewCheckBox={handleCloseMarkAsReviewCheckBox}
            setReviewedFlag={setReviewedFlag}
            selectedOption={selectedOption}
            downloadAndViewDocument={downloadAndViewDocument}
            handleOpenViewActivitiesModal={handleOpenViewActivitiesModal}
            handleCloseViewActivitiesModal={handleCloseViewActivitiesModal}
            refreshData={refreshData}
            submitECS={submitECS}
            resetECSSubmissionStatus={resetECSSubmissionStatus}
            setActivityStatus={setActivityStatus}
            setElectronicConsent={setElectronicConsent}
            setBankMandateStatus={setBankMandateStatus}
            selectChannelType={selectChannelType}
            setEscFormSubmitStatus={setEscFormSubmitStatus}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
    isLGView: state.appState.deviceInfo.isLGView,
    accountId: state.onboardingFlowState.accountId
});

export default connect(mapStateToProps, {})(BankMandate);
