import React from 'react';
import {Link, withRouter, NavLink} from "react-router-dom";
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MenuItem from '@mui/material/MenuItem';

import appTheme from "../../assets/appTheme";
import {userConst} from "../../user/userConsts";
import {isEmpty} from "../../utils/helperCommon";
import {getRandomColors} from "../../utils/helper";
import logo from '../../assets/img/simplicity_logo.jpg';
import {resetLeadFilters, setLeadsRowPerPageCount} from "../../leads/LeadsActions";
import {resetOnboardingListingFilters, setOnboardingListingRowPerPageCount}  from "../../onboarding/listing/onboardingListingAction";
import homeSelected from "../../assets/img/homeSelected.png";
import home from "../../assets/img/home.png";
import leadsSelected from "../../assets/img/leadsSelected.png";
import leads from "../../assets/img/leads.png";
import onboardingSelected from "../../assets/img/onboardingSelected.png";
import onboarding from "../../assets/img/onboarding.png";
import clientsSelected from "../../assets/img/clientsSelected.png";
import clients from "../../assets/img/clients.png";
import "../../assets/css/headerFooter.css"
import DLText from "../../core/components/DLText/DLText";

AppResAppBarView.propTypes = {};

const BASE_URL = process.env.REACT_APP_S3_IMAGE_PUBLIC_BASE_URL;

function AppResAppBarView(props) {

    const {userAuthStatus, isXSView} = props;

    const renderAppLogo = () => {
        const {isMobileView} = props;
        return (
            <div className='d-flex align-items-center'>
                <img className='rounded-2 me-1 cursor'
                     src={logo}
                     onClick={() => props.history.push('/')}
                     alt={'logo'}
                     width={isMobileView ? '28px' : '30px'}
                     height={isMobileView ? '28px' : '30px'}/>
                <div id={"logo"} className='cursor'
                     style={{color: appTheme.appColor.black, fontSize: 24, fontWeight:500}}
                     onClick={() => props.history.push('/')}>Advisory
                </div>
            </div>
        );
    };

    const handleClick = (event, path) => {
        event.preventDefault(); // Prevent the default navigation behavior
        if('/onboardings'===path)
        {
            resetOnboardingListingFilters();
            setLeadsRowPerPageCount(10);
        }
        else{
            resetLeadFilters();
            setOnboardingListingRowPerPageCount(10);
        }
        setTimeout(() => {
            props.history.push(path); // Redirect after timeout
        }, 100);
    };

    const getMenubarStatus = () => {
        return (location.pathname === '/leads'
            ||location.pathname === '/home'
            ||location.pathname === '/'
            ||location.pathname === '/onboardings'
            ||location.pathname === '/clients')
    };

    const renderMobileDrawerBtn = () =>{
        const {isXSView, isSMView} = props;
        let isVisibleMenuBar = getMenubarStatus();

        if((isXSView || isSMView) && isVisibleMenuBar){
            return(
                <div className="position-fixed end-0 bottom-0 bg-white border-top w-100 px-3 "
                     style={{height: 54}}>
                    <nav id='app-header-nav-mobile'
                         className="d-flex align-items-center h-100"
                         style={{gap: isXSView ? 0 : 16}}>
                        <NavLink exact to="/" style={{flex:1}}>
                            <div className="horizontal-line"/>
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <img src={(location.pathname === '/') ? homeSelected : home}
                                     height={22}
                                     width={16}
                                     style={{paddingBottom: 4}}
                                />
                                Home
                            </div>
                        </NavLink>
                        <NavLink to="/leads" style={{flex:1}}>
                            <div className="horizontal-line"/>
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <img
                                    src={(location.pathname.includes('/leads')) ? leadsSelected : leads}
                                    height={30}
                                    width={26}
                                    style={{paddingBottom: 4}}
                                />
                                Leads
                            </div>
                        </NavLink>
                        <NavLink to="/onboardings" style={{flex:1}}>
                            <div className="horizontal-line"/>
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <img src={(location.pathname.includes('/onboardings')) ? onboardingSelected : onboarding}
                                     height={24}
                                     width={18}
                                     style={{paddingBottom: 4}}
                                />
                                Onboardings
                            </div>
                        </NavLink>
                        <NavLink to="/clients" style={{flex:1}}>
                            <div className="horizontal-line"/>
                            <div className="d-flex flex-column align-items-center justify-content-center h-100">
                                <img
                                    src={(location.pathname.includes('/clients')) ? clientsSelected : clients}
                                    height={22}
                                    width={16}
                                    style={{paddingBottom: 4}}
                                />
                                Clients
                            </div>
                        </NavLink>
                    </nav>
                </div>
            )
        }

    };

    const renderNavigatorWithOptions = () => {
        const {isXSView, isSMView} = props;
        return (
            (isXSView || isSMView) ? <></> :<div className='ms-5'>
                <nav id='app-header-nav' className="d-flex align-items-center" style={{gap: 40}}>
                    <NavLink exact to="/" className='text-black fs-6'>
                        Home
                    </NavLink>
                    <NavLink to="/leads"
                             onClick={(event) => handleClick(event, '/leads')}
                             className='text-black fs-6'>
                        Leads
                    </NavLink>
                    <NavLink to="/onboardings"
                             onClick={(event) => handleClick(event, '/onboardings')}
                             className='text-black fs-6'>
                        Onboardings
                    </NavLink>
                    <NavLink to="/clients" className='text-black fs-6'>
                        Clients
                    </NavLink>
                </nav>
            </div>
        );
    };
    const renderLogoutField = () => {
        return (
            <div className='d-flex gap-2 align-items-center pt-3 pb-2 flex-grow-1'
                 style={{color:appTheme.appColor.primary}}>
                    <div>
                        <i className="fa-solid fa-arrow-right-from-bracket"/>
                    </div>
                    <div style={{color:appTheme.linkStyle.color,fontFamily:appTheme.default.fontFamily}}>Logout</div>
            </div>
        );
    };

    const renderNameAndIcon = () => {
        const {profile} = props;
        let profileImage = '';

        if(!isEmpty(profile)){
            profileImage = profile?.firstName?.slice(0, 1) + profile?.lastName?.slice(0, 1)
        }
        return (
            <div className='d-flex mx-1 ps-2 pb-3 align-items-center border-bottom'>
                <Avatar className='profile-img p-3 '
                        style={{backgroundColor: getRandomColors(profile?.firstName)
                        }}>
                    {isEmpty(profileImage) ? <></> :
                        profileImage.toUpperCase()
                    }
                </Avatar>
                <div className='ms-2'>
                    <div>
                        <DLText id={'profile-name'}
                                 isInline={true}
                                 fontSize={'sm'}
                                 fontWeight={'semi-bold'}
                                 text={ ' '+ profile?.firstName+ ' ' +profile?.lastName }/>
                    </div>
                    <div>
                        <DLText id={'profile-role'}
                                 isInline={true}
                                 fontSize={'xs'}
                                 fontColor={'grayDark'}
                                 text={profile?.advisoryRole}/>
                    </div>

                </div>
            </div>
        )
    };


    const renderProfileMenu = () => {
        const {profile, setUserAuthStatus, anchorEl, handleItemClose, handleItemOpen} = props;

        let profileImage;

        if (!isEmpty(profile)) {
            profileImage = profile?.firstName?.slice(0, 1) + profile?.lastName?.slice(0, 1);
        }

        let menuOptions = [
            {
                label: renderLogoutField(),
                onClick: () => {
                    setUserAuthStatus(userConst.AUTH_STATUS_UNAUTHORIZED);
                    props.logoutUser();
                    props.history.push('/');
                },
                isVisible: true
            },

        ];

        let isIconVisible = false;

        menuOptions.forEach(item => {
            if (item?.isVisible) {
                isIconVisible = true
            }
        });

        if (!isIconVisible) {
            return;
        }

        let icon = (
            <div className="d-flex align-items-center">
                <div className='cursor'>
                    <Avatar className='profile-img p-3 cursor'
                            onClick={handleItemOpen}
                            style={{backgroundColor: getRandomColors(profile?.firstName)}}>
                        {isEmpty(profileImage) ? <></> :
                            profileImage.toUpperCase()
                        }
                    </Avatar>
                </div>
            </div>
        );


        let menu = (
            <Menu id="items-menu"
                  anchorEl={anchorEl}
                  getcontentanchorel={null}
                  anchorOrigin={{vertical: "bottom", horizontal: "right"}}
                  transformOrigin={{vertical: "top", horizontal: "right"}}
                  open={Boolean(anchorEl)}
                  onClose={() => handleItemClose()}
                  className="p-2 w-100"
                  disableAutoFocusItem
                  PaperProps={{
                      style: {
                          marginTop: '10px',
                      }
                  }}
                  MenuListProps={{
                      style: {
                          minWidth: 200
                      },
                  }}>
                <div>
                    {renderNameAndIcon()}
                </div>
                {menuOptions.map((item, index) => {
                    if (item?.isVisible) {
                        return (
                            <MenuItem key={'menuItem-' + index}
                                      className={'cursor  mx-3 p-0'}
                                      onClick={() => {
                                          handleItemClose();
                                          item.onClick();
                                      }}>
                                {item?.label}
                            </MenuItem>
                        )
                    }
                    return <span/>
                })}
            </Menu>
        );
        return (
            <span>
                {icon}
                {menu}
            </span>
        );
    };

    const authorizedContentUi = () => {
        const {isXLView, handleSettingIconClick} = props;
        return (
            <div className='w-100'>
                {renderMobileDrawerBtn()}
                <div className="d-flex justify-content-between w-100 " style={{height: "100%"}}>
                    <div className='d-flex align-items-center w-100'>
                        <div style={{marginRight: isXLView ? 70 : 20}}>
                            {renderAppLogo()}
                        </div>
                        <div>
                            {renderNavigatorWithOptions()}
                        </div>
                    </div>

                    <div className='d-flex align-items-center flex-shrink-0'>
                        {renderProfileMenu()}
                    </div>
                </div>
            </div>
        );

    };

    const unauthorizedContentUi = () => {
        const {isXLView, isMobileView} = props;
        return (
            <div className='w-100'>
                <div className="d-flex justify-content-between w-100 " style={{height: "100%"}}>
                    <div className='d-flex align-items-center w-100'>
                        <div style={{marginRight: isXLView ? 70 : 20}}>
                            <div className='d-flex align-items-center'>
                                <img className='rounded-2 me-1'
                                     src={logo}
                                     alt={'logo'}
                                     width={isMobileView ? '28px' : '30px'}
                                     height={isMobileView ? '28px' : '30px'}/>
                                <div id={"logo"}
                                     style={{color: appTheme.appColor.black, fontSize: 24, fontWeight:500}}>
                                    Advisory
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    if (props.location.pathname === '/session-expired') {
        return (
            <div className="">
                <AppBar drawerwidth={0}
                        style={{backgroundColor: appTheme.appColor.white, borderBottom: '2px solid lightGray'}}
                        position="fixed"
                        open={true}
                        className='shadow-none d-flex'>
                    <Toolbar className='' style={{height: '50px', minHeight: '50px '}}>
                        {unauthorizedContentUi()}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }

    if (userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) {
        return (
            <div className="">
                <AppBar drawerwidth={0}
                        style={{backgroundColor: appTheme.appColor.white, borderBottom: '2px solid lightGray'}}
                        position="fixed"
                        open={true}
                        className='shadow-none d-flex'>
                    <Toolbar className='' style={{height: '50px', minHeight: '50px '}}>
                        {(userAuthStatus === userConst.AUTH_STATUS_AUTHORIZED) ? authorizedContentUi() : unauthorizedContentUi()}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
    return <></>
}

export default AppResAppBarView;