import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getDocAssociateData, updateGuardian, updateNominee} from "../../OnboardingActions";
import {isEmpty} from "../../../utils/validations";
import {isAlphabetic, isIncludeSpecialCharacters, validateAge} from "../../../utils/helper";
import MinorNomineeCreateOrUpdateView from "./MinorNomineeCreateOrUpdateView";

const MinorNomineeCreateOrUpdate = (props) => {

    const {nomineeId, requiredDocuments, nomineeData, nomineeType, dropdownValues, guardianId} = props;

    const [minorNomineeState, setMinorNomineeState] = useState({
        personalInformation : {
            type : "Y",  // N is major Y is minor
            dateOfBirth: null,
            name: "",
        },
        nomineeInformation : {
            relation: "",
            percentageAllocation: 1,
        },
        guardian : {
            type : "Y",  // N is major Y is minor
            dateOfBirth: null,
            name: "",
            PANNumber: ""
        },
        guardianRelationWithNominee : {
            relation: ""
        },
        guardianAddress:{
            countryCode: "IND",
            city: "",
            stateCode: "",
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            zipCode: ""
        },
        minorsDocument: {
            documentType:"MINOR_BIRTH_CERTIFICATE",
            documentCategory:"MINOR_BIRTH_CERTIFICATE",
            originalFileName: '',
            uploadedFileName: '',
            filePath: '',
            fileName: '',
            BIRTH_CERTIFICATE: '',
            id: '',
        },
        guardiansDocument: {
            documentType:"GUARDIAN_PAN_CARD",
            documentCategory:"GUARDIAN_PAN_CARD",
            originalFileName: '',
            uploadedFileName: '',
            filePath: '',
            fileName: '',
            PAN_CARD: '',
            id: '',
        },
        errors:{
            personalInformation : {
                dateOfBirth: "",
                name: "",
            },
            nomineeInformation : {
                relation: "",
                percentageAllocation: "",
            },
            guardian : {
                dateOfBirth: '',
                name: "",
                PANNumber: "",
            },
            guardianRelationWithNominee : {
                relation: ""
            },
            guardianAddress:{
                city: "",
                stateCode: "",
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                zipCode: ""
            },
            minorsDocument: {
                BIRTH_CERTIFICATE: ''
            },
            guardiansDocument:{
                PAN_CARD: ''
            },
        },
        isLoaderVisible: false,
        scrollToFieldId: '',
        responseErrors: [],
        totalErrors: '',
        minorsDocs : [],
        guardiansDocs : []
    });

    useEffect(()=>{
        setNomineeDetails()
    },[nomineeData,dropdownValues])

    useEffect(()=>{
        setDocuments()
    },[minorNomineeState.minorsDocs, minorNomineeState.guardiansDocs])

    const setNomineeDetails = () =>{
        if(nomineeType !== "NEW" && nomineeData?.type === "Y"){
            getRequiredDocuments();
            setMinorNomineeState((prevState)=>({
                ...prevState,

                personalInformation : {
                    type : "Y",  // N is major Y is minor
                    dateOfBirth: isEmpty(nomineeData?.dateOfBirth)? null : nomineeData?.dateOfBirth,
                    name: isEmpty(nomineeData?.name)? "" : nomineeData?.name,
                },
                nomineeInformation : {
                    relation: isEmpty(nomineeData?.relation)? "" : getDropdownSelectedObj("relations",nomineeData?.relation, dropdownValues),
                    percentageAllocation: isEmpty(nomineeData?.percentageAllocation)? 1 : nomineeData?.percentageAllocation,
                },
                guardian : {
                    type : "Y",  // N is major Y is minor
                    dateOfBirth: isEmpty(nomineeData?.guardian?.dateOfBirth)? null : nomineeData?.guardian?.dateOfBirth,
                    name: isEmpty(nomineeData?.guardian?.name)? "" : nomineeData?.guardian?.name,
                    PANNumber: isEmpty(nomineeData?.guardian?.PANNumber)? "" : nomineeData?.guardian?.PANNumber
                },
                guardianRelationWithNominee : {
                    relation: isEmpty(nomineeData?.guardian?.relation)? "" : nomineeData?.guardian?.relation
                },
                guardianAddress:{
                    countryCode: "IND",
                    city: isEmpty(nomineeData?.guardian?.guardianAddress?.city)? "" : nomineeData?.guardian?.guardianAddress?.city,
                    stateCode: isEmpty(nomineeData?.guardian?.guardianAddress?.stateCode)? "" : getDropdownSelectedObj("states",nomineeData?.guardian?.guardianAddress?.stateCode, dropdownValues),
                    addressLine1: isEmpty(nomineeData?.guardian?.guardianAddress?.addressLine1)? "" : nomineeData?.guardian?.guardianAddress?.addressLine1,
                    addressLine2: isEmpty(nomineeData?.guardian?.guardianAddress?.addressLine2)? "" : nomineeData?.guardian?.guardianAddress?.addressLine2,
                    addressLine3: isEmpty(nomineeData?.guardian?.guardianAddress?.addressLine3)? "" : nomineeData?.guardian?.guardianAddress?.addressLine3,
                    zipCode: isEmpty(nomineeData?.guardian?.guardianAddress?.zipCode)? "" : nomineeData?.guardian?.guardianAddress?.zipCode
                }

            }))
        }
    };

    const getRequiredDocuments = () =>{
        if(isEmpty(requiredDocuments)){
            return;
        }
        let minorsData = "";
        let guardiansData = "";
        requiredDocuments.forEach((item)=>{
            if(item.holderType === "GUARDIAN"){
                guardiansData = item?.documentCategory.documentTypes
            }
            if(item.holderType === "NOMINEE_MINOR"){
                minorsData = item?.documentCategory.documentTypes
            }
        });

        setMinorNomineeState(prevStep => ({
            ...prevStep,
            minorsDocs : minorsData,
            guardiansDocs : guardiansData
        }));
    };

    const setDocuments = () =>{
        const {minorsDocs, guardiansDocs} =minorNomineeState;
        let minorsRequiredDocuments = [];
        let guardiansRequiredDocuments = [];
        guardiansDocs.forEach((item)=>{
            if( item.id === "GUARDIAN_PAN_CARD"){
                guardiansRequiredDocuments = item?.documents
            }
        });
        minorsDocs.forEach((item)=>{
            if( item.id === "MINOR_BIRTH_CERTIFICATE"){
                minorsRequiredDocuments = item?.documents
            }
        });

        if(!isEmpty(requiredDocuments)){
            setMinorNomineeState(prevStep => ({
                ...prevStep,
                minorsDocument: {
                    ...prevStep.minorsDocument,
                    filePath: isEmpty(minorsRequiredDocuments[0]?.imageFilePaths) ? '' : minorsRequiredDocuments[0]?.imageFilePaths[0],
                    fileName: minorsRequiredDocuments[0]?.fileName,
                    id: minorsRequiredDocuments[0]?.id
                },
                guardiansDocument: {
                    ...prevStep.guardiansDocument,
                    filePath: isEmpty(guardiansRequiredDocuments[0]?.imageFilePaths) ? '' : guardiansRequiredDocuments[0]?.imageFilePaths[0],
                    fileName: guardiansRequiredDocuments[0]?.fileName,
                    id: guardiansRequiredDocuments[0]?.id
                },
            }));
        }
    }


    const getDropdownSelectedObj=(type,selectedValue, dropDownValues)=>{
        if(isEmpty(selectedValue)){
            return ''
        }
        return dropDownValues[type]?.find(dropdownVal=>{
            return dropdownVal.value === selectedValue})
    };

    const getRequiredDocumentId = (name) =>{
        if(isEmpty(requiredDocuments)){
            return;
        }
        let requirementId = ""

        requiredDocuments.forEach((item)=>{
            if(item?.holderType === name){
                requirementId = item?.id
            }
        })

        return requirementId;
    }

    const handleChange = (name, value, fieldType) => {
        let updatedCompState = {...minorNomineeState};

        if (fieldType === "personalInformation") {
            updatedCompState.personalInformation[name] = value;
        } else if (fieldType === "nomineeInformation") {
            updatedCompState.nomineeInformation[name] = value;
        } else if (fieldType === "guardianRelationWithNominee") {
            updatedCompState.guardianRelationWithNominee[name] = value;
        }else if (fieldType === "guardian") {
            updatedCompState.guardian[name] = value;
        } else if (fieldType === "guardianAddress") {
            updatedCompState.guardianAddress[name] = value;
        }else {
            updatedCompState[name] = value;
        }

        const validateObject = validateChanges(updatedCompState, name, fieldType);

        setMinorNomineeState(prevStep => ({
            ...prevStep,
            ...updatedCompState,
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));

    };

    const validatePersonalInformation = (componentState, attr) => {
        const {personalInformation, errors} = componentState;
        const {name, dateOfBirth} = personalInformation;

        let errorObj = {...errors.personalInformation};

        if (attr === 'name' || attr === 'SUBMIT') {
            if (isEmpty(name)) {
                errorObj.name = 'Please enter name'
            }  else if (isIncludeSpecialCharacters(name) || isAlphabetic(name)) {
                errorObj.name = 'Please enter alphabets only'
            } else if (name.length > 62) {
                errorObj.name = 'Please enter 60 alphabets only'
            } else {
                errorObj.name = ''
            }

            if (attr === 'name') {
                return errorObj
            }
        }

        if (attr === 'dateOfBirth' || attr === 'SUBMIT') {
            if (isEmpty(dateOfBirth)) {
                errorObj.dateOfBirth = 'Please enter date of birth'
            }else {
                errorObj.dateOfBirth = ''
            }

            if (attr === 'dateOfBirth') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateNomineeInformation = (componentState, attr) => {
        const {nomineeInformation, errors} = componentState;
        const {relation, percentageAllocation} = nomineeInformation;

        let errorObj = {...errors.nomineeInformation};

        if (attr === 'relation' || attr === 'SUBMIT') {
            if (isEmpty(relation)) {
                errorObj.relation = 'Please select relation'
            }else {
                errorObj.relation = ''
            }

            if (attr === 'relation') {
                return errorObj
            }
        }

        if (attr === 'percentageAllocation' || attr === 'SUBMIT') {
            if (isEmpty(percentageAllocation)) {
                errorObj.percentageAllocation = 'Please enter percentage allocation'
            }else if (percentageAllocation > 100) {
                errorObj.percentageAllocation = 'Allocation can not be more than 100 %'
            }else if (percentageAllocation < 0) {
                errorObj.percentageAllocation = 'Allocation can not be less than 0 %'
            }else {
                errorObj.percentageAllocation = ''
            }

            if (attr === 'percentageAllocation') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateGuardianInformation = (componentState, attr) => {
        const {guardian, errors} = componentState;
        const {name, dateOfBirth, PANNumber} = guardian;

        let errorObj = {...errors.guardian};

        if (attr === 'name' || attr === 'SUBMIT') {
            if (isEmpty(name)) {
                errorObj.name = 'Please enter name'
            }  else if (isIncludeSpecialCharacters(name) || isAlphabetic(name)) {
                errorObj.name = 'Please enter alphabets only'
            } else if (name.length > 62) {
                errorObj.name = 'Please enter 60 alphabets only'
            } else {
                errorObj.name = ''
            }

            if (attr === 'name') {
                return errorObj
            }
        }


        if (attr === 'PANNumber' || attr === 'SUBMIT') {
            if (isEmpty(PANNumber)) {
                errorObj.PANNumber = 'Please enter PAN number'
            } else if (isIncludeSpecialCharacters(PANNumber)) {
                errorObj.PANNumber = 'Please enter valid PAN number'
            } else if (PANNumber?.length !== 10) {
                errorObj.PANNumber = 'PAN number must be of 10 characters'
            } else {
                errorObj.PANNumber = ''
            }
            if (attr === 'PANNumber') {
                return errorObj
            }
        }

        if (attr === 'dateOfBirth' || attr === 'SUBMIT') {
            if (isEmpty(dateOfBirth)) {
                errorObj.dateOfBirth = 'Please enter date of birth'
            } else if (validateAge(dateOfBirth, 18)) {
                errorObj.dateOfBirth = 'Applicant age must be above 18'
            }else {
                errorObj.dateOfBirth = ''
            }

            if (attr === 'dateOfBirth') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateGuardianAddress = (componentState, name) => {
        const {guardianAddress, errors} = componentState;
        const {addressLine1, addressLine2, addressLine3, zipCode, stateCode, city} = guardianAddress;


        let errorObj = {...errors.guardianAddress};

        if ((name === 'addressLine1') || name === 'SUBMIT') {
            if (isEmpty(addressLine1)) {
                errorObj.addressLine1 = "Please enter address line 1"
            }else if (addressLine1.length > 50) {
                errorObj.addressLine1 = "Please enter 50 characters only"
            } else {
                errorObj.addressLine1 = ""
            }
            if (name === 'addressLine1') {
                return errorObj
            }
        }

        if ((name === 'addressLine2') || name === 'SUBMIT') {
            if (addressLine2.length > 50) {
                errorObj.addressLine2 = "Please enter 50 characters only"
            }else {
                errorObj.addressLine2 = ""
            }
            if (name === 'addressLine2') {
                return errorObj
            }
        }

        if ((name === 'addressLine3') || name === 'SUBMIT') {
            if (addressLine3.length > 50) {
                errorObj.addressLine3 = "Please enter 50 characters only"
            } else {
                errorObj.addressLine3 = ""
            }
            if (name === 'addressLine3') {
                return errorObj
            }
        }

        if ((name === 'city') || name === 'SUBMIT') {
            if (isEmpty(city)) {
                errorObj.city = "Please enter city"
            }else if (city.length > 30) {
                errorObj.city = "Please enter 30 characters only"
            } else {
                errorObj.city = ""
            }
            if (name === 'city') {
                return errorObj
            }
        }

        if ((name === 'zipCode') || name === 'SUBMIT') {

            if (isEmpty(zipCode)) {
                errorObj.zipCode = "Please enter pincode"
            }else if (zipCode.length > 6) {
                errorObj.zipCode = "Please enter 6 characters only"
            } else {
                errorObj.zipCode = ""
            }
            if (name === 'zipCode') {
                return errorObj
            }
        }

        if ((name === 'stateCode') || name === 'SUBMIT') {
            if (isEmpty(stateCode)) {
                errorObj.stateCode = "Please select state"
            } else {
                errorObj.stateCode = ""
            }
            if (name === 'stateCode') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateGuardianRelationWithNominee = (componentState, name) => {
        const {guardianRelationWithNominee,errors} = componentState;
        const {relation} = guardianRelationWithNominee;

        let errorObj = {...errors.guardianRelationWithNominee};

        if ((name === 'relation') || name === 'SUBMIT') {
            if (isEmpty(relation)) {
                errorObj.relation = "Please select relation"
            } else {
                errorObj.relation = ""
            }
            if (name === 'relation') {
                return errorObj
            }
        }

        return errorObj;
    };

    const validateChanges = (componentState, name, fieldType) => {
        const {errors} = componentState;
        let errorObj = errors;

        if (fieldType === 'personalInformation' || isEmpty(fieldType)) {
            errorObj.personalInformation = validatePersonalInformation(componentState, name);
        }
        if (fieldType === 'nomineeInformation' || isEmpty(fieldType)) {
            errorObj.nomineeInformation = validateNomineeInformation(componentState, name);
        }
        if (fieldType === 'guardian' || isEmpty(fieldType)) {
            errorObj.guardian = validateGuardianInformation(componentState, name);
        }
        if (fieldType === 'guardianAddress' || isEmpty(fieldType)) {
            errorObj.guardianAddress = validateGuardianAddress(componentState, name);
        }
        if (fieldType === 'guardianRelationWithNominee' || isEmpty(fieldType)) {
            errorObj.guardianRelationWithNominee = validateGuardianRelationWithNominee(componentState, name);
        }

        return errorObj;
    };

    const simplifyObjectValues = (input) => {
        // Create a copy of the input object to avoid mutating the original object
        const output = {...input};

        // Iterate through the keys of the object
        for (const key in output) {
            // If the property's value is an object and has a 'value' key, replace it
            if (typeof output[key] === 'object' && output[key] !== null && 'value' in output[key]) {
                output[key] = output[key].value;
            }
        }
        return output;
    };

    const prepareGuardiansPayload = () => {
        const {accountId} = props;
        let updatedData = {
            ...minorNomineeState,
            ...minorNomineeState.guardian,
            ...minorNomineeState.guardianRelationWithNominee
        };

        delete updatedData.personalInformation;
        delete updatedData.guardianRelationWithNominee;
        delete updatedData.nomineeInformation;
        delete updatedData.guardian;
        delete updatedData.errors;
        delete updatedData.guardiansDocument;
        delete updatedData.minorsDocument;
        delete updatedData.isLoaderVisible;
        delete updatedData.scrollToFieldId;
        delete updatedData.responseErrors;
        delete updatedData.totalErrors;
        delete updatedData.document;

        let guardiansDataObj = {
            ...updatedData,
        };
        guardiansDataObj = simplifyObjectValues(guardiansDataObj);
        guardiansDataObj.guardianAddress = simplifyObjectValues(guardiansDataObj.guardianAddress)
        guardiansDataObj.accountId = accountId;
        guardiansDataObj.nomineeId = nomineeId;

        return guardiansDataObj;
    };

    const prepareMinorsPayload = () => {
        const {accountId} = props;
        let updatedData = {
            ...minorNomineeState,
            ...minorNomineeState.personalInformation,
            ...minorNomineeState.nomineeInformation
        };

        delete updatedData.personalInformation;
        delete updatedData.guardianRelationWithNominee;
        delete updatedData.nomineeInformation;
        delete updatedData.guardiansDocument;
        delete updatedData.minorsDocument;
        delete updatedData.guardian;
        delete updatedData.guardianAddress;
        delete updatedData.errors;
        delete updatedData.isLoaderVisible;
        delete updatedData.scrollToFieldId;
        delete updatedData.responseErrors;
        delete updatedData.totalErrors;
        delete updatedData.document;

        let nomineeDataObj = {
            ...updatedData,
        };
        nomineeDataObj = simplifyObjectValues(nomineeDataObj);
        nomineeDataObj.accountId = accountId;
        nomineeDataObj.nomineeId = nomineeId;

        return nomineeDataObj;
    };

    const onSaveClick = () => {
        const {profile, isProceedWithErrors, handleCloseCreateModal} = props;

        setMinorNomineeState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));
        let errorId = '';


        const validateObject = validateChanges(minorNomineeState, 'SUBMIT');
        let minorsPayload = prepareMinorsPayload();
        let guardiansPayload = prepareGuardiansPayload();
        let validatorErrors = [];
        let apiErrors = [];


        updateNominee(minorsPayload, profile.advisoryId)
            .then(minorsRes => {

                if(!isEmpty(minorsRes?.data?.validationErrors)){
                    minorsRes?.data?.validationErrors.forEach((item)=>{
                        validatorErrors.push(item)
                    })
                }
                if(!isEmpty(minorsRes?.__error)){
                    apiErrors.push(minorsRes?.__error)
                }

                updateGuardian(guardiansPayload, profile.advisoryId)
                    .then(res => {

                        if(!isEmpty(res?.data?.validationErrors)){
                            res?.data?.validationErrors.forEach((item)=>{
                                validatorErrors.push(item)
                            })
                        }
                        if(!isEmpty(res?.__error)){
                            apiErrors.push(res?.__error)
                        }

                        if (!isEmpty(apiErrors)) {
                            let responseErrors = [];
                            if(apiErrors){
                                responseErrors.push(apiErrors);
                                errorId= 'page-level-errors'
                            }
                            setMinorNomineeState(prevStep => ({
                                ...prevStep,
                                errors: {
                                    ...prevStep.errors,
                                    ...validateObject,
                                },
                                responseErrors: responseErrors,
                                totalErrors: responseErrors.length,
                                isLoaderVisible: false,
                                scrollToFieldId: errorId
                            }));
                        } else {
                            if(isEmpty(validatorErrors)){
                                handleCloseCreateModal();
                                setMinorNomineeState(prevState => ({
                                    ...prevState,
                                    isLoaderVisible: true
                                }));
                            }else{
                                assignResponseError(validatorErrors, validateObject)
                            }
                        }
                        if (isProceedWithErrors) {
                            handleCloseCreateModal();
                            setMinorNomineeState(prevState => ({
                                ...prevState,
                                isLoaderVisible: true
                            }));
                        }
                    })
            })
    };

    const assignResponseError = (response, stateErrors) => {

        const errorResponse = response;

        let newStateErrors = {...stateErrors};
        let responseErrors = [];

        if (isEmpty(errorResponse)) { //handled state if reponse status is failed
            setMinorNomineeState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...newStateErrors,
                },
                isLoaderVisible: false,
                responseErrors: [],
                totalErrors: 0,
            }));
            return
        }

        errorResponse.forEach(error => { //assign each field by section name
            const section = error.section;
            if (!newStateErrors[section]) {
                return;
            }
            if ((error.fields).length > 1) { //add page level validation error
                responseErrors.push(error.message)
            } else {
                //assign each field error to respected field and log if invalid field is found
                error.fields.forEach(field => {
                    newStateErrors[section][field] = error.message;
                });
            }
        });
        let errors = {
            ...minorNomineeState.errors,
            ...newStateErrors,
        };
        setMinorNomineeState(prevStep => ({
            ...prevStep,
            errors: {
                ...prevStep.errors,
                ...newStateErrors,
            },
            responseErrors: responseErrors,
            totalErrors: responseErrors.length,
            isLoaderVisible: false,
            scrollToFieldId: isEmpty(responseErrors) ? getFirstErrorFieldId(errors) : 'page-level-errors'
        }));
    };

    const getFirstErrorFieldId = (errors) => {
        let sectionOrderToSort =["personalInformation", "nomineeInformation", "guardian", "guardianRelationWithNominee",
            "guardianAddress"];
        for (const section of sectionOrderToSort) {
            if (errors[section]) {
                for (const key in errors[section]) {

                    const error = errors[section][key];
                    if (error && typeof error === 'object' && 'label' in error && error.label !== 'type') {
                        return section + '-' + error.label + '-id';
                    }
                    if (typeof error === 'string' && error && key !== 'type') {
                        return section + '-' + key + '-id';
                    }
                }
            }
        }
        return null;
    };

    const associateMinorsDocument = (type, response) => {
        const {profile, accountId} = props;
        const {minorsDocument, dropdownValues} = minorNomineeState;

        if (type === 'DELETE') {
            setMinorNomineeState(prevStep => ({
                ...prevStep,
                minorsDocument: {
                    ...minorsDocument,
                    filePath: ''
                },
                errors: {
                    ...prevStep.errors,
                    minorsDocument: {
                        ...prevStep.errors.minorsDocument,
                        BIRTH_CERTIFICATE: ''
                    }
                },
                responseErrors: [],
                totalErrors: 0,
            }));
            return;
        }

        setMinorNomineeState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true,
        }));

        const associatePayload = {
            type: minorsDocument.documentType,
            accountId: accountId,
            nomineeId: nomineeId,
            documentRequirementId: getRequiredDocumentId("NOMINEE_MINOR"),
            originalFileName: response?.originalFileName,
            uploadedFileName: response?.uploadedFileName
        };

        getDocAssociateData(associatePayload, profile?.advisoryId)
            .then((res) => {
                if (res.success) {
                    const mappedState = getMappedData(res.data.documentDetails, dropdownValues);
                    setMinorNomineeState(prevStep => ({
                        ...prevStep,
                        ...mappedState,
                        minorsDocument: {
                            ...minorsDocument,
                            filePath: (isEmpty(res.data?.imageFilePaths)) ? '' : res.data?.imageFilePaths[0],
                            fileName: res.data?.fileName,
                            id: res.data?.id
                        },
                        isLoaderVisible: false,
                        responseErrors: [],
                        totalErrors: 0,
                    }));
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setMinorNomineeState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                            minorsDocument: {
                                ...prevStep.errors.minorsDocument,
                                BIRTH_CERTIFICATE: '',
                                filePath: '',
                                id: ''
                            }
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                    }));
                }
            })
    };

    const getMappedData = (response) => {
        const {errors} = minorNomineeState;
        if (isEmpty(response)) {
            return {...minorNomineeState}
        }
        return {
            personalInformation: mapPersonalInformation(response),
            errors: {
                ...errors,
                ...mapPersonalInformationErrors(errors, response),
            },
        };
    };

    const mapPersonalInformation = (PANDetails) => {
        let personDetails = PANDetails;
        return {
            ...minorNomineeState.personalInformation,
            name: isEmpty(personDetails?.name) ? '' : personDetails?.name,
            dateOfBirth: isEmpty(personDetails?.dateOfBirth) ? '' : personDetails?.dateOfBirth,
        };
    };

    const mapPersonalInformationErrors = (errors, PANDetails) => {

        let errorPANInformationObj = {...errors.personalInformation};
        if (!isEmpty(PANDetails?.name)) {
            errorPANInformationObj.name = ''
        }
        if (!isEmpty(PANDetails?.dateOfBirth)) {
            errorPANInformationObj.dateOfBirth = ''
        }

        return {
            personalInformation: errorPANInformationObj
        };
    };

    const associateGuardiansDocument = (type, response) => {
        const {profile, accountId} = props;
        const {guardiansDocument, dropdownValues} = minorNomineeState;

        if (type === 'DELETE') {
            setMinorNomineeState(prevStep => ({
                ...prevStep,
                guardiansDocument: {
                    ...guardiansDocument,
                    filePath: ''
                },
                errors: {
                    ...prevStep.errors,
                    guardiansDocument: {
                        ...prevStep.errors.guardiansDocument,
                        PAN_CARD: ''
                    }
                },
                responseErrors: [],
                totalErrors: 0,
            }));
            return;
        }

        setMinorNomineeState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true,
        }));

        const associatePayload = {
            type: guardiansDocument.documentType,
            accountId: accountId,
            nomineeId: nomineeId,
            guardianId: guardianId,
            documentRequirementId: getRequiredDocumentId("GUARDIAN"),
            originalFileName: response?.originalFileName,
            uploadedFileName: response?.uploadedFileName
        };

        getDocAssociateData(associatePayload, profile?.advisoryId)
            .then((res) => {
                if (res.success) {
                    const mappedState = getMappedGuardiansData(res.data?.documentDetails, dropdownValues);
                    setMinorNomineeState(prevStep => ({
                        ...prevStep,
                        ...mappedState,
                        guardiansDocument: {
                            ...guardiansDocument,
                            filePath: (isEmpty(res.data?.imageFilePaths)) ? '' : res.data?.imageFilePaths[0],
                            fileName: res.data?.fileName,
                            id: res.data?.id
                        },
                        isLoaderVisible: false,
                        responseErrors: [],
                        totalErrors: 0,
                    }));
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setMinorNomineeState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                            guardiansDocument: {
                                ...prevStep.errors.guardiansDocument,
                                PAN_CARD: '',
                                filePath: '',
                                id: ''
                            }
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                    }));
                }
            })
    };

    const getMappedGuardiansData = (response) => {
        const {errors} = minorNomineeState;
        if (isEmpty(response)) {
            return {...minorNomineeState}
        }
        return {
            guardian: mapGuardiansInformation(response),
            errors: {
                ...errors,
                ...mapGuardiansInformationErrors(errors, response),
            },
        };
    };

    const mapGuardiansInformation = (PANDetails) => {
        let personDetails = PANDetails;
        return {
            ...minorNomineeState.guardian,
            name: isEmpty(personDetails?.nameAsPerPAN) ? '' : personDetails.nameAsPerPAN,
            PANNumber: isEmpty(personDetails?.PANNumber) ? '' : personDetails.PANNumber,
            dateOfBirth: isEmpty(personDetails?.dateOfBirth) ? '' : personDetails.dateOfBirth,
        };
    };

    const mapGuardiansInformationErrors = (errors, PANDetails) => {

        let errorPANInformationObj = {...errors.guardian};
        if (!isEmpty(PANDetails?.nameAsPerPAN)) {
            errorPANInformationObj.name = ''
        }
        if (!isEmpty(PANDetails?.PANNumber)) {
            errorPANInformationObj.PANNumber = ''
        }
        if (!isEmpty(PANDetails?.dateOfBirth)) {
            errorPANInformationObj.dateOfBirth = ''
        }

        return {
            guardian: errorPANInformationObj
        };
    };


    return (
        <MinorNomineeCreateOrUpdateView {...props}
                                        {...minorNomineeState}
                                        handleChange={handleChange}
                                        onSaveClick={onSaveClick}
                                        associateGuardiansDocument={associateGuardiansDocument}
                                        associateMinorsDocument={associateMinorsDocument}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    accountId: state.onboardingFlowState.accountId
});

export default connect(mapStateToProps, {})(MinorNomineeCreateOrUpdate);