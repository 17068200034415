import React, {useEffect, useState} from 'react';
import OnboardingDetailsView from "./OnboardingDetailsView"
import {isEmpty} from "../../../utils/validations";
import {getAllRequiredDocuments, getOnboardingDetails, getPersonData, getCRMDetails} from "../../OnboardingActions";
import {connect} from "react-redux";
import store from "../../../store";
import {
    setAccountId,
    setAccountType,
    setApplicationId,
    setCRMDetails,
    setOnboardingData
} from "../../onboardingFlowReducer";
import {showToastMessage} from "../../../AppComponents/AppActions";
import {joinApplicationRoom} from "../../../user/userActions";

const OnboardingDetails = (props) => {

    const [OnboardingDetailsState, setOnboardingDetailsState] = useState({
        name: '',
        email: '',
        phone: '',
        allTabs: [
            {label: 'Documents', value: 'DOCUMENTS'},
            {label: 'Personal Profile', value: 'PERSONAL_PROFILE'},
            {label: 'Financial Profile', value: 'FINANCIAL_PROFILE'},
            {label: 'Bank Account', value: 'BANK_ACCOUNT'},
            {label: 'Nominee', value: 'NOMINEE'},
            {label: 'Forms and Review', value: 'FORMS_REVIEW'}
        ],
        selectedTab: {
            label: 'Documents',
            value: 'DOCUMENTS'
        },
        selectedScreenObj: {
            personId: '',
            screenKey: 'DOCUMENTS',
            applicantName: 'Applicant 1'
        },
        isFromOnboarding: false,
        isShowLoader: false,
        isFromOnboardingFlow: false,
        screenDetails: [],
        personId: '',
        onboardingApplicationId: '',
        totalApplicants: [],
        accountHoldersPAN: [],
        applicantDetails: [],
        saveFailureErrorMessage: 'Your data could not be saved due to the errors displayed.',
        nomineeDocuments: [],
        onboardingStatus: '',
        isReassignCRMModalOpen : false,
        isDeleteOnboardingModalOpen : false
    });
    const [anchorEl, setAnchorEl] = useState(null);

    useEffect(() => {
        getApplicationData();
        getALLCRMDetails();

        let applicationId = getApplicationId();
        store.dispatch(setApplicationId(applicationId));

        store.dispatch(joinApplicationRoom())
    }, []);

    const isManagementUser = () =>{
        const{profile} = props;
        return profile?.isManagement;
    }

    const getApplicationId = () => {
        return props.match?.params?.onboardingApplicationId
    };

    const getALLCRMDetails = () => {
        const {profile} = props;
        getCRMDetails(profile.advisoryId)
            .then((res) => {
                if (res?.success) {
                    if (!isEmpty(res?.data?.advisoryUsers)) {
                        store.dispatch(setCRMDetails(res?.data?.advisoryUsers));
                    }
                }
            })
    };

    const getDocumentsRequirements = (responseData) => {

        const {screenDetails} = OnboardingDetailsState;

        let newScreenDetails = screenDetails;
        let prosonProfiles = [];
        newScreenDetails.push(
            {
                parentScreenKey: '',
                applicantKey: '',
                applicantName: '',
                personId: '',
                screenKey: 'UPLOAD_DOCUMENTS',
                screenName: 'Upload Documents',
                processInitiated: false,
                processCompleted: false
            }
        );
        let commonDocs = responseData?.accountDocuments;
        let documentByPerson = responseData?.accountHolderDocuments;

        let applicantDocuments = [];
        documentByPerson.map((person, personId) => {
            //add document by person

            let documentsRequired = person?.documentsRequired;
            let docPersonId = person.personId;

            if (isEmpty(documentsRequired)) {
                return
            }
            documentsRequired.sort((a, b) => a.id - b.id); //sort document by id from backend

            documentsRequired.map((doc) => {
                applicantDocuments.push({
                    screenKey: doc.documentCategory.id,
                    screenName: doc.documentCategory.name,
                    personId: docPersonId,
                    parentScreenKey: 'UPLOAD_DOCUMENTS',
                    applicantKey: (personId + 1),
                    applicantName: 'Applicant ' + (personId + 1),
                    documentRequirementId: doc.id,
                    processInitiated: (doc.documentCategory.id === 'PAN_CARD' && personId === 0),
                    processCompleted: false
                })
            });
            if (person?.holderType === 'PRIMARY_HOLDER') {
                prosonProfiles.unshift(
                    {
                        parentScreenKey: '',
                        applicantKey: (personId + 1),
                        applicantName: 'Applicant ' + (personId + 1),
                        personId: docPersonId,
                        screenKey: 'PERSONAL_PROFILE',
                        screenName: 'Personal Profile',
                        processInitiated: false,
                        processCompleted: false
                    }
                )
            } else {
                prosonProfiles.push(
                    {
                        parentScreenKey: '',
                        applicantKey: (personId + 1),
                        applicantName: 'Applicant ' + (personId + 1),
                        personId: docPersonId,
                        screenKey: 'PERSONAL_PROFILE',
                        screenName: 'Personal Profile',
                        processInitiated: false,
                        processCompleted: false
                    }
                );
            }

        });

        const commonDocuments = commonDocs.map((item) => {
            //add common documents
            return {
                screenKey: item.documentCategory.id,
                screenName: item.documentCategory.name,
                personId: '',
                parentScreenKey: 'UPLOAD_DOCUMENTS',
                applicantKey: '',
                applicantName: 'Common',
                documentRequirementId: item.id,
                processInitiated: false,
                processCompleted: false
            };
        });

        let newObjects = applicantDocuments.concat(commonDocuments);
        newObjects = newObjects.concat(prosonProfiles);
        // Find index of the "UPLOAD_DOCUMENTS" object
        let uploadDocsIndex = newScreenDetails.findIndex(screen => screen.screenKey === "UPLOAD_DOCUMENTS");

        // Insert new objects after the "UPLOAD_DOCUMENTS" object
        if (uploadDocsIndex !== -1) {
            newScreenDetails.splice(uploadDocsIndex + 1, 0, ...newObjects);
        }
        return getUploadedDocuments(responseData, newScreenDetails)

    };

    const getUploadedDocuments = (responseData, screenDetails) => {
        let updatedScreenDetails = screenDetails;

        let documentByPerson = responseData?.accountHolderDocuments;

        documentByPerson.map((person) => {
            let selectedPersonId = person?.personId;
            //add document by person
            let documentsRequired = person?.documentsRequired;
            if (!isEmpty(documentsRequired)) {
                documentsRequired.map((document) => {
                    let currentDocumentCategory = document?.documentCategory;
                    if (!isEmpty(currentDocumentCategory)) {
                        updatedScreenDetails.map((screen) => {
                            if ((screen.screenKey === currentDocumentCategory.id) && (screen.personId === selectedPersonId)) {
                                //associate all docs with respected object
                                screen.documentTypes = currentDocumentCategory.documentTypes;
                            }
                        });
                    }

                })
            }
        });

        let commonDocs = responseData?.accountDocuments;
        commonDocs.map((document) => {
            let currentDocumentCategory = document?.documentCategory;
            if (!isEmpty(currentDocumentCategory)) {
                updatedScreenDetails.map((screen) => {
                    if (screen.screenKey === document.documentCategory.id) {
                        screen.documentTypes = document.documentCategory.documentTypes;
                    }
                });
            }
        });
        return updatedScreenDetails
    };

    const setRequiredDocuments = (accountId, primaryPersonId, applicantDetails, accountHoldersPAN, onboardingStatus) => {
        const {profile} = props;
        const {screenDetails} = OnboardingDetailsState;
        let applicationId = getApplicationId();

        getAllRequiredDocuments(accountId, profile.advisoryId)
            .then((res) => {
                if (res?.success) {
                    let documentsRequirements = getDocumentsRequirements(res.data);
                    let selectedScreenObj = documentsRequirements.find((screen) => screen?.screenKey === 'PAN_CARD');
                    const uniqueApplicantNames = [...new Set(screenDetails.map(item => (item.applicantName)).filter(name => name))];
                    setOnboardingDetailsState((prevState) => ({
                        ...prevState,
                        selectedScreenObj: selectedScreenObj,
                        responseErrors: [],
                        totalErrors: 0,
                        isShowLoader: false,
                        screenDetails: documentsRequirements,
                        personId: primaryPersonId,
                        onboardingApplicationId: applicationId,
                        totalApplicants: uniqueApplicantNames,
                        applicantDetails: applicantDetails,
                        accountHoldersPAN: accountHoldersPAN,
                        onboardingStatus:onboardingStatus,
                        selectedTab: !(props?.history.location.state === 'showErrors' || onboardingStatus !== 'DRAFT') ? {
                            label: 'Forms and Review',
                            value: 'FORMS_REVIEW'
                        } : {
                            label: 'Documents',
                            value: 'DOCUMENTS'
                        },
                    }));
                }
            })
    };

    const getApplicationData = () => {
        let applicationId = getApplicationId();
        const {profile} = props;
        let applicantDetails = [];
        let accountHoldersPAN = [];
        let primaryPersonId = '';
        if (isEmpty(applicationId)) {
            return
        }
        setOnboardingDetailsState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        getOnboardingDetails(applicationId, profile.advisoryId)
            .then((res) => {
                if (res?.success) {
                    let accountHolders = res.data?.account?.accountHolders;
                    let accountType = res.data?.details?.accountType;
                    let accountId = res.data?.account?.id;
                    let status = res.data?.status;

                    if (!isEmpty(accountHolders)) {
                        for (let i = 0; i < accountHolders?.length; i++) {
                            let personId = accountHolders[i]?.personId;
                            let holderType = accountHolders[i]?.holderType;
                            if (!isEmpty(personId)) {
                                getPersonData(personId, profile.advisoryId)
                                    .then(response => {
                                        store.dispatch(setAccountId(accountId));
                                        store.dispatch(setAccountType(accountType));
                                        store.dispatch(setOnboardingData(res.data?.account));

                                        if (response?.success) {
                                            if (!isEmpty(response.data?.PANNumber)) {
                                                if (holderType === 'PRIMARY_HOLDER') {
                                                    applicantDetails.unshift(mappedPersonData(response.data));
                                                    accountHoldersPAN.unshift({
                                                        PAN_Number: response.data?.PANNumber,
                                                        personId: personId
                                                    });
                                                } else {
                                                    applicantDetails.push(mappedPersonData(response.data));
                                                    accountHoldersPAN.push({
                                                        PAN_Number: response.data?.PANNumber,
                                                        personId: personId
                                                    });
                                                }
                                            }else{
                                                if (holderType === 'PRIMARY_HOLDER') {
                                                    applicantDetails.unshift(mappedPersonData(response.data));
                                                } else {
                                                    applicantDetails.push(mappedPersonData(response.data));
                                                }
                                            }
                                        } else {
                                            setOnboardingDetailsState((prevState) => ({
                                                ...prevState,
                                                responseErrors: [response?.__error],
                                                totalErrors: 1,
                                                isShowLoader: false,
                                                onboardingStatus: status,
                                            }));
                                        }

                                        if (accountHolders?.length === (i + 1)) {
                                            primaryPersonId = accountHolders[0]?.personId;
                                            setRequiredDocuments(accountId, primaryPersonId, applicantDetails, accountHoldersPAN, status)
                                        }
                                    });
                            }
                        }
                    }
                } else {
                    setOnboardingDetailsState((prevState) => ({
                        ...prevState,
                        responseErrors: [res?.__error],
                        totalErrors: 1,
                        isShowLoader: false,
                        onboardingApplicationId: applicationId,
                        onboardingStatus: '',

                    }));
                }
            })
    };

    const getPersonaFullName = (firstName, lastName) => {

        let fullName = '';
        if (isEmpty(firstName) && isEmpty(lastName)) {
            return ''
        }
        if (!isEmpty(firstName)) {
            fullName = firstName + ' '
        }
        if (!isEmpty(lastName)) {
            fullName += lastName
        }
        return fullName
    };

    const mappedPersonData = (personData) => {
        if (isEmpty(personData)) {
            return
        }
        return {
            personId: personData?.id,
            name: getPersonaFullName(personData.firstName, personData.lastName),
            email: (isEmpty(personData.primaryEmail)) ? '' : personData.primaryEmail,
            phone: (isEmpty(personData.primaryPhoneNumber)) ? '' : personData.primaryPhoneNumber,
        }

    };

    const getSelectProfileObjByPerson = (selectedPersonId, applicantName, personType) => {
        const {screenDetails} = OnboardingDetailsState;
        if (personType === 'APPLICANT') {
            return screenDetails.find((screen) => (screen?.screenKey === 'PERSONAL_PROFILE' && screen?.applicantName === applicantName));
        }
        return screenDetails.find((screen) => (screen?.screenKey === 'PERSONAL_PROFILE' && screen?.personId === selectedPersonId));
    };

    const handleTabChange = (name, selectedTab) => {
        const {selectedScreenObj, personId, nomineeDocuments} = OnboardingDetailsState;

        if (selectedTab.value === 'DOCUMENTS') {
            selectDocument('PAN_CARD', personId);
            return
        }
        if (selectedTab.value === 'PERSONAL_PROFILE') {
            setOnboardingDetailsState((prevState) => ({
                ...prevState,
                [name]: selectedTab,
                selectedScreenObj: getSelectProfileObjByPerson(personId, '', 'PERSON')
            }));
            return
        }

        if (selectedTab.value === 'NOMINEE') {
            setOnboardingDetailsState((prevState) => ({
                ...prevState,
                [name]: selectedTab,
                selectedScreenObj: {
                    ...selectedScreenObj,
                    parentScreenKey: '',
                    screenKey: selectedTab?.value,
                    documentTypes: nomineeDocuments
                }
            }));
            return
        }
        setOnboardingDetailsState((prevState) => ({
            ...prevState,
            [name]: selectedTab,
            selectedScreenObj: {
                ...selectedScreenObj,
                screenKey: selectedTab?.value
            }
        }));

    };

    const getUplodedDocuments = (screenId, personId) => {
        const {profile, accountId} = props;
        if (isEmpty(accountId)) {
            return
        }
        const {screenDetails} = OnboardingDetailsState;
        let updatedScreenDetails = screenDetails;

        getAllRequiredDocuments(accountId, profile.advisoryId)
            .then((res) => {
                if (res?.success) {
                    let documentByPerson = res.data?.accountHolderDocuments;

                    documentByPerson.map((person) => {
                        //add document by person
                        let documentsRequired = person?.documentsRequired;
                        let selectedPersonId = person?.personId;
                        if (!isEmpty(documentsRequired)) {
                            documentsRequired.map((document) => {
                                let currentDocumentCategory = document?.documentCategory;
                                if (!isEmpty(currentDocumentCategory)) {
                                    updatedScreenDetails.map((screen) => {
                                        if ((screen.screenKey === currentDocumentCategory.id) && (selectedPersonId === screen.personId)) { //associate all docs with respected object
                                            screen.documentTypes = currentDocumentCategory.documentTypes;
                                        }
                                    });
                                }

                            })
                        }
                    });

                    let commonDocs = res.data?.accountDocuments;
                    commonDocs.map((document) => {
                        let currentDocumentCategory = document?.documentCategory;
                        if (!isEmpty(currentDocumentCategory)) {
                            updatedScreenDetails.map((screen) => {
                                if (screen.screenKey === document.documentCategory.id) {
                                    screen.documentTypes = document.documentCategory.documentTypes;
                                }
                            });
                        }
                    });

                    let selectedScreenObj = updatedScreenDetails.find((screen) => (screen?.screenKey === screenId) && (personId === screen.personId));
                    if (screenId === 'NOMINEE') {
                        selectedScreenObj.documentTypes = res.data?.nomineeDocuments
                    }
                    setOnboardingDetailsState(prevStep => ({
                        ...prevStep,
                        screenDetails: updatedScreenDetails,
                        selectedScreenObj: selectedScreenObj,
                        nomineeDocuments: res.data?.nomineeDocuments
                    }));
                }
            })
    };

    const selectDocument = (screenKey, selectedPersonId) => {
        const {screenDetails} = OnboardingDetailsState;
        let updatedScreenDetails = screenDetails.map(screen => {
            if ((screen.screenKey === screenKey) && (screen.personId === selectedPersonId)) {
                return {...screen, processInitiated: true, processCompleted: false};
            } else {
                return {...screen, processInitiated: false, processCompleted: false};
            }
        });

        let selectedScreenObj = updatedScreenDetails.find((screen) => (screen?.screenKey === screenKey && screen?.personId === selectedPersonId));
        setOnboardingDetailsState((prevState) => ({
            ...prevState,
            selectedScreenObj: selectedScreenObj,
            screenDetails: updatedScreenDetails,
            responseErrors: [],
            totalErrors: 0,
            selectedTab: {
                label: 'Documents',
                value: 'DOCUMENTS'
            },
            isShowLoader: false
        }));
    };

    const handleClickNext = (isRefreshApplicantUI, personId) => {
        //    todo when click on save
        const {applicantDetails} = OnboardingDetailsState;

        let updatedApplicantDetails = [];
        setOnboardingDetailsState((prevState) => ({
            ...prevState,
            isShowLoader: true
        }));

        const {profile} = props;
        if(isRefreshApplicantUI && !isEmpty(personId)){
            getPersonData(personId, profile.advisoryId)
                .then(response => {
                    let selectedPersonMapData = mappedPersonData(response?.data);
                    applicantDetails.map(applicant => {
                        if (applicant.personId === personId) {
                            updatedApplicantDetails.push(
                                {
                                    personId : applicant?.personId,
                                    name: selectedPersonMapData?.name,    // Update the fields you want to change
                                    email: selectedPersonMapData?.email,
                                    phone: selectedPersonMapData?.phone
                                }
                            )
                        }else{
                            updatedApplicantDetails.push(applicant)
                        }
                        setOnboardingDetailsState((prevState) => ({
                            ...prevState,
                            isShowLoader: false,
                            applicantDetails: updatedApplicantDetails
                        }));
                        store.dispatch(showToastMessage('success', 'Changes are saved.'))
                    });

                });
        }else{
            setTimeout(() => {
                setOnboardingDetailsState((prevState) => ({
                    ...prevState,
                    isShowLoader: false
                }));
                store.dispatch(showToastMessage('success', 'Changes are saved.'))
            }, 500)
        }
    };

    const changePersonTab = (applicantName) => {
        setOnboardingDetailsState((prevState) => ({
            ...prevState,
            selectedScreenObj: getSelectProfileObjByPerson('', applicantName, 'APPLICANT')
        }));
    };

    const getFilledApplicantsDetails = (applicantDetails) => {
        let filledApplicantsDetails = [];
        applicantDetails.map((applicant, index) => {
            if(!isEmpty(applicant?.name) && !isEmpty(applicant?.email) && !isEmpty(applicant?.phone)){
                filledApplicantsDetails.push(applicant)
            }
        });
        return filledApplicantsDetails
    };

    const isShowApplicantCounts = (applicantsWithDetailsUploaded, applicantDetails) =>{
        if(isEmpty(applicantsWithDetailsUploaded)){
            return true
        }
        else if((applicantsWithDetailsUploaded.length === 1) && (applicantDetails.length === 2)){
            return true
        }
        return false;
    };

    const handleItemOpen = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleItemClose = () => {
        setAnchorEl(null);
    };

    const handleChangeOnboardingStatus = (status) =>{
        setOnboardingDetailsState((prevState) => ({
            ...prevState,
            onboardingStatus: status
        }));
    };

    const selectedOption = (value) =>{
        if(value === "REASSIGN"){
            handleOpenReassignCRMModal()
        }
        if(value === "REMOVE"){
            handleOpenDeleteOnboardingModal()
        }
    }

    const handleOpenReassignCRMModal = () => {
        setOnboardingDetailsState((prevState) => ({
            ...prevState,
            isReassignCRMModalOpen : true
        }));
    };

    const handleCloseReassignCRMModal = () => {
        setOnboardingDetailsState((prevState) => ({
            ...prevState,
            isReassignCRMModalOpen : false
        }));
    };

    const handleOpenDeleteOnboardingModal = () => {
        setOnboardingDetailsState((prevState) => ({
            ...prevState,
            isDeleteOnboardingModalOpen : true
        }));
    };

    const handleCloseDeleteOnboardingModal = () => {
        setOnboardingDetailsState((prevState) => ({
            ...prevState,
            isDeleteOnboardingModalOpen : false
        }));
    };

    return (
        <OnboardingDetailsView
            {...props}
            {...OnboardingDetailsState}
            anchorEl={anchorEl}
            handleTabChange={handleTabChange}
            getUplodedDocuments={getUplodedDocuments}
            selectDocument={selectDocument}
            handleClickNext={handleClickNext}
            changePersonTab={changePersonTab}
            getFilledApplicantsDetails={getFilledApplicantsDetails}
            isShowApplicantCounts={isShowApplicantCounts}
            handleItemClose={handleItemClose}
            handleItemOpen={handleItemOpen}
            handleChangeOnboardingStatus={handleChangeOnboardingStatus}
            selectedOption={selectedOption}
            handleCloseReassignCRMModal={handleCloseReassignCRMModal}
            handleCloseDeleteOnboardingModal={handleCloseDeleteOnboardingModal}
            getApplicationId={getApplicationId}
            isManagementUser={isManagementUser}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    isXLView: state.appState.deviceInfo.isXLView,
    isXSView: state.appState.deviceInfo.isXSView,
    accountId: state.onboardingFlowState.accountId,
    accountType: state.onboardingFlowState.accountType,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(OnboardingDetails);