import React, {useState} from 'react';
import {connect} from "react-redux";

import {getDocAssociateIINOrECSForm} from "../../../../../OnboardingActions";
import UploadBankMandateView from "./UploadBankMandateView";

const UploadBankMandate = (props) => {

    const [uploadState, setUploadState] = useState({
        document: {
            originalFileName: '',
            uploadedFileName: '',
            filePath: '',
            fileName: '',
            id: ''
        },
        uploadFormError: '',
        responseErrors: [],
        totalErrors: 0,
        isPdfDocument: false,
        isLoaderVisible: false
    });

    const isPdf = (fileName) => {
        return fileName.includes("pdf") || fileName.includes("Pdf") || fileName.includes("PDF");
    };

    const associateDocument = (type, response) => {
        const {profile, formId, refreshData} = props;
        const {document} = uploadState;
        if(type === 'ERROR'){
            setUploadState(prevStep => ({
                ...prevStep,
                uploadFormError: response,
            }));
            return;
        }

        if (type === 'DELETE') {
            setUploadState(prevStep => ({
                ...prevStep,
                document: {
                    ...document,
                    filePath: '',
                    fileName: '',
                },
                responseErrors: [],
                totalErrors: 0,
            }));
            return;
        }

        setUploadState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true,
        }));

        const associatePayload = {
            id: formId,
            originalFileName: response?.originalFileName,
            uploadedFileName: response?.uploadedFileName,
        };

        getDocAssociateIINOrECSForm(associatePayload, profile?.advisoryId)
            .then((res) => {
                if (res.success) {
                    setUploadState(prevStep => ({
                        ...prevStep,
                        document: {
                            ...document,
                            filePath: isPdf(response?.originalFileName) ? "" : res.data?.filePath,
                            fileName: isPdf(response?.originalFileName) ? "" : res.data?.fileName,
                            id: isPdf(response?.originalFileName) ? "" : res.data?.id
                        },
                        isLoaderVisible: false,
                        responseErrors: [],
                        totalErrors: 0,
                        isPdfDocument: isPdf(response?.originalFileName) ? true : false
                    }));
                    refreshData()
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setUploadState(prevStep => ({
                        ...prevStep,
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                        document: {
                            ...document,
                            filePath: '',
                            id: ''
                        },
                        isPdfDocument: isPdf(response?.originalFileName) ? true : false
                    }));
                }
            })
    };

    return (
        <UploadBankMandateView
            {...props}
            {...uploadState}
            associateDocument={associateDocument}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(UploadBankMandate);
