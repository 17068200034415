import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import {getManagementClientCRM} from "../../../HomePageActions";
import DashboardWidget from "../../../DashboardWidget";
const ClientByCrm=(props)=> {
    const [clientCRMState, setClientCRMState] = useState({
        clientByCRM: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        const {profile} = props;
        const advisoryId = profile?.advisoryId;

        setClientCRMState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getManagementClientCRM(advisoryId).then(res => {
            if (res.success) {
                setClientCRMState(prevState => ({
                    ...prevState,
                    clientByCRM: res.data,
                    isShowLoader: false,
                }));
            } else {
                setClientCRMState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        // setCRM(item.key);
        // props.history.push('/leads')
    };


    return (
        <DashboardWidget
            {...props}
            {...clientCRMState}
            detailsObj={clientCRMState.clientByCRM}
            setFilters={setFilters}
            title={"Summary by CRM"}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(ClientByCrm);