import React from 'react';
import DLText from "../../../../../core/components/DLText/DLText";
import {isEmpty} from "../../../../../utils/validations";
import RenderKYCStatusBadge from "../../../kyc/RenderKYCStatusBadge";
import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import appTheme from "../../../../../assets/appTheme";
import KYCStatus from "../../../kyc/KYCStatus";
import moment from "moment";
import {getFullName} from "../../../../OnboardingHelper"
import store from "../../../../../store";
import {showToastMessage} from "../../../../../AppComponents/AppActions";
import {getTrimmedValue} from "../../../../../utils/helper";
import DLTooltip from "../../../../../core/components/DLTooltip/DLTooltip";

const KycView = (props) => {
    const {isShowLoader, handleOpenKycStatusModal, setEkycStatus, asOfFieldDate, showEkycBlock, generateLink, generateForm, refreshButton,
        downloadAndViewDocument, isManagementUser, isXLView} = props;

    const getStatusBadge = (status) => {
        return (
            <RenderKYCStatusBadge status={status}/>
        );

    };
    const generateFormUi = (applicantDetails) => {
        const {onboardingApplicationId} = props;
        return (
            isEmpty(applicantDetails?.ekycDetails?.eKYCLink) ?
                <div>
                    <DLButton
                        id={'generate-link'}
                        buttonSize={'sm'}
                        fontSize={'sm'}
                        sideMargin={'none'}
                        backgroundColor={"grey"}
                        styleType={"outlined"}
                        isDisabled={isManagementUser}
                        onClick={() => {
                            generateLink(applicantDetails?.personId, onboardingApplicationId)
                        }}
                        label={'Generate Link'}/>
                </div>
                :
                <div className='d-flex gap-3 justify-content-between'>
                    <div className='d-flex w-100 align-items-end pb-1'>
                        <div className='col-3 p-0 cursor'
                             style={{
                                 color: appTheme.linkStyle.color,
                                 textDecoration: "underline",
                                 minWidth: 69
                             }}
                             onClick={() => window.open(applicantDetails?.ekycDetails?.eKYCLink, '_blank')}>
                            e-kyc link
                        </div>
                        <div>
                            <i className="fa-regular fa-copy cursor default-icon ps-3"
                               onClick={() => {
                                   navigator.clipboard.writeText(applicantDetails?.ekycDetails?.eKYCLink)
                                       .then(store.dispatch(showToastMessage('success', 'Copied!')))
                               }}
                            />
                        </div>
                    </div>
                    <div className='p-0' style={{minWidth: 218}}>
                        <div className='cursor'>
                            <DLButton
                                id={'create-lead-cancelBtn'}
                                buttonSize={'sm'}
                                fontSize={'sm'}
                                sideMargin={'none'}
                                backgroundColor={"grey"}
                                styleType={"outlined"}
                                isDisabled={isManagementUser}
                                onClick={() => {
                                    generateLink(applicantDetails?.personId, onboardingApplicationId)
                                }}
                                label={'Regenarate e-KYC Link'}/>
                        </div>
                    </div>
                </div>
        )
    };

    const generateLinkUi = (applicantDetails) => {
        return (
            isEmpty(applicantDetails?.formDetails?.filePath) ?
                <div className='d-flex pt-1 align-items-center justify-content-between'>
                    <div className='flex-4'>
                        <DLText id={'Kyc-form'}
                                fontColor={'grayDark'}
                                fontSize={'xs'}
                                text={'KYC Form'}/>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontWeight={"semi-bold"}
                                text={'Not Generated'}/>
                    </div>

                    <div className='flex-1 align-items-center d-flex'>
                        <DLButton
                            id={'create-lead-cancelBtn'}
                            buttonSize={'sm'}
                            fontSize={'sm'}
                            sideMargin={'none'}
                            backgroundColor={"grey"}
                            isDisabled={isManagementUser}
                            styleType={"outlined"}
                            onClick={() => {
                                generateForm()
                            }}
                            label={'Generate'}/>
                    </div>

                </div>
                :
                <div className='d-flex align-items-end gap-3 pt-1'>
                    <div className='flex-4'>
                        <DLText id={'Kyc-form'}
                                fontColor={'grayDark'}
                                fontSize={'xs'}
                                text={'KYC Form'}/>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontWeight={"semi-bold"}
                                isClickable={!isEmpty(isEmpty(applicantDetails?.formDetails?.filePath))}
                                onClick={() => {
                                    downloadAndViewDocument('View', applicantDetails?.formDetails?.filePath, applicantDetails?.formDetails?.fileName)
                                }}
                                marginBottom={"none"}
                                fontColor={isEmpty(applicantDetails?.formDetails?.filePath) ? "black" : "darkBlue"}
                                text={getTrimmedValue(applicantDetails?.formDetails?.fileName, 35)}/>

                    </div>
                    <div className='d-flex align-items-end gap-3 flex-1'>
                        <div>
                            <i className={"fa-solid fa-download " + (isEmpty(applicantDetails?.formDetails?.filePath) ? ' disable-icon cursor' : ' default-icon cursor')}
                               onClick={() => {
                                   if(!isEmpty(applicantDetails?.formDetails?.filePath)){
                                       downloadAndViewDocument('Download', applicantDetails?.formDetails?.filePath, applicantDetails?.formDetails?.fileName)
                                   }
                            }}/>
                        </div>
                        <div>
                            <DLTooltip tooltipPlacement={'right'}
                                       tooltipLabel={
                                           <i className={isManagementUser ? "fa-solid fa-rotate disable-icon" : "fa-solid fa-rotate cursor default-icon"}
                                              onClick={() => {
                                                  if(!isManagementUser){
                                                      generateForm()
                                                  }
                                              }}/>
                                       }
                                       id={''}
                                       customUi={true}
                                       tooltipContent={<DLText id={''}
                                                               fontSize={'xs'}
                                                               text={'Regenerate'}
                                                               marginBottom={'none'}/>}
                            />
                        </div>
                    </div>
                </div>)
    };

    const renderEKYCUi = (applicantDetails) => {
        const {isEKyc} = applicantDetails;
        return (
            isEKyc ?
                <div>{generateFormUi(applicantDetails)}</div>
                :
                <div>{generateLinkUi(applicantDetails)}</div>
        )
    };

    const generateLinkMobileUi = (applicantDetails) => {
        const {onboardingApplicationId} = props;
        return (
            isEmpty(applicantDetails?.ekycDetails?.eKYCLink) ?
                <div>
                    <div className='py-2 cursor '
                         onClick={() => {
                             generateLink(applicantDetails?.personId, onboardingApplicationId)
                         }}>
                        <div style={{color: appTheme.linkStyle.color}}>
                            <u> Generate Link </u>
                        </div>
                    </div>
                </div>
                :
                <div className='  p-1'>
                    <div className='row border-bottom'>
                        <div className='col-6 ps-2 p-0 cursor' style={{color: appTheme.linkStyle.color}}
                             onClick={() => window.open(applicantDetails?.ekycDetails?.eKYCLink, '_blank')}>
                            e-kyc link
                        </div>
                        <div className='col-6 p-0 d-flex justify-content-end'
                             onClick={() => {
                                 navigator.clipboard.writeText(applicantDetails?.ekycDetails?.eKYCLink)
                                     .then(store.dispatch(showToastMessage('success', 'Copied!')))
                             }}>
                            <i className="fa-regular fa-copy "/>
                        </div>
                    </div>

                    <div className='row ps-2 pe-0 mt-3 d-flex cursor'>
                        <div className='p-0 cursor' style={{color: appTheme.linkStyle.color}}
                             onClick={() => generateLink(applicantDetails?.personId, onboardingApplicationId)}>
                            Regenarate e-KYC Link
                        </div>
                    </div>
                </div>
        )
    };

    const generateFormMobileUi = (applicantDetails) => {
        return (
            isEmpty(applicantDetails?.formDetails?.filePath) ?
                <div className='row'>
                    <div className='col-6'>
                        <DLText id={'Kyc-form'}
                                fontColor={'grayDark'}
                                fontSize={'xs'}
                                text={'KYC Form'}/>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontWeight={"semi-bold"}
                                text={'Not Generated'}/>
                    </div>

                    <div className='col-6 align-items-center justify-content-end d-flex'>
                        <div className='ps-2   mt-3 d-flex cursor'>
                            <div className='p-0 cursor' style={{color: appTheme.linkStyle.color}}
                                 onClick={() => generateForm()}>
                                <u>Generate</u>
                            </div>
                        </div>
                    </div>

                </div>
                :
                <div>
                    <div className='row p-0 mb-3'>
                        <DLText id={'Kyc-form'}
                                fontColor={'grayDark'}
                                fontSize={'xs'}
                                text={'KYC Form'}/>
                        <DLText id={'kyc-Summary'}
                                fontSize={'sm'}
                                fontWeight={"semi-bold"}
                                isClickable={!isEmpty(isEmpty(applicantDetails?.formDetails?.filePath))}
                                onClick={() => {
                                    downloadAndViewDocument('View', applicantDetails?.formDetails?.filePath, applicantDetails?.formDetails?.fileName)
                                }}
                                fontColor={isEmpty(applicantDetails?.formDetails?.filePath) ? "black" : "darkBlue"}
                                text={getTrimmedValue(applicantDetails?.formDetails?.fileName, 30)}/>
                    </div>

                    <div className='row p-0 mb-3 d-flex'>
                        <div className='col-4  d-flex align-items-center justify-content-center border-right cursor'>
                            <div onClick={() =>
                                downloadAndViewDocument('Download', applicantDetails?.formDetails?.filePath, applicantDetails?.formDetails?.fileName)}>
                                <i className="fa-solid fa-download"/>
                            </div>
                        </div>
                        <div className='col-4 border-right d-flex align-items-center justify-content-center'>
                            <div className='p-0 cursor' style={{color: appTheme.linkStyle.color}}
                                 onClick={() => generateForm()}>
                                <u>Regenerate</u>
                            </div>
                        </div>
                    </div>
                </div>)
    };

    const renderEKYCMobileUi = (applicantDetails) => {
        const {isEKyc} = applicantDetails;
        return (
            isEKyc ?
                <div>{generateLinkMobileUi(applicantDetails)}</div>
                :
                <div>{generateFormMobileUi(applicantDetails)}</div>
        )
    };

    const renderSwitch = (applicantDetails, isLastName) => {
        const {isMobileView,} = props;
        const {isEKyc} = applicantDetails;
        return (
            <div className={'py-3' + (isLastName ? '' : ' border-bottom')}>
                <div className={'d-flex justify-content start align-items-center pb-3'}>
                    <div className={"me-2"}>
                        <DLText id={''}
                                text={"e-KYC"}
                                fontSize={'md'}
                                fontWeight={"semi-bold"}
                                marginBottom={'none'}
                        />
                    </div>
                    <ButtonGroup style={{
                        textTransform: 'none',
                        maxHeight: '30px',
                        borderRadius: '25px',
                        backgroundColor: '#E8EEF8',

                    }}
                                 variant="outlined"
                                 className='btn-group '
                                 aria-label=" button group">
                        <Button id={''}
                                className={(isEKyc) ? " bg-white m-1 py-1" : "py-1"}
                                style={{
                                    textTransform: 'none',
                                    border: 'none',
                                    fontFamily: appTheme.default.fontFamily,
                                    fontWeight: isEKyc ? 500 : 400,
                                    color: isManagementUser ? appTheme.appColor.disable : isEKyc ? 'var(--primary)' : 'var(--gray-dark)',
                                    borderRadius: '25px',
                                    fontSize: 14,
                                    cursor: isManagementUser? 'auto': 'pointer'
                                }}
                                onClick={() => {
                                    if(!isManagementUser){
                                        setEkycStatus(true, applicantDetails)
                                    }
                                }}
                        >
                            Yes
                        </Button>
                        <Button id={'campaignReportView-byBatch'}
                                className={!isEKyc ? " bg-white m-1 py-1" : "py-1"}
                                style={{
                                    textTransform: 'none',
                                    border: 'none',
                                    fontFamily: appTheme.default.fontFamily,
                                    fontWeight: !isEKyc ? 500 : 400,
                                    color: isManagementUser ? appTheme.appColor.disable : !isEKyc ? 'var(--primary)' : 'var(--gray-dark)',
                                    borderRadius: '25px',
                                    fontSize: 14,
                                    cursor: isManagementUser? 'auto': 'pointer'
                                }}
                                onClick={() => {
                                    if(!isManagementUser){
                                        setEkycStatus(false, applicantDetails)
                                    }
                                }}
                        >
                            No
                        </Button>
                    </ButtonGroup>

                </div>

                    <div>{renderEKYCUi(applicantDetails, isLastName)}</div>
            </div>
        )
    };

    const renderKycFields = (fields, isLastRow) => {
        return (
            <div>
                <div className='w-100'>
                    <DLText id={'kyc-Summary'}
                            fontColor={'grayDark'}
                            fontSize={'xs'}
                            text={'Name'}/>
                    <DLText id={'kyc-Summary'}
                            fontWeight={"semi-bold"}
                            text={getFullName(fields?.firstName, fields?.middleName, fields?.lastName)}/>
                </div>
                {isXLView &&
                <div>
                    <div className={'row d-flex pt-3 mx-0'}>
                        <div className='col-4 p-0 m-0'>
                            <DLText id={'kyc-Summary'}
                                    fontColor={'grayDark'}
                                    fontSize={'xs'}
                                    text={'PAN'}/>
                        </div>

                        <div className='col-7 m-0 p-0 d-flex align-items-center'>
                            <div>
                                <DLText id={'kyc-Summary'}
                                        fontColor={'grayDark'}
                                        fontSize={'xs'}
                                        marginBottom={"none"}
                                        text={'KYC Status'}/>
                            </div>
                        </div>
                    </div>
                    <div className={'row d-flex pb-3 mb-1 mx-0' + ((fields?.isShowEkyc || isLastRow) ? '' :' border-bottom')}>
                        <div className='col-4 p-0 m-0'>
                            <DLText id={'kyc-Summary'}
                                    customWrapperStyle={{
                                        paddingTop : 6
                                    }}
                                    fontWeight={"semi-bold"}
                                    marginBottom={"none"}
                                    text={fields?.PANNumber}/>
                        </div>

                        <div className='col-7 m-0 p-0 d-flex align-items-start'>
                            <div>
                                {getStatusBadge(fields?.kycDetails?.kycStatus?.description)}
                                {fields?.kycDetails?.lastUpdatedDate &&
                                <div className='d-flex gap-1 ps-2 ms-1'>
                                    <DLText id={'kyc-Summary'}
                                            fontColor={'grayDark'}
                                            fontSize={'xs'}
                                            text={'as of '}/>

                                    <DLText id={'kyc-Summary'}
                                            fontColor={'grayDark'}
                                            fontSize={'xs'}
                                            text={asOfFieldDate(fields?.kycDetails?.lastUpdatedDate)}/>
                                </div>
                                }
                            </div>
                            <div className=' d-flex align-items-center ps-3'>
                                <div className='pb-1' onClick={() => {
                                    refreshButton(fields)
                                }}>
                                    <i className="fa-solid fa-rotate-right cursor"/>
                                </div>
                                <div className='ps-3 pb-1'>
                                    <div className='py-2 cursor '
                                         onClick={() => {
                                             handleOpenKycStatusModal(fields)
                                         }}>
                                        <i className="fa-regular fa-eye default-icon"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-1 m-0 p-0 d-flex align-items-start py-2 justify-content-end '>
                            <div className='m-0 cursor pb-1'
                                 onClick={() => showEkycBlock(!fields?.isShowEkyc, fields)}>
                                <div className='me-2'>
                                    <i className="fa-solid fa-angle-down"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
                {!isXLView &&
                <div>
                    <div>
                        <div className={'row d-flex pt-3 mx-0'}>
                            <div className='col-3 p-0 m-0'>
                                <DLText id={'kyc-Summary'}
                                        fontColor={'grayDark'}
                                        fontSize={'xs'}
                                        text={'PAN'}/>
                            </div>
                        </div>
                        <div className={'row d-flex mx-0' + ((fields?.isShowEkyc || isLastRow) ? '' :' border-bottom')}>
                            <div className='col-3 p-0 m-0'>
                                <DLText id={'kyc-Summary'}
                                        customWrapperStyle={{
                                            paddingTop : 6
                                        }}
                                        fontWeight={"semi-bold"}
                                        text={fields?.PANNumber}/>
                            </div>
                        </div>
                    </div>
                <div>
                    <div className={'row d-flex pt-3 mx-0'}>
                        <div className='col-7 m-0 p-0 d-flex align-items-center'>
                            <div>
                                <DLText id={'kyc-Summary'}
                                        fontColor={'grayDark'}
                                        fontSize={'xs'}
                                        marginBottom={"none"}
                                        text={'KYC Status'}/>
                            </div>
                        </div>

                        <div className='col-4'/>
                    </div>
                    <div className={'row d-flex pb-3 mb-1 mx-0' + ((fields?.isShowEkyc || isLastRow) ? '' :' border-bottom')}>

                        <div className='col-7 m-0 p-0 d-flex align-items-start'>
                            <div>
                                {getStatusBadge(fields?.kycDetails?.kycStatus?.description)}
                                {fields?.kycDetails?.lastUpdatedDate &&
                                <div className='d-flex gap-1 ps-2 ms-1'>
                                    <DLText id={'kyc-Summary'}
                                            fontColor={'grayDark'}
                                            fontSize={'xs'}
                                            text={'as of '}/>

                                    <DLText id={'kyc-Summary'}
                                            fontColor={'grayDark'}
                                            fontSize={'xs'}
                                            text={asOfFieldDate(fields?.kycDetails?.lastUpdatedDate)}/>
                                </div>
                                }
                            </div>
                            <div className=' d-flex align-items-center ps-3'>
                                <div className='pb-1' onClick={() => {
                                    refreshButton(fields)
                                }}>
                                    <i className="fa-solid fa-rotate-right cursor"/>
                                </div>
                                <div className='ps-3 pb-1'>
                                    <div className='py-2 cursor '
                                         onClick={() => {
                                             handleOpenKycStatusModal(fields)
                                         }}>
                                        <i className="fa-regular fa-eye default-icon"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='col-5 m-0 p-0 d-flex align-items-start py-2 justify-content-end '>
                            <div className='m-0 cursor pb-1'
                                 onClick={() => showEkycBlock(!fields?.isShowEkyc, fields)}>
                                <div className='me-2'>
                                    <i className="fa-solid fa-angle-down"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                }
            </div>
        );
    };

    const renderDesktopUi = (data, index) => {
        const {getApplicantData, accountHoldersPAN} = props;
        const fields = getApplicantData(data);
        if (isEmpty(fields?.PANNumber)) {
            return;
        }
        return (
            <div className="my-3" key={fields?.PANNumber}>
                {renderKycFields(fields, (accountHoldersPAN?.length === index))}
                {fields?.isShowEkyc && renderSwitch(fields, (accountHoldersPAN?.length === index))}
            </div>)
    };

    const renderMobileUi = (data, index) => {
        const {getApplicantData, accountHoldersPAN} = props;
        const fields = getApplicantData(data);
        if (isEmpty(fields?.PANNumber)) {
            return;
        }
        return (
            <div className={(accountHoldersPAN?.length === index) ? ' mt-2' : 'pb-3 border-bottom mt-3'}
                 key={fields?.PANNumber}>
                <div className='row w-100'>
                    <div className='col-12 mb-3'>
                        <DLText id={'kyc-Summary'}
                                fontColor={'grayDark'}
                                fontSize={'xs'}
                                text={'Name'}/>
                        <DLText id={'kyc-Summary'}
                                fontWeight={"semi-bold"}
                                text={getFullName(fields?.firstName, fields?.middleName, fields?.lastName)}/>
                    </div>
                </div>

                <div className='mb-3'>
                    <DLText id={'kyc-Summary'}
                            fontColor={'grayDark'}
                            fontSize={'xs'}
                            text={'PAN'}/>
                    <DLText id={'kyc-Summary'}
                            fontWeight={"semi-bold"}
                            text={fields?.PANNumber}/>
                </div>
                <div className='row w-100 mx-0'>
                    <div className='ps-0'>
                        <DLText id={'kyc-Summary'}
                                fontColor={'grayDark'}
                                fontSize={'xs'}
                                text={'Status'}/>
                        <div className='d-flex align-items-center w-100'>
                            {getStatusBadge(fields?.kycDetails?.kycStatus?.description)}
                            <div className='d-flex w-100'>
                                <div className='d-flex justify-content-center align-items-center'>
                                    <div className=''>
                                        <div className='ps-2 cursor d-flex align-items-center '
                                             onClick={() => {
                                                 refreshButton(fields)
                                             }}>
                                            <i className="fa-solid fa-rotate-right cursor"/>
                                        </div>
                                    </div>

                                    <div className=''>
                                        <div className='ps-2 cursor d-flex align-items-center '
                                             onClick={() => handleOpenKycStatusModal(fields)}>
                                            <i className="fa-regular fa-eye default-icon"/>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex-grow-1 d-flex justify-content-end'>
                                    <div className='ps-2 py-2 cursor d-flex align-items-center '
                                         onClick={() => showEkycBlock(!fields?.isShowEkyc, fields)}>
                                        <div className='ps-1'>
                                            <i className="fa-solid fa-angle-down fa-lg"/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-3">
                    {isEmpty(fields?.kycDetails?.lastUpdatedDate) ? ''
                        :
                        <span
                            className='align-self-center ps-1'> as of
                                <span className='px-1'>
                                {

                                    moment(fields?.kycDetails.lastUpdatedDate).format('DD-MM-YYYY')
                                }
                                </span>
                            </span>
                    }
                </div>

                {fields?.isShowEkyc &&
                <div className="row border border-1 border-rounded m-1 mt-3 "
                     style={{backgroundColor: appTheme.appColor.lightBlue}}>
                    {renderSwitch(fields)}
                </div>}
            </div>
        )
    };

    const renderUI = () => {
        const {isMobileView, accountHoldersPAN, generatedFormDetails} = props;
        return (
            <div className={isMobileView ? '' : 'border border-rounded bg-white'}>
                <div className={isMobileView ? 'mb-1':' p-3 mb-1'}>
                    <DLText id={'Kyc-Accordian heading'}
                            type={'normal'}
                            fontSize={"lg"}
                            fontWeight={"semi-bold"}
                            marginBottom={"none"}
                            text={'KYC'}
                    />
                </div>
                <div className={!isMobileView ? 'border-top w-100' : ''}>
                    <div style={{
                        maxHeight: !isMobileView ? 'calc(100dvh - 281px)' : 'auto',
                        minHeight:  !isMobileView ? 'calc(100dvh - 281px)' :'auto',
                        overflow: "auto"}}
                         className={isMobileView ? 'w-100':'px-3'}>
                        {accountHoldersPAN.map((applicantDetails, index) => (
                            renderDesktopUi(applicantDetails, index+1)
                        ))}
                    </div>
                </div>
            </div>)
    };

    const renderKycStatusModal = () => {
        const {isMobileView, handleCloseKycStatusModal, isKycStatusModelOpen} = props;

        if (isKycStatusModelOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseKycStatusModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <KYCStatus {...props} isOverlay={true} isFromIIN={true}/>
                    </div>
                </div>
            } else {
                return (
                    <KYCStatus {...props} isFromIIN={true}/>
                )
            }
        }
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    return (
        <div>
            {renderLoader()}
            {renderKycStatusModal()}
            {renderUI()}
        </div>

    );
};

export default KycView;