import React from 'react';
import {isEmpty} from "../../../utils/helperCommon";
import DLText from "../DLText/DLText";
import './DocumentUploadStatus.css';
import iconPan from "../../../assets/img/iconPan.png";
import iconPanSelected from "../../../assets/img/iconPanSelected.png";
import iconAadhar from "../../../assets/img/iconAadhar.png";
import iconAadharSelected from "../../../assets/img/iconAadharSelected.png";
import iconPhoto from "../../../assets/img/iconPhoto.png";
import iconPhotoSelected from "../../../assets/img/iconPhotoSelected.png";
import iconBankProof from "../../../assets/img/iconBankProof.png";
import iconBankProofSelected from "../../../assets/img/iconBankProofSelected.png";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

const DocumentUploadStatusView = (props) => {
    const {documents, isMobileView, applicantDocuments, isMultipleApplicants,isShowDocumentList,selectedScreenObj,
        isFromOnboardingFlow, selectDocument} = props;

    const renderMobileUI = () => {
        let applicantDocument = selectedScreenObj?.applicantName + ': ' +selectedScreenObj?.screenName;
        return (
            <div className={(isFromOnboardingFlow ? 'mt-5 ' : 'mt-3 ') + (isShowDocumentList ? 'border-top' : '')}
                 style={{maxWidth: 300}}>
                <form className={''} autoComplete="off">
                    <FormControl style={{width :' 100%'}}>
                        <Select
                            id={'doc-status-dropdown'}
                            value={applicantDocument}
                            style={{height: "36px"}}
                            disabled={isFromOnboardingFlow}
                            renderValue={() => applicantDocument}>
                            {renderDocumentWithStatusUI()}
                        </Select>
                    </FormControl>
                </form>
            </div>
        )

    };

    const getDocumentImageURL = (doc, isSelected) => {
        let imageSrc = '';
        switch (doc) {
            case 'PAN_CARD':
                if(isSelected){
                    imageSrc = iconPanSelected;
                }else{
                    imageSrc = iconPan;
                }
                break;
            case 'AADHAR_CARD':
                if(isSelected){
                    imageSrc = iconAadharSelected;
                }else{
                    imageSrc = iconAadhar;
                }
                break;
            case 'PHOTOGRAPH':
                if(isSelected){
                    imageSrc = iconPhotoSelected;
                }else{
                    imageSrc = iconPhoto;
                }
                break;
            case 'BANK_PROOF':
                if(isSelected){
                    imageSrc = iconBankProofSelected;
                }else{
                    imageSrc = iconBankProof;
                }
                break;
            default:
        }

        return imageSrc;

    };

    const renderHeading = (block) => {
        let isStepInProgress = (block.status === 'IN_PROGRESS');
        if (isEmpty(block)) {
            return;
        }
        let docImageSrc = getDocumentImageURL(block?.key, isStepInProgress);
        return (
            <div className="d-flex gap-2">
                <img alt=''
                     src={docImageSrc}
                     height={18}
                     width={18}/>
                <DLText id={'block-name'}
                        text={block.name}
                        marginBottom={"none"}
                        fontSize={"sm"}
                        fontWeight={isStepInProgress ? 'bold' : 'normal'}
                        fontColor={isStepInProgress ? "darkBlue" : "black"}
                />
            </div>
        );
    };

    const renderLayout = (item, index) => {

        return (
            <div key={index}
                 className={(item.status !== 'DISABLED') ? !isFromOnboardingFlow ? 'cursor' :'' : ' '}
                 onClick={() => {
                 }}>
                <div className='d-flex align-items-center'>
                    <div style={{flex: 0.8}}>
                        {renderHeading(item)}
                    </div>
                </div>
            </div>
        );
    };

    const renderDocumentWithStatusUI = () => {

        return applicantDocuments.map((item, index) => {
            let allDocs = item?.documents;
            let applicantName = item?.applicantName;

            return (
                <div key={'applicant_' + index} style={{
                    width: '100%'
                }}>
                    {isMultipleApplicants && <div className={(index ===0) ? 'px-3' : 'px-3 mt-3'}>
                        <DLText id={''}
                                text={applicantName}
                                fontWeight={"semi-bold"}
                                fontColor={"grayDark"}
                                fontSize={"md"}
                        />
                    </div>}
                    {allDocs.map((doc, index) => {
                        return (
                            <div key={'document_name_'+index}
                                 className={"px-4 py-1" + (doc?.status === "IN_PROGRESS" ? ' border' : '')}
                                 style={{
                                     backgroundColor :  (doc?.status === "IN_PROGRESS")  ? 'var(--gray-light)' : 'var(--white)'
                                 }}
                                 onClick={()=>{
                                     if(!isFromOnboardingFlow){
                                         selectDocument(doc?.key, doc?.personId)
                                     }
                                 }}
                            >
                                {renderLayout(doc, index)}
                            </div>
                        )
                    })}
                </div>
            );
        })
    };

    const renderDesktopUI = () => {
        if (isEmpty(documents)) {
            return (<div className="panel d-flex align-items-center justify-content-center"
                         style={{minHeight: 200}}>
                No Data Avaliable
            </div>)
        }
        return (
            <div style={{minWidth: "265px"}}>
                <div className='py-2 px-3'>
                    {renderDocumentWithStatusUI()}
                </div>
            </div>
        );
    };

    const renderUI = () => {
        if (isMobileView) {
            return (<div className='page-container'>
                {renderMobileUI()}
            </div>);
        }

        return renderDesktopUI()
    };

    return (
        <div style={{width: isMobileView ? 'auto' : 'w-100'}}>
            {renderUI()}
        </div>
    )

};

export default DocumentUploadStatusView;
