import React from 'react';
import Aadhar from "./aadhar/Aadhaar";
import PAN from "./PAN/PAN";
import CancelledCheque from "./cancalledCheque/CancelledCheque";
import Photograph from "./photograph/Photograph";
import DocumentUploadStatus from "../../../core/components/documentUploadStatus/DocumentUploadStatus";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLText from "../../../core/components/DLText/DLText";
import Signature from "./signature/Signature";

const DocumentUploadView = (props) => {

    const {validateUploadDocuments, documents, isMobileView, selectedApplicantName, isMultipleApplicants, isProceedWithErrors,
        selectedScreenObj, getUplodedDocuments, isFromOnboardingFlow, handleClickSaveAndClose, saveFailureErrorMessage} = props;

    const renderDocumentUploadUI = () => {
        const {subScreenKey} = props;

        switch (subScreenKey) {
            case 'PAN_CARD':
                return <PAN
                    history={props.history}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    getUplodedDocuments={getUplodedDocuments}
                    selectedScreenObj={selectedScreenObj}
                    handleClickNext={props.handleClickNext}
                    isMultipleApplicants={isMultipleApplicants}
                    handleClickSaveAndClose={handleClickSaveAndClose}
                    isProceedWithErrors={isProceedWithErrors}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                />;

            case 'AADHAR_CARD':
                return <Aadhar
                    history={props.history}
                    getUplodedDocuments={getUplodedDocuments}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    selectedScreenObj={props.selectedScreenObj}
                    handleClickNext={props.handleClickNext}
                    isMultipleApplicants={isMultipleApplicants}
                    handleClickSaveAndClose={handleClickSaveAndClose}
                    isProceedWithErrors={isProceedWithErrors}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                />;

            case 'BANK_PROOF':
                return <CancelledCheque
                    history={props.history}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    handleClickNext={props.handleClickNext}
                    selectedScreenObj={selectedScreenObj}
                    isMultipleApplicants={isMultipleApplicants}
                    handleClickSaveAndClose={handleClickSaveAndClose}
                    isProceedWithErrors={isProceedWithErrors}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                />;

            case 'PHOTOGRAPH':
                return <Photograph
                    history={props.history}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    getUplodedDocuments={getUplodedDocuments}
                    handleClickNext={props.handleClickNext}
                    selectedScreenObj={selectedScreenObj}
                    isMultipleApplicants={isMultipleApplicants}
                    handleClickSaveAndClose={handleClickSaveAndClose}
                    isProceedWithErrors={isProceedWithErrors}
                    saveFailureErrorMessage={saveFailureErrorMessage}/>;

            case 'SIGNATURE':
                return <Signature
                    history={props.history}
                    isFromOnboardingFlow={isFromOnboardingFlow}
                    getUplodedDocuments={getUplodedDocuments}
                    handleClickNext={props.handleClickNext}
                    selectedScreenObj={selectedScreenObj}
                    isMultipleApplicants={isMultipleApplicants}
                    handleClickSaveAndClose={handleClickSaveAndClose}
                    isProceedWithErrors={isProceedWithErrors}
                    saveFailureErrorMessage={saveFailureErrorMessage}
                />;
        }
    };

    const renderButtonUi = () => {

        return (
            <div className='sticky-bottom-buttons'>
                <DLButton
                    id={'cancel-button'}
                    styleType={"outlined"}
                    borderType={'square'}
                    buttonSize={'sm'}
                    fontSize={'sm'}
                    sideMargin={'none'}
                    onClick={() => {
                    }}
                    label={"Save & Close"}
                />
                <DLButton id={'delete-button'}
                          buttonSize={'sm'}
                          fontSize={'sm'}
                          sideMargin={'none'}
                          onClick={() => {
                              validateUploadDocuments()
                          }}
                          label={"Next"}/>
            </div>
        );
    };

    const renderMobileUI = () => {
        return (
            <div style={{marginBottom: !isFromOnboardingFlow ? "0px" : "68px"}}>
                <DocumentUploadStatus {...props} />
                <div className='pb-5' style={{
                    minHeight: isMobileView ? !isFromOnboardingFlow ? 'calc(100dvh - 386px)' : 'calc(100dvh - 261px)' : 'calc(100dvh - 266px)',
                    maxHeight: isMobileView ? !isFromOnboardingFlow ? 'calc(100dvh - 386px)' : 'calc(100dvh - 339px)' : 'calc(100dvh - 266px)',
                    overflowY: "auto"
                }}>
                    {renderDocumentUploadUI()}
                </div>
            </div>
        );
    };

    const renderDesktopUI = () => {
        const {isMDView} = props;
        return (
            <div style={{
                maxHeight:!isFromOnboardingFlow ? 'calc(100dvh - 200px)' : 'calc(100dvh - 186px)',
                overflowY: 'auto',
                scrollbarWidth: 'thin'
            }}>
                <div className={isMobileView ? 'pt-4' : 'd-flex'}>
                    <div className='bg-white border-right'
                         style={{flex: isMDView ? 3 : 1.5}}>
                        <DocumentUploadStatus
                            {...props}
                            documents={documents}
                            selectedApplicantName={selectedApplicantName}
                        />
                    </div>
                    <div style={{flex: isMDView ? 9 : 8}}>
                        {isMultipleApplicants && (selectedApplicantName !== 'Common') &&
                        <div className={'mt-2 pt-1 ps-5'}>
                            <DLText
                                id={''}
                                text={selectedApplicantName}
                                fontSize={'lg'}
                                marginBottom={"none"}
                                fontWeight={"bold"}
                                fontColor={"grayDark"}
                            />
                        </div>}
                        <div className='pb-3'>
                            {renderDocumentUploadUI()}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderUI = () => {
        if (isMobileView) {
            return renderMobileUI()
        }
        return renderDesktopUI()
    };

    return <div>
        {renderUI()}
    </div>
};

export default DocumentUploadView;