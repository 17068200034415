import React, {useEffect} from 'react';
import DLText from '../../../core/components/DLText/DLText';
import DLInputTextField from '../../../core/components/DLInputTextField/DLInputTextField';
import DLDatePicker from '../../../core/components/DLDatePicker/DLDatePicker';
import "./nomineeList/NomineeList.css"
import DLButton from "../../../core/components/DLButton/DLButton";
import {isEmpty} from "../../../utils/validations";
import DLLoader from "../../../core/components/DLLoader/DLLoader";
import {debounce} from "../../../core/components/DLComponentHelper";
import DLDocUploader from "../../../core/components/DLDocUploader/DLDocUploader";
import {handleFileError} from "../../../utils/FileHelper";
import DLSearchAutoComplete from "../../../core/components/DLSearchAutoComplete/DLSearchAutoComplete";
import NumberInputBasic from "./NumberInputTextField";
import DLRadioGroup from "../../../core/components/DLRadioGroup/DLRadioGroup";
import {getRandomId, searchOnWordBoundary} from "../../../utils/helper";

const MinorNomineeCreateOrUpdateView = (props) => {

    const {personalInformation, guardianAddress,guardian, nomineeInformation, guardianRelationWithNominee, errors, handleChange, profile,
        dropdownValues, isLoaderVisible, scrollToFieldId, isMobileView} = props;

    useEffect(() => {
        if (!isEmpty(scrollToFieldId)) {
            const element = document.getElementById(scrollToFieldId);
            if (!isEmpty(element)) {
                element.scrollIntoView({behavior: 'smooth', block: 'center'});
                handleScrollChangeWithDebounce()
            }
        }
        return () => {
            window.scrollTo(0, 0)
        }
    }, [scrollToFieldId]);

    const handleScrollChangeWithDebounce = debounce(() => {

        handleChange('scrollToFieldId', '')
    }, 1000);

    const renderGuardianInformationMobile = () => {
        return (
            <div>
                <div className='section-title pt-0'
                     id={'personal-information'} style={{lineHeight: '14px'}}>
                    Personal Information
                </div>
                <div>
                    <div>
                        <div id='guardian-name-id'>
                            <DLInputTextField
                                id={''}
                                label={'Name'}
                                placeholder={"Please enter full name"}
                                value={guardian.name}
                                inputType={'text'}
                                maxCharLength={62}
                                isClearable={false}
                                isRequired={true}
                                disableHelperMessageHeight={true}
                                marginBottom={'sm'}
                                hideCharLength={true}
                                onChangeValue={(value) => {
                                    handleChange('name', value, "guardian")
                                }}
                                error={errors.guardian.name !== ''}
                                helperMessage={errors.guardian.name}
                            />
                        </div>
                        <div id='guardian-PANNumber-id'>
                            <DLInputTextField
                                id={''}
                                label={'PAN Number'}
                                value={guardian.PANNumber?.toUpperCase()}
                                inputType={'text'}
                                maxCharLength={101}
                                isRequired={true}
                                isClearable={false}
                                disableHelperMessageHeight={true}
                                marginBottom={'sm'}
                                hideCharLength={true}
                                onChangeValue={(value) => {
                                    handleChange('PANNumber', value, "guardian")
                                }}
                                error={errors.guardian.PANNumber !== ''}
                                helperMessage={errors.guardian.PANNumber}
                            />
                        </div>
                    </div>
                    <div>
                        <div id='guardian-dateOfBirth-id' className="mb-3">
                            <DLDatePicker
                                id={'InboxView-FromDate'}
                                label={'Date of Birth'}
                                value={guardian.dateOfBirth}
                                maximumDate={new Date()}
                                isClearable={false}
                                isRequired={true}
                                minWidth={'100%'}
                                disableHelperMessageHeight={true}
                                marginBottom={'sm'}
                                minimumDate={'1900-12-30'}
                                onChangeValue={(value) => {
                                    handleChange('dateOfBirth', value, "guardian")
                                }}
                                error={errors.guardian.dateOfBirth !== ''}
                                helperMessage={errors.guardian.dateOfBirth}

                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderGuardianNominationInformationMobile = () => {
        return (
            <div>
                <div className='section-title'
                     id={'personal-information'}>
                    Nomination Information
                </div>
                <div>
                    <div className="mb-3">
                        <div>
                            <div id='guardianRelationWithNominee-relation-id' style={{paddingLeft : "10px"}}>
                                <div>
                                    {
                                        dropdownValues.guardianRelations.map((item)=>{
                                            return(
                                                <div key={getRandomId()}>
                                                    <DLRadioGroup
                                                        id={'hours-radioBtn'}
                                                        options={[
                                                            {
                                                                label: item.label,
                                                                value: item.value,
                                                            }]}
                                                        label={''}
                                                        value={guardianRelationWithNominee.relation}
                                                        type={'inline'}
                                                        labelFontSize={'sm'}
                                                        labelFontColor={'black'}
                                                        onChangeValue={(value) => {
                                                            handleChange('relation', value, "guardianRelationWithNominee")
                                                        }}
                                                        disableHelperMessageHeight={true}
                                                        disableDescriptionMessageHeight={true}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                {(errors.guardianRelationWithNominee.relation !== '') &&
                                <DLText text={errors.guardianRelationWithNominee.relation}
                                        id={''}
                                        fontSize={'xs'}
                                        fontColor={"danger"}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderGuardiansAddressMobile = () => {
        return (
            <div>
                <div className='section-title'
                     id={'address'}>
                    Address
                </div>
                <div>
                    <div id='guardianAddress-addressLine1-id'>
                        <DLInputTextField
                            id={''}
                            label={'Address Line 1'}
                            value={guardianAddress.addressLine1}
                            inputType={'text'}
                            maxCharLength={50}
                            isMulti={true}
                            isClearable={false}
                            isRequired={true}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            hideCharLength={true}
                            onChangeValue={(value) => {
                                handleChange('addressLine1', value, 'guardianAddress')
                            }}
                            onBlur={() => {
                            }}
                            error={errors.guardianAddress.addressLine1 !== ''}
                            helperMessage={errors.guardianAddress.addressLine1}
                        />
                    </div>
                    <div id='guardianAddress-addressLine2-id'>
                        <DLInputTextField
                            id={''}
                            label={'Address Line 2 (Optional)'}
                            value={guardianAddress.addressLine2}
                            inputType={'text'}
                            maxCharLength={50}
                            isMulti={true}
                            isClearable={false}
                            isRequired={true}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            hideCharLength={true}
                            onChangeValue={(value) => {
                                handleChange('addressLine2', value, 'guardianAddress')
                            }}
                            onBlur={() => {
                            }}
                            error={errors.guardianAddress.addressLine2 !== ''}
                            helperMessage={errors.guardianAddress.addressLine2}
                        />
                    </div>
                    <div id='guardianAddress-addressLine3-id'>
                        <DLInputTextField
                            id={''}
                            label={'Address Line 3 (Optional)'}
                            value={guardianAddress.addressLine3}
                            inputType={'text'}
                            maxCharLength={50}
                            isClearable={false}
                            isRequired={true}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            hideCharLength={true}
                            onChangeValue={(value) => {
                                handleChange('addressLine3', value, 'guardianAddress')
                            }}
                            onBlur={() => {
                            }}
                            error={errors.guardianAddress.addressLine3 !== ''}
                            helperMessage={errors.guardianAddress.addressLine3}
                        />
                    </div>
                    <div>
                        <div>
                            <div id='guardianAddress-city-id'>
                                <DLInputTextField
                                    id={''}
                                    label={'City'}
                                    value={guardianAddress.city}
                                    inputType={'text'}
                                    maxCharLength={30}
                                    isClearable={false}
                                    isRequired={true}
                                    disableHelperMessageHeight={true}
                                    marginBottom={'sm'}
                                    hideCharLength={true}
                                    onChangeValue={(value) => {
                                        handleChange('city', value, 'guardianAddress')
                                    }}
                                    onBlur={() => {
                                    }}
                                    error={errors.guardianAddress.city !== ''}
                                    helperMessage={errors.guardianAddress.city}
                                />
                            </div>
                            <div id='guardianAddress-zipCode-id'>
                                <DLInputTextField
                                    id={''}
                                    label={'Pincode'}
                                    value={guardianAddress.zipCode}
                                    inputType={"number"}
                                    maxCharLength={6}
                                    isClearable={false}
                                    isRequired={true}
                                    disableHelperMessageHeight={true}
                                    marginBottom={'sm'}
                                    hideCharLength={true}
                                    onChangeValue={(value) => {
                                        handleChange('zipCode', value, 'guardianAddress')
                                    }}
                                    onBlur={() => {
                                    }}
                                    error={errors.guardianAddress.zipCode !== ''}
                                    helperMessage={errors.guardianAddress.zipCode}
                                />

                            </div>
                        </div>

                        <div>
                            <div id='guardianAddress-stateCode-id'>
                                <div className='w-100'>
                                    <DLSearchAutoComplete
                                        id={'stateCode-dropDown'}
                                        label={'State'}
                                        loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.states)}
                                        value={guardianAddress.stateCode}
                                        onSelect={(value) => handleChange('stateCode', value, 'guardianAddress')}
                                        isClearable={false}
                                        isSearchable={true}
                                        dropDownPlacement={"top"}
                                        error={errors.guardianAddress.stateCode !== ''}
                                        helperMessage={errors.guardianAddress.stateCode}
                                        itemNotFoundText={"No options found'"}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'sm'}
                                        isMulti={false}
                                        defaultOptions={dropdownValues?.states}
                                        noOptionsMessage={"No options found'"}
                                        isRequired={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderGuardianInformation = () => {
        return (
            <div>
                <div className='section-title pt-0'
                     id={'personal-information'} style={{lineHeight: '14px'}}>
                    Personal Information
                </div>
                <div className='panel'>
                    <div className='row'>
                        <div id='guardian-name-id' className="col-6">
                            <DLInputTextField
                                id={''}
                                label={'Name'}
                                placeholder={"Please enter full name"}
                                value={guardian.name}
                                inputType={'text'}
                                maxCharLength={62}
                                isClearable={false}
                                isRequired={true}
                                disableHelperMessageHeight={true}
                                marginBottom={'sm'}
                                hideCharLength={true}
                                onChangeValue={(value) => {
                                    handleChange('name', value, "guardian")
                                }}
                                error={errors.guardian.name !== ''}
                                helperMessage={errors.guardian.name}
                            />
                        </div>
                        <div id='guardian-PANNumber-id' className="col-6">
                            <DLInputTextField
                                id={''}
                                label={'PAN Number'}
                                value={guardian.PANNumber?.toUpperCase()}
                                inputType={'text'}
                                maxCharLength={101}
                                isRequired={true}
                                isClearable={false}
                                disableHelperMessageHeight={true}
                                marginBottom={'sm'}
                                hideCharLength={true}
                                onChangeValue={(value) => {
                                    handleChange('PANNumber', value, "guardian")
                                }}
                                error={errors.guardian.PANNumber !== ''}
                                helperMessage={errors.guardian.PANNumber}
                            />
                        </div>
                    </div>
                    <div className='row'>
                        <div id='guardian-dateOfBirth-id' className="col-6 mb-3">
                            <DLDatePicker
                                id={'InboxView-FromDate'}
                                label={'Date of Birth'}
                                value={guardian.dateOfBirth}
                                maximumDate={new Date()}
                                isClearable={false}
                                isRequired={true}
                                minWidth={'100%'}
                                disableHelperMessageHeight={true}
                                marginBottom={'sm'}
                                minimumDate={'1900-12-30'}
                                onChangeValue={(value) => {
                                    handleChange('dateOfBirth', value, "guardian")
                                }}
                                error={errors.guardian.dateOfBirth !== ''}
                                helperMessage={errors.guardian.dateOfBirth}

                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderGuardianNominationInformation = () => {
        return (
            <div className='mt-2'>
                <div className='section-title'
                     id={'personal-information'}>
                    Nomination Information
                </div>
               <div className='panel'>
                   <div className="mb-3">
                       <div className='row'>
                           <div id='guardianRelationWithNominee-relation-id' className='col-9 ms-2'>
                               <div className={'d-flex'}>
                                   {
                                       dropdownValues.guardianRelations.map((item)=>{
                                           return(
                                               <div key={getRandomId()}>
                                                   <DLRadioGroup
                                                       id={'hours-radioBtn'}
                                                       options={[
                                                           {
                                                               label: item.label,
                                                               value: item.value,
                                                           }]}
                                                       label={''}
                                                       value={guardianRelationWithNominee.relation}
                                                       type={'inline'}
                                                       labelFontSize={'sm'}
                                                       labelFontColor={'black'}
                                                       onChangeValue={(value) => {
                                                           handleChange('relation', value, "guardianRelationWithNominee")
                                                       }}
                                                       disableHelperMessageHeight={true}
                                                       disableDescriptionMessageHeight={true}
                                                   />
                                               </div>
                                           )
                                       })
                                   }
                               </div>
                               {(errors.guardianRelationWithNominee.relation !== '') &&
                               <DLText text={errors.guardianRelationWithNominee.relation}
                                       id={''}
                                       fontSize={'xs'}
                                       fontColor={"danger"}
                               />}
                           </div>
                       </div>
                   </div>
               </div>
            </div>
        );
    };

    const renderGuardiansAddress = () => {
        return (
            <div className='mt-2'>
                <div className='section-title'
                     id={'address'}>
                    Address
                </div>
                <div className="panel">
                    <div id='guardianAddress-addressLine1-id'>
                        <DLInputTextField
                            id={''}
                            label={'Address Line 1'}
                            value={guardianAddress.addressLine1}
                            inputType={'text'}
                            maxCharLength={50}
                            isMulti={true}
                            isClearable={false}
                            isRequired={true}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            hideCharLength={true}
                            onChangeValue={(value) => {
                                handleChange('addressLine1', value, 'guardianAddress')
                            }}
                            onBlur={() => {
                            }}
                            error={errors.guardianAddress.addressLine1 !== ''}
                            helperMessage={errors.guardianAddress.addressLine1}
                        />
                    </div>
                    <div id='guardianAddress-addressLine2-id'>
                        <DLInputTextField
                            id={''}
                            label={'Address Line 2 (Optional)'}
                            value={guardianAddress.addressLine2}
                            inputType={'text'}
                            maxCharLength={50}
                            isMulti={true}
                            isClearable={false}
                            isRequired={true}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            hideCharLength={true}
                            onChangeValue={(value) => {
                                handleChange('addressLine2', value, 'guardianAddress')
                            }}
                            onBlur={() => {
                            }}
                            error={errors.guardianAddress.addressLine2 !== ''}
                            helperMessage={errors.guardianAddress.addressLine2}
                        />
                    </div>
                    <div id='guardianAddress-addressLine3-id'>
                        <DLInputTextField
                            id={''}
                            label={'Address Line 3 (Optional)'}
                            value={guardianAddress.addressLine3}
                            inputType={'text'}
                            maxCharLength={50}
                            isClearable={false}
                            isRequired={true}
                            disableHelperMessageHeight={true}
                            marginBottom={'sm'}
                            hideCharLength={true}
                            onChangeValue={(value) => {
                                handleChange('addressLine3', value, 'guardianAddress')
                            }}
                            onBlur={() => {
                            }}
                            error={errors.guardianAddress.addressLine3 !== ''}
                            helperMessage={errors.guardianAddress.addressLine3}
                        />
                    </div>
                    <div>
                        <div className="row">
                            <div id='guardianAddress-city-id' className="col-6">
                                <DLInputTextField
                                    id={''}
                                    label={'City'}
                                    value={guardianAddress.city}
                                    inputType={'text'}
                                    maxCharLength={30}
                                    isClearable={false}
                                    isRequired={true}
                                    disableHelperMessageHeight={true}
                                    marginBottom={'sm'}
                                    hideCharLength={true}
                                    onChangeValue={(value) => {
                                        handleChange('city', value, 'guardianAddress')
                                    }}
                                    onBlur={() => {
                                    }}
                                    error={errors.guardianAddress.city !== ''}
                                    helperMessage={errors.guardianAddress.city}
                                />
                            </div>
                            <div id='guardianAddress-zipCode-id' className="col-6">
                                <DLInputTextField
                                    id={''}
                                    label={'Pincode'}
                                    value={guardianAddress.zipCode}
                                    inputType={"number"}
                                    maxCharLength={6}
                                    isClearable={false}
                                    isRequired={true}
                                    disableHelperMessageHeight={true}
                                    marginBottom={'sm'}
                                    hideCharLength={true}
                                    onChangeValue={(value) => {
                                        handleChange('zipCode', value, 'guardianAddress')
                                    }}
                                    onBlur={() => {
                                    }}
                                    error={errors.guardianAddress.zipCode !== ''}
                                    helperMessage={errors.guardianAddress.zipCode}
                                />

                            </div>
                        </div>

                        <div className={"row"}>
                            <div id='guardianAddress-stateCode-id' className="col-6">
                                <div className='w-100'>
                                    <DLSearchAutoComplete
                                        id={'stateCode-dropDown'}
                                        label={'State'}
                                        loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.states)}
                                        value={guardianAddress.stateCode}
                                        onSelect={(value) => handleChange('stateCode', value, 'guardianAddress')}
                                        isClearable={false}
                                        isSearchable={true}
                                        dropDownPlacement={"top"}
                                        error={errors.guardianAddress.stateCode !== ''}
                                        helperMessage={errors.guardianAddress.stateCode}
                                        itemNotFoundText={"No options found'"}
                                        disableHelperMessageHeight={true}
                                        marginBottom={'sm'}
                                        isMulti={false}
                                        defaultOptions={dropdownValues?.states}
                                        noOptionsMessage={"No options found'"}
                                        isRequired={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderPersonalInformationMobile = () => {

        return (
            <div>
                <div className='section-title pt-0'
                     id={'Personal-information'}
                     style={{lineHeight: '14px'}}>
                    Personal Information
                </div>
                <div>
                    <div className=''>
                        <div>
                            <div id='personalInformation-name-id'>
                                <DLInputTextField
                                    id={''}
                                    label={'Name'}
                                    placeholder={"Please enter full name"}
                                    value={personalInformation.name}
                                    inputType={'text'}
                                    maxCharLength={62}
                                    isClearable={false}
                                    isRequired={true}
                                    disableHelperMessageHeight={true}
                                    marginBottom={'sm'}
                                    hideCharLength={true}
                                    onChangeValue={(value) => {
                                        handleChange('name', value, "personalInformation")
                                    }}
                                    error={errors.personalInformation.name !== ''}
                                    helperMessage={errors.personalInformation.name}
                                />
                            </div>
                            <div id='personalInformation-dateOfBirth-id' className={'mb-3'}>
                                <DLDatePicker
                                    id={'InboxView-FromDate'}
                                    label={'Date of Birth'}
                                    value={personalInformation.dateOfBirth}
                                    maximumDate={new Date()}
                                    isClearable={false}
                                    isRequired={true}
                                    minWidth={'100%'}
                                    disableHelperMessageHeight={true}
                                    marginBottom={'sm'}
                                    minimumDate={'1900-12-30'}
                                    onChangeValue={(value) => {
                                        handleChange('dateOfBirth', value, "personalInformation")
                                    }}
                                    error={errors.personalInformation.dateOfBirth !== ''}
                                    helperMessage={errors.personalInformation.dateOfBirth}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderNominationInformationMobile = () => {
        return (
            <div>
                <div className='section-title'
                     id={'Nomination-information'}>
                    Nomination Information
                </div>
                <div>
                    <div>
                        <div id='nomineeInformation-relation-id'>
                            <DLSearchAutoComplete
                                id={'relation-dropDown'}
                                label={'Relation'}
                                loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.relations)}
                                value={nomineeInformation.relation}
                                onSelect={(value) =>  handleChange('relation', value, "nomineeInformation")}
                                isClearable={false}
                                isSearchable={true}
                                error={errors.nomineeInformation.relation !== ''}
                                helperMessage={errors.nomineeInformation.relation}
                                itemNotFoundText={"No options found'"}
                                disableHelperMessageHeight={true}
                                marginBottom={'sm'}
                                isMulti={false}
                                defaultOptions={dropdownValues?.relations}
                                noOptionsMessage={"No options found'"}
                                isRequired={true}
                            />
                        </div>
                        {/*<div id='personalInformation-percentageAllocation-id' className='col-6'>*/}
                        {/*    <DLInputTextField*/}
                        {/*        id={''}*/}
                        {/*        label={'Percentage Allocation'}*/}
                        {/*        value={percentageAllocation}*/}
                        {/*        inputType={'number'}*/}
                        {/*        maxCharLength={3}*/}
                        {/*        isClearable={true}*/}
                        {/*        disableHelperMessageHeight={true}*/}
                        {/*        marginBottom={'sm'}*/}
                        {/*        isRequired={true}*/}
                        {/*        hideCharLength={true}*/}
                        {/*        onChangeValue={(value) => {*/}
                        {/*            handleChange('percentageAllocation', value, "personalInformation")*/}
                        {/*        }}*/}
                        {/*        error={errors.personalInformation.percentageAllocation !== ''}*/}
                        {/*        helperMessage={errors.personalInformation.percentageAllocation}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div id='nomineeInformation-percentageAllocation-id' className='mb-3'>
                            <div style={{marginBottom : '2px'}}>
                                <DLText id={''}
                                        text={'Percentage Allocation'}
                                        fontSize={'xs'}
                                        marginBottom={"none"}
                                        fontColor={errors.nomineeInformation.percentageAllocation !== '' ? 'danger' : "grayDark"}
                                />
                            </div>

                            <NumberInputBasic
                                onChange={(value)=>{handleChange('percentageAllocation', value, "nomineeInformation")}}
                                value={nomineeInformation.percentageAllocation}
                            />

                            {(errors.nomineeInformation.percentageAllocation !== '') &&
                            <DLText text={errors.nomineeInformation.percentageAllocation}
                                    id={''}
                                    fontSize={'xs'}
                                    fontColor={"danger"}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderPersonalInformation = () => {

        return (
            <div>
                <div className='section-title pt-0'
                     id={'Personal-information'}
                     style={{lineHeight: '14px'}}>
                    Personal Information
                </div>
                <div className='panel'>
                    <div className=''>
                        <div className='row'>
                            <div id='personalInformation-name-id' className="col-6">
                                <DLInputTextField
                                    id={''}
                                    label={'Name'}
                                    placeholder={"Please enter full name"}
                                    value={personalInformation.name}
                                    inputType={'text'}
                                    maxCharLength={62}
                                    isClearable={false}
                                    isRequired={true}
                                    disableHelperMessageHeight={true}
                                    marginBottom={'sm'}
                                    hideCharLength={true}
                                    onChangeValue={(value) => {
                                        handleChange('name', value, "personalInformation")
                                    }}
                                    error={errors.personalInformation.name !== ''}
                                    helperMessage={errors.personalInformation.name}
                                />
                            </div>
                            <div id='personalInformation-dateOfBirth-id' className="col-6">
                                <DLDatePicker
                                    id={'InboxView-FromDate'}
                                    label={'Date of Birth'}
                                    value={personalInformation.dateOfBirth}
                                    maximumDate={new Date()}
                                    isClearable={false}
                                    isRequired={true}
                                    minWidth={'100%'}
                                    disableHelperMessageHeight={true}
                                    marginBottom={'sm'}
                                    minimumDate={'1900-12-30'}
                                    onChangeValue={(value) => {
                                        handleChange('dateOfBirth', value, "personalInformation")
                                    }}
                                    error={errors.personalInformation.dateOfBirth !== ''}
                                    helperMessage={errors.personalInformation.dateOfBirth}

                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderNominationInformation = () => {
        return (
            <div className='mt-2'>
                <div className='section-title'
                     id={'Nomination-information'}>
                    Nomination Information
                </div>
                <div className="panel">
                    <div className='row'>
                        <div id='nomineeInformation-relation-id' className='col-6'>
                            <DLSearchAutoComplete
                                id={'relation-dropDown'}
                                label={'Relation'}
                                loadApiData={(input) => searchOnWordBoundary(input, dropdownValues?.relations)}
                                value={nomineeInformation.relation}
                                onSelect={(value) =>  handleChange('relation', value, "nomineeInformation")}
                                isClearable={false}
                                isSearchable={true}
                                error={errors.nomineeInformation.relation !== ''}
                                helperMessage={errors.nomineeInformation.relation}
                                itemNotFoundText={"No options found'"}
                                disableHelperMessageHeight={true}
                                marginBottom={'sm'}
                                isMulti={false}
                                defaultOptions={dropdownValues?.relations}
                                noOptionsMessage={"No options found'"}
                                isRequired={true}
                            />
                        </div>
                        {/*<div id='personalInformation-percentageAllocation-id' className='col-6'>*/}
                        {/*    <DLInputTextField*/}
                        {/*        id={''}*/}
                        {/*        label={'Percentage Allocation'}*/}
                        {/*        value={percentageAllocation}*/}
                        {/*        inputType={'number'}*/}
                        {/*        maxCharLength={3}*/}
                        {/*        isClearable={true}*/}
                        {/*        disableHelperMessageHeight={true}*/}
                        {/*        marginBottom={'sm'}*/}
                        {/*        isRequired={true}*/}
                        {/*        hideCharLength={true}*/}
                        {/*        onChangeValue={(value) => {*/}
                        {/*            handleChange('percentageAllocation', value, "personalInformation")*/}
                        {/*        }}*/}
                        {/*        error={errors.personalInformation.percentageAllocation !== ''}*/}
                        {/*        helperMessage={errors.personalInformation.percentageAllocation}*/}
                        {/*    />*/}
                        {/*</div>*/}
                        <div id='nomineeInformation-percentageAllocation-id' className='col-6 mb-3'>
                            <div style={{marginBottom : '2px'}}>
                                <DLText id={''}
                                        text={'Percentage Allocation'}
                                        fontSize={'xs'}
                                        marginBottom={"none"}
                                        fontColor={errors.nomineeInformation.percentageAllocation !== '' ? 'danger' : "grayDark"}
                                />
                            </div>

                            <NumberInputBasic
                                onChange={(value)=>{handleChange('percentageAllocation', value, "nomineeInformation")}}
                                value={nomineeInformation.percentageAllocation}
                            />

                            {(errors.nomineeInformation.percentageAllocation !== '') &&
                            <DLText text={errors.nomineeInformation.percentageAllocation}
                                    id={''}
                                    fontSize={'xs'}
                                    fontColor={"danger"}
                            />}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderMinorsDocumentUploader = () => {
        const {associateMinorsDocument, minorsDocument} = props;

        return (
            <DLDocUploader
                isDisableDelete={props.profile?.isManagement}
                id={'resource-doc-uploader'}
                history={props.history}
                key={'doc-uploader'}
                label={''}
                boxLabel={'Upload Birth Certificate'}
                onChangeValue={(type,value) => {
                    associateMinorsDocument(type,value);
                }}
                isShowLabel={false}
                disableHelperMessageHeight={false}
                helperMessage={errors.minorsDocument?.BIRTH_CERTIFICATE}
                isRequired={true}
                filePath={minorsDocument.filePath}
                uploadedFileName={minorsDocument.fileName}
                documentId={minorsDocument?.id}
                advisoryId={profile?.advisoryId}
                handleError={(error) => handleFileError(error, 62914560, 1)}
            />
        )
    };

    const renderGuardiansDocumentUploader = () => {
        const {associateGuardiansDocument, guardiansDocument} = props;

        return (
            <DLDocUploader
                id={'resource-doc-uploader'}
                history={props.history}
                key={'doc-uploader'}
                label={''}
                boxLabel={'Upload PAN Card'}
                onChangeValue={(type,value) => {
                    associateGuardiansDocument(type,value);
                }}
                isShowLabel={false}
                disableHelperMessageHeight={false}
                helperMessage={errors.guardiansDocument.PAN_CARD}
                isRequired={true}
                filePath={guardiansDocument.filePath}
                uploadedFileName={guardiansDocument.fileName}
                documentId={guardiansDocument?.id}
                advisoryId={profile?.advisoryId}
                isDisableDelete={props.profile?.isManagement}
                handleError={(error) => handleFileError(error, 62914560, 1)}
            />
        )
    };

    const renderMinorBlocks = () => {
        if(isMobileView){
            return (
                <div className={''}>
                    {renderPersonalInformationMobile()}
                    <div className='mobile-border-bottom'/>
                    {renderNominationInformationMobile()}
                    <div className='mobile-border-bottom'/>
                </div>
            )
        }

        return (
            <div className={''}>
                {renderPersonalInformation()}
                {renderNominationInformation()}
            </div>
        )
    };

    const renderGuardianBlocks = () => {
        if(isMobileView){
            return (
                <div className={''}>
                    {renderGuardianInformationMobile()}
                    <div className='mobile-border-bottom'/>
                    {renderGuardianNominationInformationMobile()}
                    <div className='mobile-border-bottom'/>
                    {renderGuardiansAddressMobile()}
                </div>
            )
        }
        return (
            <div className={''}>
                {renderGuardianInformation()}
                {renderGuardianNominationInformation()}
                {renderGuardiansAddress()}
            </div>
        )
    };

    const renderNote = () => {
        return (
            <div className={'d-flex mb-3'}>
                <DLText id={''}
                        text={'Notes: '}
                        fontSize={'sm'}
                        fontColor={'grayDark'}
                        fontWeight={'normal'}
                        marginBottom={'none'}
                />
                <div className="ms-1">
                    <DLText id={''}
                            text={'Birth Certificate is mandatory for minor.'}
                            fontSize={'sm'}
                            fontColor={'grayDark'}
                            fontWeight={'normal'}
                            marginBottom={'none'}
                    />
                    <DLText id={''}
                            text={'Guardian is mandatory for minor. Guardian PAN Card is mandatory.'}
                            fontSize={'sm'}
                            fontColor={'grayDark'}
                            fontWeight={'normal'}
                            marginBottom={'none'}
                    />
                </div>
            </div>
        )
    };

    const renderButtons = () => {
        const {onCancelClick, onSaveClick} = props;
        return (
            <div className='sticky-bottom-buttons'>
                <div style={{paddingTop: '7px'}}>
                    <DLButton
                        id={'create-lead-cancelBtn'}
                        history={history}
                        label={'Cancel'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        styleType={"outlined"}
                        backgroundColor={"grey"}
                        sideMargin={"sm"}
                        onClick={() => onCancelClick()}/>
                    <DLButton
                        id={'create-lead-saveBtn'}
                        history={history}
                        label={'Save'}
                        buttonSize={"sm"}
                        fontSize={"md"}
                        sideMargin={"sm"}
                        onClick={() => onSaveClick()}/>
                </div>
            </div>
        );
    };

    const renderErrorUI = () => {
        const {responseErrors, totalErrors} = props;

        if (isEmpty(responseErrors) || totalErrors === 0) {
            return '';
        }

        return (
            <div id={'page-level-errors'} className='my-3'>
                {responseErrors.slice(0, 3).map((errorMessage, index) => (
                    <div key={index} className='py-2 align-items-center d-flex message-block'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel'>
                            {errorMessage}
                        </div>
                    </div>
                ))}
                {totalErrors > 3 && (
                    <div className='py-2 align-items-center d-flex'>
                        {`...${totalErrors - 3} More`}
                    </div>
                )}
            </div>
        );
    };

    const renderLoader = () =>{
        return(
            <DLLoader isVisible={isLoaderVisible} type={'screen'}/>
        )
    }

    const renderUi = () => {
        if(isMobileView){
            return (
                <div>
                    {renderNote()}
                    <div>
                        <div>
                            {renderMinorsDocumentUploader()}
                        </div>
                        <div>
                            {renderMinorBlocks()}
                        </div>
                    </div>
                    <div>
                        <div  className='section-title'>
                            <DLText id={'guardian-block'}
                                    fontColor={'black'}
                                    fontWeight={'semi-bold'}
                                    marginBottom={"none"}
                                    fontSize={'lg'}
                                    text={'Guardian'}/>
                        </div>
                        <div>
                            <div>
                                {renderGuardiansDocumentUploader()}
                            </div>
                            <div>
                                {renderGuardianBlocks()}
                            </div>
                        </div>
                    </div>
                    {renderButtons()}
                </div>
            );
        }
        return (
            <div>
                {renderNote()}
                <div className={'border-bottom'}>
                    <div className="pb-4">
                        <div className='row p-0 m-0 pb-3'>
                            <div className='col-lg-4 ps-0'>
                                {renderMinorsDocumentUploader()}
                            </div>
                            <div className='col-lg-8'>
                                {renderMinorBlocks()}
                            </div>
                        </div>
                    </div>
                </div>
                <div  className="pt-4">
                    <div>
                        <DLText id={'guardian-block'}
                                fontColor={'black'}
                                fontWeight={'semi-bold'}
                                marginBottom={"sm"}
                                fontSize={'lg'}
                                text={'Guardian'}/>
                    </div>
                    <div className='row p-0 m-0'>
                        <div className='col-lg-4 ps-0'>
                            {renderGuardiansDocumentUploader()}
                        </div>
                        <div className='col-lg-8'>
                            {renderGuardianBlocks()}
                        </div>
                    </div>
                </div>
                {renderButtons()}
            </div>
        );
    };

    return (
        <div style={{overflowY: 'auto', marginBottom : '60px'}}>
            {renderErrorUI()}
            {renderUi()}
            {renderLoader()}
        </div>
    );
};

MinorNomineeCreateOrUpdateView.propTypes = {};

export default MinorNomineeCreateOrUpdateView;