import React, {useEffect, useState} from 'react';
import DashboardWidget from "../../../DashboardWidget";
import {connect} from "react-redux";
import {getManagementClientByLocation} from "../../../HomePageActions";

const ClientByLocation=(props)=> {
    const [locationState, setLocationState] = useState({
        clientLocation: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        const {profile} = props;
        const advisoryId = profile?.advisoryId;

        setLocationState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getManagementClientByLocation(advisoryId).then(res => {
            if (res.success) {
                setLocationState(prevState => ({
                    ...prevState,
                    clientLocation: res.data,
                    isShowLoader: false,
                }));
            } else {
                setLocationState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        // setFromDate(item.fromDate);
        // setToDate(item.toDate);
        // props.history.push('/leads')
    };


    return (
        <DashboardWidget
            {...props}
            {...locationState}
            detailsObj={locationState.clientLocation}
            setFilters={setFilters}
            title={"Summary by Location"}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(ClientByLocation);