import React, {useEffect, useState} from 'react';
import {connect} from "react-redux";
import DashboardWidget from "../../../DashboardWidget";
import {
    getOnboardingStatus,
    resetOnboardingListingFilters,
    setOnboardingStatus
} from "../../../HomePageActions";

const OnboardingStatus=(props)=> {
    const [onboardingStatusState, setOnboardingStatusState] = useState({
        onboardingStatus: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        const {profile} = props;
        const advisoryId = profile?.advisoryId;

        setOnboardingStatusState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getOnboardingStatus(advisoryId).then(res => {
            if (res.success) {
                setOnboardingStatusState(prevState => ({
                    ...prevState,
                    onboardingStatus: res.data,
                    isShowLoader: false,
                }));
            } else {
                setOnboardingStatusState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetOnboardingListingFilters();
        setOnboardingStatus([item.key]);
        props.history.push('/onboardings')
    };


    return (
        <DashboardWidget
            {...props}
            {...onboardingStatusState}
            detailsObj={onboardingStatusState.onboardingStatus}
            setFilters={setFilters}
            title={"Summary by Status"}
        />
    );
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(OnboardingStatus);