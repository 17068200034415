import React, {useState} from 'react';
import {connect} from "react-redux";

import {removeLead} from "../../../onboarding/OnboardingActions";

import DeleteLeadView from "./DeleteLeadView";


const DeleteLead = (props) => {

    const{onSaveSuccess} = props;

    const [deleteLeadState, setDeleteLeadState] = useState({
        isApiInProgress: false,
        handleErrorInComponent:true,
        modalErrorMessage:'',
    });

    const clearData = () => {
        const {handleCloseDeleteLeadModal} = props;
        handleCloseDeleteLeadModal();
    };

    const deleteLead = () => {
        const {profile, selectedLeadId} = props;
        const {advisoryId} = profile;

        setDeleteLeadState(prevStep => ({
            ...prevStep,
            isApiInProgress: true
        }));

        removeLead(selectedLeadId, advisoryId)
            .then((res) => {
                if (res.success) {
                    clearData();
                    props.history.push('/leads');
                    setDeleteLeadState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false
                    }));
                } else {
                    setDeleteLeadState((prevStep) => ({
                        ...prevStep,
                        isApiInProgress: false,
                        modalErrorMessage:res.__error
                    }));
                }
            }
        );
    };

    return (
        <DeleteLeadView
            {...props}
            {...deleteLeadState}
            deleteLead={deleteLead}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile
});

export default connect(mapStateToProps, {})(DeleteLead);
