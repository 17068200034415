import React from 'react';
import DLModal from "../../../core/components/DLModal/DLModal";
import DLModalTitle from "../../../core/components/DLModal/DLModalTitle/DLModalTitle";
import DLModalContainer from "../../../core/components/DLModal/DLModalContainer/DLModalContainer";
import DLInputTextField from "../../../core/components/DLInputTextField/DLInputTextField";
import DLButton from "../../../core/components/DLButton/DLButton";
import DLModalActions from "../../../core/components/DLModal/DLModalActions/DLModalActions";
import DLText from "../../../core/components/DLText/DLText";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

import {isEmpty} from "../../../utils/helperCommon";
import appTheme from "../../../assets/appTheme";

const AccountHolderCreateView = (props) => {

    function getFullName(verifyPANResponse) {
        const firstName = verifyPANResponse?.firstName || "";
        const middleName = verifyPANResponse?.middleName || "";
        const lastName = verifyPANResponse?.lastName || "";

        const fullName = `${firstName} ${middleName} ${lastName}`.trim().replace(/\s+/g, ' ');

        return fullName;
    }

    const renderUserDetails = (verifyPANResponse) => {
        const {isXSView} = props;

        if (isXSView) {
            return <div className='p-3'>
                <DLText id={'lead-name'}
                        fontSize={'md'}
                        fontWeight={'semi-bold'}
                        text={isEmpty(getFullName(verifyPANResponse)) ? '-' : getFullName(verifyPANResponse)}
                        marginBottom={'none'}/>

                <DLText id={'lead-email'}
                        fontWeight={"semi-bold"}
                        fontSize={'md'}
                        marginBottom={"none"}
                        isClickable={!isEmpty(verifyPANResponse?.primaryEmail)}
                        fontColor={'darkBlue'}
                        text={isEmpty(verifyPANResponse?.primaryEmail) ? '-' :
                            <a id={'lead-email'}
                               href={`mailto:${verifyPANResponse?.primaryEmail}`}
                               className="user-email">
                                <u>{verifyPANResponse?.primaryEmail}</u>
                            </a>
                        }
                />
                <DLText id={'lead-phone'}
                        fontWeight={"semi-bold"}
                        text={isEmpty(verifyPANResponse?.primaryPhoneNumber) ? '-' :
                            <a href={"tel:" + verifyPANResponse?.primaryPhoneNumber}
                               className='link-primary'>{verifyPANResponse?.primaryPhoneNumber}</a>
                        }
                        marginBottom={"none"}
                        isClickable={!isEmpty(verifyPANResponse?.primaryPhoneNumber)}
                />
            </div>
        }

        return(
            <div>
                <div className='row d-flex mt-3 pt-1'>
                    <div className=' col-6' >
                        <DLText id={'lead-name'}
                                fontSize={'xs'}
                                fontColor={'grayDark'}
                                text={'Name'}/>
                        <DLText id={'lead-name'}
                                isInline={true}
                                fontSize={'sm'}
                                fontWeight={'semi-bold'}
                                text={isEmpty(getFullName(verifyPANResponse)) ? '-' : getFullName(verifyPANResponse)}
                                marginBottom={'none'}/>
                    </div>
                    <div className='row mt-3'>
                        <div className='border-right col-6 ' >
                            <DLText id={'lead-email'}
                                    fontSize={'xs'}
                                    fontColor={'grayDark'}
                                    text={'Email'}/>
                            <DLText id={'lead-email'}
                                    fontWeight={"semi-bold"}
                                    isInline={true}
                                    fontSize={'sm'}
                                    marginBottom={"none"}
                                    isClickable={!isEmpty(verifyPANResponse?.primaryEmail)}
                                    text={isEmpty(verifyPANResponse?.primaryEmail) ? '-' :
                                        <a id={'lead-email'}
                                           href={`mailto:${verifyPANResponse?.primaryEmail}`}
                                           className="user-email"
                                        >
                                            {verifyPANResponse?.primaryEmail}
                                        </a>
                                    }/>
                        </div>
                        <div className='col-6'>
                            <DLText id={'lead-phone'}
                                    fontSize={'xs'}
                                    fontColor={'grayDark'}
                                    text={'Phone Number'}/>
                            <DLText id={'lead-phone'}
                                    fontWeight={"semi-bold"}
                                    fontSize={'sm'}
                                    text={isEmpty(verifyPANResponse?.primaryPhoneNumber) ? '-' :
                                        <a href={"tel:" + verifyPANResponse?.primaryPhoneNumber}
                                        >{verifyPANResponse?.primaryPhoneNumber}</a>
                                    }
                                    marginBottom={"none"}
                                    isClickable={!isEmpty(verifyPANResponse?.primaryPhoneNumber)}/>
                        </div>
                    </div>
                </div>
            </div>
                )
    };

    const renderPANVerificationFailed = () => {
        return (
            <div className='text-center w-100'>
                <div>
                    <DLText id='block-sub-title'
                            text={'We could not locate any person with this PAN Number.'}
                    />
                </div>
                <div className='pt-3'>
                    <DLText id='block-sub-title'
                            text={'Please choose another PAN or Add as new.'}
                    />
                </div>
            </div>
        )
    };

    const renderDetails = () => {
        const {verifyPANResponse, isVerifyCalled} = props;

        // If the verifyPANResponse is empty, return null
        if (!isVerifyCalled || isEmpty(verifyPANResponse)) {
            return <></>;
        }

        // Otherwise, return the user details and verification failure UI depending on response
        return (
            <div>
                {verifyPANResponse.existingPerson ?
                    <div className=''>
                        {renderUserDetails(verifyPANResponse)}
                    </div>
                    :
                    <div className='pt-4'>
                        {renderPANVerificationFailed()}
                    </div>
                }
            </div>
        );
    };

    const renderModalDetailsBlock = () => {
        const {PAN_Number, onChangePANData, verifyPAN, PAN_NumberError, isShowLoader, isXSView, error} = props;

        // Search UI component for PAN input and verification button
        const search = (
            <div className='d-flex justify-content-between align-items-end mt-3'>
            <div className={'d-flex align-items-center flex-grow-1'}>
                <div className='flex-grow-1' >
                    <DLInputTextField
                        id='add-PAN'
                        label={'PAN'}
                        value={PAN_Number.toUpperCase()}
                        marginBottom="none"
                        inputType="text"
                        disableHelperMessageHeight={true}
                        hideCharLength
                        isClearable={false}
                        isRequired={true}
                        maxCharLength={10}
                        onChangeValue={(val) => onChangePANData(val)}
                        style={{width: '100%'}}
                        placeholder='Please enter your PAN'
                        error={!isEmpty(PAN_NumberError)}
                        helperMessage={PAN_NumberError}
                    />
                </div>
            </div>
                <div className={'ms-2 mb-1'} style={{
                    maxWidth: 'max-content'
                }}>
                    <div className={isEmpty(PAN_NumberError) ? '' : 'pb-1 mb-3'}>
                    <DLButton
                        id='verify-button'
                        sideMargin='sm'
                        styleType='outlined'
                        type='black'
                        onClick={verifyPAN}
                        label='Search'
                    />
                    </div>
                </div>
                </div>
        );

        const renderError = <div key={'err-create'} className='py-2  d-flex align-items-center justify-content-center'>
            <div className='error-vertical-line'/>
            <div className='error-panel w-100'>{error}</div>
        </div>;

        return (
            <div className='' style={isXSView ? {} : {minHeight: '200px'}}>
                {!isEmpty(error) && renderError}
                {search}
                {renderDetails()}
                <div className='d-flex justify-content-center'>
                    <DLLoader isVisible={isShowLoader} type={'inline'}/>
                </div>

            </div>
        );
    };

    const renderCreateModal = () => {
        const {isOpenCreateModal, closeAccountModal, history, verifyPANResponse, handleSelectOrAddPANDetails} = props;

        return (
            <DLModal
                history={history}
                maxWidth={"sm"}
                onClose={() => closeAccountModal()}
                open={isOpenCreateModal}
                id={'add-holder-modal'}>
                <DLModalTitle
                    id={'add-holder-title'}
                    onClose={() => closeAccountModal()}
                    title={'Select Account Holder'}/>
                <DLModalContainer id={'add-holder-container'}>
                    {renderModalDetailsBlock()}
                </DLModalContainer>

                <DLModalActions id='add-holder-action'>
                    <div className='mb-3' style={{paddingTop: '7px', minHeight: '43px'}}>
                        <DLButton
                            id={'add-holder-cancelBtn'}
                            history={history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            hidden={isEmpty(verifyPANResponse)}
                            sideMargin={"sm"}
                            onClick={() => closeAccountModal()}/>
                        <DLButton
                            id={'add-holder-saveBtn'}
                            history={props.history}
                            label={verifyPANResponse.existingPerson ? 'Select' : 'Add New'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            hidden={isEmpty(verifyPANResponse)}
                            onClick={() => {
                                handleSelectOrAddPANDetails()
                            }}/>
                    </div>
                </DLModalActions>

            </DLModal>
        );
    };

    const overlay = () => {
        const {PAN_Number, onChangePANData, verifyPAN, PAN_NumberError, closeAccountModal, isShowLoader} = props;
        const {verifyPANResponse, handleSelectOrAddPANDetails, history, error} = props;

        return (
            <div>
                <DLLoader isVisible={isShowLoader} type={'screen'}/>

                <div className='d-flex justify-content-between align-items-center border-bottom '>
                    <div className='p-3'>
                        <DLText
                            id={'select-account'}
                            fontSize={'md'}
                            marginBottom={"none"}
                            text={'Select Account Holder'}
                        />
                    </div>
                    <div className='p-3'>
                        <i className="fa-solid fa-xmark cursor"
                           style={{fontSize: '16px'}}
                           onClick={() => closeAccountModal()}/>
                    </div>
                </div>
                <div className='d-flex justify-content-between p-3'>
                    {!isEmpty(error) &&
                    <div key={'err-create'} className='py-2  d-flex align-items-center justify-content-center'>
                        <div className='error-vertical-line'/>
                        <div className='error-panel w-100'>{error}</div>
                    </div>}
                    <div className='w-75'>
                        <DLInputTextField
                            id='add-PAN'
                            label='PAN'
                            value={PAN_Number.toUpperCase()}
                            marginBottom="none"
                            inputType="text"
                            disableHelperMessageHeight={true}
                            hideCharLength
                            isClearable={false}
                            maxCharLength={10}
                            onChangeValue={(val) => onChangePANData(val)}
                            style={{width: '100%'}}
                            placeholder='Please enter your PAN'
                            error={!isEmpty(PAN_NumberError)}
                            helperMessage={PAN_NumberError}
                        />
                    </div>
                    <div className='w-25 pt-4 ms-2'>
                        <DLButton
                            id={'verify-button'}
                            sideMargin={'none'}
                            styleType={'contained'}
                            type={'black'}
                            onClick={verifyPAN}
                            label={'Search'}
                        />
                    </div>
                </div>
                <div className='px-3'>
                    {renderDetails()}
                </div>
                <div className='fixed-bottom mb-2'>
                    <div className='mb-2 d-flex justify-content-center' style={{paddingTop: '7px', minHeight: '43px'}}>
                        <DLButton
                            id={'add-holder-cancelBtn'}
                            history={history}
                            label={'Cancel'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            styleType={"outlined"}
                            backgroundColor={"grey"}
                            sideMargin={"sm"}
                            hidden={isEmpty(verifyPANResponse)}
                            onClick={() => closeAccountModal()}/>
                        <DLButton
                            id={'add-holder-saveBtn'}
                            history={props.history}
                            label={verifyPANResponse.existingPerson ? 'Select' : 'Add New'}
                            buttonSize={"sm"}
                            fontSize={"md"}
                            sideMargin={"sm"}
                            hidden={isEmpty(verifyPANResponse)}
                            onClick={() => {
                                handleSelectOrAddPANDetails()
                            }}/>
                    </div>
                </div>
            </div>
        );
    };

    return (
        <div>
            {props.isOverlay ? overlay() : renderCreateModal()}
        </div>
    );
};

export default AccountHolderCreateView;