import {httpPost, httpGet, httpPut} from "../utils/httpHelper";
import store from "../store";
import {
    setSearchKeywordValue, setFromDateValue, setToDateValue,
    setStatusValue, resetAllFilters,
    setLimitCountValue,
    setPageValue,
    setRowsPerPageValue,
    setSkipCountValue,
    setCRMValue
} from "./leadsReducer"



export const getLeadsListing = (data, advisoryId) => {
    let URL = '/service/advisory/lead/request/all';

    return httpPost(URL, data, advisoryId)
        .then(res => {
            return res;
        });
};

export const saveLeadData = (data, advisoryId,handleErrorInComponent) => {
    return httpPost('/service/advisory/lead/request', data, advisoryId, handleErrorInComponent).then(res => {
        return res;
    })
};

export const getCategoryOptions = (advisoryId) => {
    return httpGet('/service/advisory/categoryType/all',advisoryId).then(res => {
        return res;
    });
};

export const getLeadSources = (advisoryId,handleErrorInComponent) => {
    return httpGet('/service/advisory/leadSource/all',advisoryId,handleErrorInComponent).then(res => {
        return res;
    })
};

export const getCountryData = (advisoryId,handleErrorInComponent) => {
    return httpGet('/service/advisory/country/all',advisoryId,handleErrorInComponent).then(res => {
        return res;
    })
};

export const getLeadDetails = (leadId, advisoryId) => {
    return httpGet('/service/advisory/lead/request?id='+leadId, advisoryId).then(res => {
        return res;
    })
};
export const getLostReasons = () => {
    return httpGet('/service/advisory/lostReason/all').then(res => {
        return res;
    })
};

export const updateLeadStatus = (data, advisoryId) => {
    return httpPost('/service/advisory/lead/request/updateStatus', data, advisoryId, true).then(res => {
        return res;
    })
};

export const updateOnHoldLeadStatus = (data, advisoryId) => {
    return httpPut('/service/advisory/lead/request/updateOnHoldDetails', data, advisoryId, true).then(res => {
        return res;
    })
};
export const updateMarkedAsContacted = (data, advisoryId) => {
    return httpPut('/service/advisory/lead/request/contacted', data, advisoryId, true).then(res => {
        return res;
    })
};

export const leadAddRemark = (data, advisoryId) => {
    return httpPost('/service/advisory/lead/request/remark', data, advisoryId, true).then(res => {
        return res;
    })
};

export const setSearchKeyword = (data) => {
    store.dispatch(setSearchKeywordValue(data))
};

export const setFromDate = (data) => {
    store.dispatch(setFromDateValue(data))
};

export const setToDate = (data) => {
    store.dispatch(setToDateValue(data))
};

export const setStatus = (data) => {
    store.dispatch(setStatusValue(data))
};

export const setCRM = (data) => {
    store.dispatch(setCRMValue(data))
};

export const resetLeadFilters = (data) => {
    store.dispatch(resetAllFilters(data))
};

export const setSkipCount = (data) => {
    store.dispatch(setSkipCountValue(data))
};

export const setLimitCount = (data) => {
    store.dispatch(setLimitCountValue(data))
};

export const setPageCount = (data) => {
    store.dispatch(setPageValue(data))
};

export const setLeadsRowPerPageCount = (data) => {
    store.dispatch(setRowsPerPageValue(data))
};




