import React, {useEffect, useState} from 'react';
import BankAccountView from "./BankAccountView";
import {connect} from "react-redux";
import {
    getBankAccountType, getBankData, getBanks,
    getDetailsByIFSC, getStateList, updateBankAccountData
} from "../../OnboardingActions";
import {isEmpty} from "../../../utils/validations";
import {isAlphabetic, isIncludeSpecialCharacters} from "../../../utils/helper";
import store from "../../../store";
import {showToastMessage} from "../../../AppComponents/AppActions";

const BankAccount = (props) => {

    const errorObj={
        accountHolderDetails: {
            accountHolderName: "",
            accountNumber: "",
            bankAccountType: ""
        },
        bankDetails: {
            bankName: "",
            IFSCCode: "",
            branch: "",
            MICRNo: ""
        },
        bankAddress: {
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            stateCode: "",
            zipCode: ""
        },
        bankMandate: {
            amount: ""
        }
    };

    const [bankAccountState, setBankAccountState] = useState({
        accountHolderDetails: {
            accountHolderName: "",
            accountNumber: "",
            bankAccountType: ""
        },
        bankDetails: {
            bankName: "",
            IFSCCode: "",
            branch: "",
            MICRNo: ""
        },
        bankAddress: {
            addressLine1: "",
            addressLine2: "",
            addressLine3: "",
            city: "",
            stateCode: "",
            zipCode: "",
            countryCode: "IND"
        },
        bankMandate: {
            amount: 200000
        },
        errors: errorObj,
        dropdownValues: {
            accountTypes: [],
            states: [],
            banks: []
        },
        isLoaderVisible: false,
        scrollToFieldId: '',
        responseErrors: [],
        totalErrors: ''
    });

    useEffect(() => {
        getMasterData();
    }, []);

    const getMasterData = () => {
        const {profile, accountId} = props;
        let advisoryId = profile?.advisoryId;

        setBankAccountState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        getBankAccountType(advisoryId).then(typeRes => {
            if (typeRes?.success) {

                const accountTypeData = typeRes?.data?.bankAccountType;
                getStateList(advisoryId)
                    .then(stateRes => {
                        if (stateRes?.success) {
                            const stateData = stateRes?.data?.state;

                            getBanks(advisoryId)
                                .then(banksRes => {
                                    if (banksRes?.success) {
                                        const banksData = banksRes?.data?.banks;

                                        const dropdownVals = {
                                            accountTypes: setValuesForDropdown(accountTypeData, "description", "code"),
                                            states: setValuesForDropdown(stateData, "description", "code"),
                                            banks: setValuesForDropdown(banksData, "description", "code"),
                                        };
                                        getBankData(accountId,advisoryId)
                                            .then(bankResData => {
                                                if (bankResData?.success) {
                                                    const updatedFPData = getMappedData(bankResData.data, dropdownVals);

                                                    setBankAccountState(prevState => ({
                                                        ...prevState,
                                                        ...updatedFPData,
                                                        dropdownValues: {
                                                            ...prevState.dropdownValues,
                                                            ...dropdownVals
                                                        },
                                                        isLoaderVisible: false
                                                    }));
                                                }else{
                                                    setBankAccountState(prevState => ({
                                                        ...prevState,
                                                        dropdownValues: {
                                                            ...prevState.dropdownValues,
                                                            ...dropdownVals
                                                        },
                                                        isLoaderVisible: false
                                                    }));
                                                }
                                            });
                                    }
                                })
                        }
                    })
            }
        })
    };

    //GET CALL MAPPING
    const getMappedData=(response, dropDownValues)=>{
        const {isFromOnboardingFlow} = props;
        const {errors} = bankAccountState;
        if(isEmpty(response)){
            const {accountHolderDetails,bankDetails,bankAddress, bankMandate} = bankAccountState;
            let errorsObj = {
                ...errors,
                accountHolderDetails: (isFromOnboardingFlow) ?errors.accountHolderDetails :
                    validateAccountHolderDetails({accountHolderDetails: accountHolderDetails, errors : errors.accountHolderDetails}, 'SUBMIT'),
                bankDetails : (isFromOnboardingFlow) ?errors.bankDetails :
                    validateBankDetails({bankDetails: bankDetails, errors : errors.bankDetails}, 'SUBMIT'),
                bankAddress : (isFromOnboardingFlow) ?errors.bankAddress :
                    validateBankAddress({bankAddress: bankAddress, errors : errors.bankAddress}, 'SUBMIT'),
                bankMandate : (isFromOnboardingFlow) ? errors.bankMandate :
                    validateBankMandate({bankMandate: bankMandate, errors : errors.bankMandate}, 'SUBMIT')
            };
            return {
                errors: {
                    ...errorsObj
                },
                scrollToFieldId : getFirstErrorFieldId(errorsObj)
            };
        }
        let accountHolderFields = bankAccountState.accountHolderDetails;

        let accountHolderDetails =  {
            accountHolderName: isEmpty(response.accountHolderName) ? accountHolderFields.accountHolderName :response?.accountHolderName,
            accountNumber: isEmpty(response.accountNumber) ? accountHolderFields.accountNumber :response?.accountNumber,
            bankAccountType: isEmpty(response.bankAccountType) ? accountHolderFields.bankAccountType :getDropdownSelectedObj('accountTypes',response?.bankAccountType,dropDownValues)
        };
        let bankDetails = {
            bankName: getDropdownSelectedObj('banks',response?.bankName,dropDownValues), //
            IFSCCode: isEmpty(response.IFSCCode) ? '' :response?.IFSCCode,
            branch: isEmpty(response.branch) ? '' :response?.branch,
            MICRNo: isEmpty(response.MICRNo) ? '' :response?.MICRNo,
        };
        let bankAddress = (isEmpty(response?.bankAddress)) ? {
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                stateCode: "",
                zipCode: "",
                countryCode: "IND"
            } :
            {
                addressLine1: isEmpty(response?.bankAddress?.addressLine1) ? '' :response.bankAddress?.addressLine1,
                addressLine2: isEmpty(response?.bankAddress?.addressLine2) ? '' :response.bankAddress?.addressLine2,
                addressLine3: isEmpty(response?.bankAddress?.addressLine3) ? '' :response.bankAddress?.addressLine3,
                city: isEmpty(response?.bankAddress?.city) ? '' :response.bankAddress?.city,
                stateCode: getDropdownSelectedObj('states',response.bankAddress?.stateCode,dropDownValues), //
                zipCode: isEmpty(response?.bankAddress?.zipCode) ? '' :response.bankAddress?.zipCode
            };

        let bankMandate = (isEmpty(response?.bankMandate)) ? {
                amount: 200000
            } :
            {
                amount: isEmpty(response?.bankMandate?.amount) ? 200000 :response.bankMandate?.amount
            };

        let accountHolderDetailsError = validateAccountHolderDetails({accountHolderDetails: accountHolderDetails, errors : errors.accountHolderDetails}, 'SUBMIT');
        let bankDetailsError = validateBankDetails({bankDetails: bankDetails, errors : errors.bankDetails}, 'SUBMIT');
        let bankAddressError = validateBankAddress({bankAddress: bankAddress, errors : errors.bankAddress}, 'SUBMIT');
        let bankMandateError = validateBankMandate({bankMandate: bankMandate, errors : errors.bankMandate}, 'SUBMIT');

        let errorsObj = {
            ...errors,
            accountHolderDetails: (isFromOnboardingFlow) ?errors.accountHolderDetails : accountHolderDetailsError,
            bankDetails : (isFromOnboardingFlow) ?errors.bankDetails :bankDetailsError,
            bankAddress : (isFromOnboardingFlow) ?errors.bankAddress :bankAddressError,
            bankMandate : (isFromOnboardingFlow) ?errors.bankMandate :bankMandateError
        };

        return {
            accountHolderDetails: {...accountHolderDetails},
            bankDetails: {...bankDetails},
            bankAddress: {...bankAddress},
            bankMandate: {...bankMandate},
            errors: {
                ...errorsObj
            },
            scrollToFieldId : getFirstErrorFieldId(errorsObj)
        };
    };

    const getDropdownSelectedObj=(type,selectedValue, dropDownValues)=>{
        if(isEmpty(selectedValue)){
            return ''
        }
        return dropDownValues[type]?.find(dropdownVal=>{
            return dropdownVal.value === selectedValue})
    };

    const setValuesForDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }

        return data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken]}
        });
    };

    const getBankName = (name) => {
        const {dropdownValues} = bankAccountState;

        return dropdownValues.banks.find((item) => {
            return (item.label === name)
        })
    };

    const onSearchClick = (IFSC) => {
        const {profile} = props;
        let advisoryId = profile?.advisoryId;
        let payload = {
            IFSCCode: IFSC
        };

        const validateObject = validateChanges(bankAccountState, 'SUBMIT');

        getDetailsByIFSC(payload, advisoryId)
            .then(res => {
                if (res?.success) {
                    if(isEmpty(res.data?.validationErrors)){
                        const mappedData= getMappedData(res.data,bankAccountState.dropdownValues);
                        setBankAccountState(prevState => ({
                            ...prevState,
                            ...mappedData,
                            errors: errorObj,
                            responseErrors: '',
                            totalErrors: ''
                        }));
                    }else{
                        assignResponseError(res.data, validateObject)
                    }
                }else{
                    let responseErrors = [];
                    if(res?.__error){
                        responseErrors.push(res?.__error);
                    }
                    setBankAccountState(prevStep => ({
                        ...prevStep,
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length
                    }));
                }
            })
    };

    const handleChange = (name, value, fieldType) => {
        let updatedCompState = {...bankAccountState};

        if(value === null){
            value = ''
        }

        if (fieldType === "bankAddress") {
            updatedCompState.bankAddress[name] = value;
        } else if (fieldType === "bankDetails") {
            updatedCompState.bankDetails[name] = value;
        } else if (fieldType === "accountHolderDetails") {
            updatedCompState.accountHolderDetails[name] = value;
        } else if (fieldType === "bankMandate") {
            updatedCompState.bankMandate[name] = value;
        }  else {
            updatedCompState[name] = value;
        }

        setBankAccountState(prevStep => ({
            ...prevStep,
            ...updatedCompState,
        }));


        const validateObject = validateChanges(updatedCompState, name, fieldType);

        setBankAccountState(prevStep => ({
            ...prevStep,
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));

    };

    const validateBankDetails = (componentState, name) => {
        const {bankDetails, errors} = componentState;
        const {bankName, IFSCCode, branch, MICRNo} = bankDetails;

        let errorObj = {...errors.bankDetails};

        if (name === 'IFSCCode' || name === 'SUBMIT') {
            if (isEmpty(IFSCCode)) {
                errorObj.IFSCCode = 'Please enter IFSC code'
            } else if (IFSCCode.length > 11) {
                errorObj.IFSCCode = 'Please enter 11 characters only'
            } else {
                errorObj.IFSCCode = ''
            }

            if (name === 'IFSCCode') {
                return errorObj
            }
        }

        if (name === 'bankName' || name === 'SUBMIT') {
            if (isEmpty(bankName)) {
                errorObj.bankName = 'Please enter bank name'
            } else if (bankName.length > 60) {
                errorObj.bankName = 'Please enter 60 characters only'
            } else {
                errorObj.bankName = ''
            }
            if (name === 'bankName') {
                return errorObj
            }
        }

        if (name === 'branch' || name === 'SUBMIT') {
            if (isEmpty(branch)) {
                errorObj.branch = 'Please enter branch'
            }  else if (isAlphabetic(branch)) {
                errorObj.branch = 'Please enter alphabets only'
            } else if (branch.length > 30) {
                errorObj.branch = 'Please enter 30 alphabets only'
            } else {
                errorObj.branch = ''
            }

            if (name === 'branch') {
                return errorObj
            }
        }

        if (name === 'MICRNo' || name === 'SUBMIT') {
            if (MICRNo.length > 9) {
                errorObj.MICRNo = 'Please enter 9 numbers only'
            } else {
                errorObj.MICRNo = ''
            }

            if (name === 'MICRNo') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateBankAddress = (componentState, name) => {
        const {bankAddress, errors} = componentState;
        const {addressLine1, addressLine2, addressLine3, zipCode, stateCode} = bankAddress;

        let errorObj = {...errors.bankAddress};

        if ((name === 'addressLine1') || name === 'SUBMIT') {
            if (isEmpty(addressLine1)) {
                errorObj.addressLine1 = "Please enter address line 1"
            }else if (bankAddress.addressLine1.length > 80) {
                errorObj.addressLine1 = "Please enter 80 characters only"
            } else {
                errorObj.addressLine1 = ""
            }
            if (name === 'addressLine1') {
                return errorObj
            }
        }

        if ((name === 'addressLine2') || name === 'SUBMIT') {
            if (addressLine2.length > 50) {
                errorObj.addressLine2 = "Please enter 50 characters only"
            }else {
                errorObj.addressLine2 = ""
            }
            if (name === 'addressLine2') {
                return errorObj
            }
        }

        if ((name === 'addressLine3') || name === 'SUBMIT') {
            if (addressLine3.length > 50) {
                errorObj.addressLine3 = "Please enter 50 characters only"
            } else {
                errorObj.addressLine3 = ""
            }
            if (name === 'addressLine3') {
                return errorObj
            }
        }

        if ((name === 'city') || name === 'SUBMIT') {
            if (isEmpty(bankAddress.city)) {
                errorObj.city = "Please enter city"
            }else if (bankAddress.city.length > 30) {
                errorObj.city = "Please enter 30 characters only"
            } else {
                errorObj.city = ""
            }
            if (name === 'city') {
                return errorObj
            }
        }

        if ((name === 'zipCode') || name === 'SUBMIT') {
            if (zipCode.length > 6) {
                errorObj.zipCode = "Please enter 6 characters only"
            } else {
                errorObj.zipCode = ""
            }
            if (name === 'zipCode') {
                return errorObj
            }
        }

        if ((name === 'stateCode') || name === 'SUBMIT') {
            if (isEmpty(stateCode)) {
                errorObj.stateCode = "Please select state"
            } else {
                errorObj.stateCode = ""
            }
            if (name === 'stateCode') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateBankMandate = (componentState, name) => {
        const {bankMandate, errors} = componentState;
        const {amount} = bankMandate;

        let errorObj = {...errors.bankMandate};

        if ((name === 'amount') || name === 'SUBMIT') {
            if (isEmpty(amount)) {
                errorObj.amount = "Please enter amount"
            }else if (amount < 1000) {
                errorObj.amount = "Minimum amount should be 1,000"
            }else if (amount > 100000000) {
                errorObj.amount = "Maximum amount should be 100,000,000"
            }else {
                errorObj.amount = ""
            }
            if (name === 'amount') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateAccountHolderDetails = (componentState, name) => {
        const {accountHolderDetails, errors} = componentState;
        const {accountHolderName, accountNumber, bankAccountType} = accountHolderDetails;

        let errorObj = {...errors.accountHolderDetails};

        if (name === 'accountHolderName' || name === 'SUBMIT') { // 2 more validations are pending
            if (isEmpty(accountHolderName)) {
                errorObj.accountHolderName = 'Please enter name'
            } else if (isIncludeSpecialCharacters(accountHolderName) || isAlphabetic(accountHolderName)) {
                errorObj.accountHolderName = 'Please enter alphabets only'
            }  else if (accountHolderName.length > 60) {
                errorObj.accountHolderName = 'Please enter 60 alphabets only'
            } else {
                errorObj.accountHolderName = ''
            }
            if (name === 'accountHolderName') {
                return errorObj
            }
        }

        if (name === 'accountNumber' || name === 'SUBMIT') { // 1 more validations are pending
            if (isEmpty(accountNumber)) {
                errorObj.accountNumber = 'Please enter account number'
            } else if (accountNumber.length > 20) {
                errorObj.accountNumber = 'Please enter 20 numbers only'
            } else {
                errorObj.accountNumber = ''
            }

            if (name === 'accountNumber') {
                return errorObj
            }
        }

        if (name === 'bankAccountType' || name === 'SUBMIT') {
            if (isEmpty(bankAccountType)) {
                errorObj.bankAccountType = 'Please select account type'
            } else {
                errorObj.bankAccountType = ''
            }

            if (name === 'bankAccountType') {
                return errorObj
            }
        }

        return errorObj
    };

    const validateChanges = (componentState, name, fieldType) => {
        const {errors} = componentState;
        let errorObj = errors;

        if (fieldType === 'bankDetails' || isEmpty(fieldType)) {
            errorObj.bankDetails = validateBankDetails(componentState, name);
        }

        if (fieldType === 'bankAddress' || isEmpty(fieldType)) {
            errorObj.bankAddress = validateBankAddress(componentState, name);
        }

        if (fieldType === 'accountHolderDetails' || isEmpty(fieldType)) {
            errorObj.accountHolderDetails = validateAccountHolderDetails(componentState, name);
        }

        if (fieldType === 'bankMandate' || isEmpty(fieldType)) {
            errorObj.bankMandate = validateBankMandate(componentState, name);
        }

        return errorObj;
    };

    const simplifyObjectValues = (input) => {
        // Create a copy of the input object to avoid mutating the original object
        const output = {...input};

        // Iterate through the keys of the object
        for (const key in output) {
            // If the property's value is an object and has a 'value' key, replace it
            if (typeof output[key] === 'object' && output[key] !== null && 'value' in output[key]) {
                output[key] = output[key].value;
            }
        }
        return output;
    };

    const onNextClick = () => {
        const {profile, handleClickNext, isProceedWithErrors, accountId, onboardingObj} = props;
        let onboardingApplicationId = onboardingObj?.onboardingApplicationId;

        setBankAccountState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        const validateObject = validateChanges(bankAccountState, 'SUBMIT');
        let updatedData = {
            ...bankAccountState,
            ...bankAccountState.bankDetails,
            ...bankAccountState.accountHolderDetails
        };  // removed bankdetails and accountDetails obj var outside obj
        delete updatedData.errors;
        delete updatedData.dropdownValues;
        delete updatedData.bankDetails;
        delete updatedData.accountHolderDetails;
        delete updatedData.isLoaderVisible;
        delete updatedData.scrollToFieldId;
        delete updatedData.responseErrors;
        delete updatedData.totalErrors;


        let profileDataObj = {
            ...updatedData,
        };
        let errorId = '';
        profileDataObj = simplifyObjectValues(profileDataObj);
        profileDataObj.bankAddress = simplifyObjectValues(updatedData.bankAddress);
        profileDataObj.accountId = accountId;
        profileDataObj.onboardingApplicationId = onboardingApplicationId;
        profileDataObj.mode = 'BANK_ACCOUNT';

        updateBankAccountData(profileDataObj, profile?.advisoryId)
            .then(res => {
                if (res.success) {
                    if (isProceedWithErrors) {
                        handleClickNext();
                        setBankAccountState(prevState => ({
                            ...prevState,
                            isLoaderVisible: true
                        }));
                        return
                    }
                    if(isEmpty(res.data?.validationErrors)){
                        handleClickNext();
                        setBankAccountState(prevState => ({
                            ...prevState,
                            isLoaderVisible: false
                        }));
                    }else{
                        assignResponseError(res.data, validateObject)
                    }
                } else {
                    let responseErrors = [];
                    if(res?.__error){
                        responseErrors.push(res?.__error);
                        errorId= 'page-level-errors'
                    }
                    setBankAccountState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                            ...validateObject,
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                        scrollToFieldId: errorId
                    }));
                }

            })
    };

    const assignResponseError = (response, stateErrors) => {

        const errorResponse = response?.validationErrors;

        let newStateErrors = {...stateErrors};
        let responseErrors = [];

        if (isEmpty(errorResponse)) { //handled state if reponse status is failed
            setBankAccountState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...newStateErrors,
                },
                isLoaderVisible: false,
                responseErrors: [],
                totalErrors: 0,
            }));
            return
        }

        errorResponse.forEach(error => { //assign each field by section name
            const section = error.section;
            if (!newStateErrors[section]) {
                return;
            }
            if ((error.fields).length > 1) { //add page level validation error
                responseErrors.push(error.message)
            } else {
                //assign each field error to respected field and log if invalid field is found
                error.fields.forEach(field => {
                    newStateErrors[section][field] = error.message;
                });
            }
        });
        let errors = {
            ...bankAccountState.errors,
            ...newStateErrors,
        };
        setBankAccountState(prevStep => ({
            ...prevStep,
            errors: {
                ...prevStep.errors,
                ...newStateErrors,
            },
            responseErrors: responseErrors,
            totalErrors: responseErrors.length,
            isLoaderVisible: false,
            scrollToFieldId: isEmpty(responseErrors) ? getFirstErrorFieldId(errors) : 'page-level-errors'
        }));
    };

    const getFirstErrorFieldId = (errors) => {
        let sectionOrderToSort =["bankDetails", "bankAddress", "accountHolderDetails", "bankMandate"];
        for (const section of sectionOrderToSort) {
            if (errors[section]) {
                for (const key in errors[section]) {

                    const error = errors[section][key];
                    if (error && typeof error === 'object' && 'label' in error && error.label !== 'type') {
                        return section + '-' + error.label + '-id';
                    }
                    if (typeof error === 'string' && error && key !== 'type' && key !== 'parentType') {
                        return section + '-' + key + '-id';
                    }
                }
            }
        }
        return 'bank-account';
    };

    const isTabUI = () => {
        const {isMDView, isLGView} = props;
        return isMDView || isLGView
    };

    const onSaveAndCloseClick = () => {
        const {profile, handleClickSaveAndClose, accountId, saveFailureErrorMessage} = props;

        setBankAccountState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));


        const validateObject = validateChanges(bankAccountState, 'SUBMIT');
        let updatedData = {
            ...bankAccountState,
            ...bankAccountState.bankDetails,
            ...bankAccountState.accountHolderDetails
        };  // removed bankdetails and accountDetails obj var outside obj
        delete updatedData.errors;
        delete updatedData.dropdownValues;
        delete updatedData.bankDetails;
        delete updatedData.accountHolderDetails;
        delete updatedData.isLoaderVisible;
        delete updatedData.scrollToFieldId;
        delete updatedData.responseErrors;
        delete updatedData.totalErrors;


        let profileDataObj = {
            ...updatedData,
        };
        let errorId = '';
        profileDataObj = simplifyObjectValues(profileDataObj);
        profileDataObj.bankAddress = simplifyObjectValues(updatedData.bankAddress);
        profileDataObj.accountId = accountId;
        profileDataObj.mode = 'BANK_ACCOUNT';


        updateBankAccountData(profileDataObj, profile?.advisoryId)
            .then(res => {
                if (res.success) {
                    handleClickSaveAndClose()
                } else {
                    store.dispatch(showToastMessage('warning', saveFailureErrorMessage));
                    let responseErrors = [];
                    if(res?.__error){
                        responseErrors.push(res?.__error);
                        errorId= 'page-level-errors'
                    }
                    setBankAccountState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                            ...validateObject,
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        isLoaderVisible: false,
                        scrollToFieldId: errorId
                    }));
                }
            })
    };

    return (
        <BankAccountView
            {...props}
            {...bankAccountState}
            handleChange={handleChange}
            onNextClick={onNextClick}
            onSearchClick={onSearchClick}
            isTabUI={isTabUI}
            onSaveAndCloseClick={onSaveAndCloseClick}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    accountId: state.onboardingFlowState.accountId,
    onboardingObj: state.onboardingFlowState.onboardingObj,
});

export default connect(mapStateToProps, {})(BankAccount);
