import React, {useEffect, useState} from 'react';
import DashboardWidget from "../../../DashboardWidget";
import {connect} from "react-redux";
import {
    getOnboardingAgeing,
    resetOnboardingListingFilters,
    setOnboardingFromDate,
    setOnboardingToDate
} from "../../../HomePageActions";

const OnboardingAgeing=(props)=> {
    const [ageingState, setAgeingState] = useState({
        onboardingAgeing: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        const {profile} = props;
        const {advisoryId} = profile;

        setAgeingState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getOnboardingAgeing(advisoryId).then(res => {
            if (res?.success) {
                setAgeingState(prevState => ({
                    ...prevState,
                    onboardingAgeing: res.data,
                    isShowLoader: false,
                }));
            } else {
                setAgeingState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetOnboardingListingFilters();
        setOnboardingFromDate(item.fromDate);
        setOnboardingToDate(item.toDate);
        props.history.push('/onboardings')
    };


    return (
        <DashboardWidget
            {...props}
            {...ageingState}
            detailsObj={ageingState.onboardingAgeing}
            setFilters={setFilters}
            title={"Ageing"}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(OnboardingAgeing);