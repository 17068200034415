import React, {useEffect, useState} from 'react';
import NomineeListView from "./NomineeListView";
import {getNomineeListing, getOnboardingDetails, removeNominee, updateNomineeOpted} from "../../OnboardingActions";
import {connect} from "react-redux";
import {APP_CONST} from "../../../AppComponents/AppConstants";
import {isEmpty} from "../../../core/components/DLComponentHelper";
import DLLoader from "../../../core/components/DLLoader/DLLoader";

const NomineeList = (props) => {
    const [nomineeListState, setNomineeListState] = useState({
        nominees: [],
        UIState: '',
        isShowLoader: false,
        isAddNomineeModalOpen: false,
        selectedNominee: null,
        isDeleteNomineeDialogOpen: false,
        selectedNomineeId: '',
        showNomineeDetails: false,
        isFinishDialogOpen: false,
        isNomineeOpted: "YES",
        isDeleteAllNomineeDialogOpen: false,
        responseErrors: [],
        totalErrors: ''
    });

    useEffect(() => {
        getDetails()
    }, []);

    const getDetails = () => {
        const {profile, onboardingApplicationId, isFromOnboardingFlow, onboardingObj} = props;
        let applicationId = isFromOnboardingFlow ? onboardingObj?.onboardingApplicationId : onboardingApplicationId;
        getOnboardingDetails(applicationId, profile.advisoryId)
            .then((onBoardingRes) => {
                if (onBoardingRes?.success) {
                    let onboardingDetails = onBoardingRes.data;
                    setNomineeListState((preState) => ({
                            ...preState,
                            isNomineeOpted: onboardingDetails?.account?.isNomineeOpted ? "YES" : "NO"
                        }
                    ))
                    getListing()
                }
            })
    }

    const getListing = () => {
        const {profile, accountId} = props;
        let advisoryId = profile?.advisoryId;

        getNomineeListing(accountId, advisoryId).then(res => {
            if (res?.success) {
                setNomineeListState((preState) => ({
                        ...preState,
                        nominees: res.data,
                        isShowLoader: false,
                        UIState: APP_CONST.CONTENT_AVAILABLE
                    }
                ))
            } else {
                let responseErrors = [];
                if (res?.__error) {
                    responseErrors.push(res?.__error);
                }
                setNomineeListState(prevStep => ({
                    ...prevStep,
                    responseErrors: responseErrors,
                    totalErrors: responseErrors.length,
                    isShowLoader: false,
                    UIState: APP_CONST.CONTENT_UNAVAILABLE,
                }));
            }
        })
    };

    const resetErrorsState = () => {
        setNomineeListState(prevState => ({
            ...prevState,
            responseErrors: [],
            totalErrors: ''
        }));
    }

    const setNomineeOpted = (value) => {
        const {profile, accountId} = props;
        const {responseErrors} = nomineeListState
        let advisoryId = profile?.advisoryId;

        if (!isEmpty(responseErrors)) {
            resetErrorsState()
        }

        let payload = {
            accountId: accountId,
            isNomineeOpted: value === "YES"
        }

        updateNomineeOpted(payload, advisoryId)
            .then(res => {
                if (res.success) {
                    setNomineeListState(prevState => ({
                        ...prevState,
                        isNomineeOpted: value
                    }));
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setNomineeListState(prevStep => ({
                        ...prevStep,
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                    }));
                }
            });
    }

    const openDeleteAllNomineeDialog = () => {
        if (isEmpty(nomineeListState.nominees)) {
            setNomineeOpted("NO")
        } else {
            setNomineeListState(prevState => ({
                ...prevState,
                isDeleteAllNomineeDialogOpen: true
            }));
        }
    }

    const closeDeleteAllNomineeDialog = () => {
        setNomineeListState(prevState => ({
            ...prevState,
            isDeleteAllNomineeDialogOpen: false
        }));
    }

    const handleOpenEditModal = (nomineeId) => {   // done
        setNomineeListState((prevStep) => ({
            ...prevStep,
            isAddNomineeModalOpen: true,
            nomineeType: "EXISTING",
            selectedNomineeId: nomineeId
        }));
    };


    const handleOpenCreateModal = () => {   // done
        setNomineeListState((prevStep) => ({
            ...prevStep,
            isAddNomineeModalOpen: true,
            nomineeType: "NEW"
        }));
    };

    const handleCloseCreateModal = () => {  // done
        setNomineeListState((prevStep) => ({
            ...prevStep,
            isAddNomineeModalOpen: false,
            nomineeType: "",
            selectedNomineeId: "",
            UIState: ""
        }));
        getListing()
    };

    const setSelectedNominee = (nomineeId) => {
        setNomineeListState((prevStep) => ({
            ...prevStep,
            showNomineeDetails: true,
            selectedNomineeId: nomineeId
        }));
    };

    const clearSelectedNominee = () => {
        setNomineeListState((prevStep) => ({
            ...prevStep,
            showNomineeDetails: false,
            selectedNomineeId: ''
        }));
    };


    const selectedOption = (type, nomineeId) => {
        if (type === 'DELETE') {
            handleOpenDeleteModal(nomineeId)
        }
        if (type === 'EDIT') {
            handleOpenEditModal(nomineeId)
            // setSelectedNominee(nomineeId);
        }
    };

    const handleOpenDeleteModal = (nomineeId) => {
        setNomineeListState((prevStep) => ({
            ...prevStep,
            isDeleteNomineeDialogOpen: true,
            selectedNomineeId: nomineeId
        }));
    };

    const handleCloseDeleteModal = () => {
        setNomineeListState((prevStep) => ({
            ...prevStep,
            isDeleteNomineeDialogOpen: false,
            selectedNomineeId: ''
        }));
    };

    const handleRemoveNominee = (nomineeId) => {
        const {profile, accountId} = props;
        let advisoryId = profile?.advisoryId;
        setNomineeListState(prevStep => ({
            ...prevStep,
            isShowLoader: true
        }));

        removeNominee(accountId, nomineeId, advisoryId)
            .then(res => {
                if (res.success) {
                    setNomineeListState(prevStep => ({
                        ...prevStep,
                        isShowLoader: false
                    }));
                    console.log("Your Nominee is deleted");
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                    }
                    setNomineeListState(prevStep => ({
                        ...prevStep,
                        isShowLoader: false,
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                    }));
                }
                getListing();
                handleCloseDeleteModal()
            });
    };

    const {UIState} = nomineeListState;

    if (isEmpty(UIState)) {
        return (
            <DLLoader isVisible={true} type={'screen'}/>
        )
    }

    const handleOpenFinishDialog = () => {
        setNomineeListState((prevStep) => ({
            ...prevStep,
            isFinishDialogOpen: true
        }));
    };

    const handleCloseFinishDialog = () => {
        setNomineeListState((prevStep) => ({
            ...prevStep,
            isFinishDialogOpen: false
        }));
    };

    const redirectToDetails = (redirectURL) => {
        setNomineeListState((prevStep) => ({
            ...prevStep,
            isFinishDialogOpen: false
        }));

        setTimeout(() => {
            props.history.replace(redirectURL, 'showErrors');
            props.history.push(redirectURL, 'showErrors')
        }, 1000)
    };

    return (
        <NomineeListView
            {...props}
            {...nomineeListState}
            setSelectedNominee={setSelectedNominee}
            selectedOption={selectedOption}
            handleCloseDeleteModal={handleCloseDeleteModal}
            handleOpenDeleteModal={handleOpenDeleteModal}
            handleRemoveNominee={handleRemoveNominee}
            clearSelectedNominee={clearSelectedNominee}

            handleOpenCreateModal={handleOpenCreateModal}
            handleCloseCreateModal={handleCloseCreateModal}
            handleOpenFinishDialog={handleOpenFinishDialog}
            handleCloseFinishDialog={handleCloseFinishDialog}
            redirectToDetails={redirectToDetails}
            handleOpenEditModal={handleOpenEditModal}
            getListing={getListing}
            setNomineeOpted={setNomineeOpted}
            openDeleteAllNomineeDialog={openDeleteAllNomineeDialog}
            closeDeleteAllNomineeDialog={closeDeleteAllNomineeDialog}

        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    accountId: state.onboardingFlowState.accountId,
    onboardingObj: state.onboardingFlowState.onboardingObj,
});

export default connect(mapStateToProps, {})(NomineeList);