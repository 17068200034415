import React, {useEffect, useState} from 'react';
import {getOnboardingCategory, resetOnboardingListingFilters, setOnboardingCategory} from "../../../HomePageActions";
import DashboardWidget from "../../../DashboardWidget";
import {connect} from "react-redux";

const OnboardingCategory=(props)=> {
    const [onboardingCategoryState, setOnboardingCategoryState] = useState({
        categoryData: {},
        isShowLoader: false,
    });

    useEffect(() => {
        getDetails();
    }, [props.isRefreshButtonLoader]);

    const getDetails = () => {
        const {profile} = props;
        const {advisoryId} = profile;

        setOnboardingCategoryState(prevState => ({
            ...prevState,
            isShowLoader: true
        }));

        getOnboardingCategory(advisoryId).then(res => {
            if (res?.success) {
                setOnboardingCategoryState(prevState => ({
                    ...prevState,
                    categoryData: res.data,
                    isShowLoader: false,
                }));
            } else {
                setOnboardingCategoryState(prevState => ({
                    ...prevState,
                    isShowLoader: false,
                }));
            }
        })
    };

    const setFilters = (item) => {
        resetOnboardingListingFilters();
        setOnboardingCategory([item.key]);
        props.history.push('/onboardings')
    };


    return (
        <DashboardWidget
            {...props}
            {...onboardingCategoryState}
            detailsObj={onboardingCategoryState.categoryData}
            setFilters={setFilters}
            title={"Summary by Category"}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isXSView: state.appState.deviceInfo.isXSView,
});
export default connect(mapStateToProps, {})(OnboardingCategory);
