import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import {
    getApplicableIncomeOptions,
    getCashFlowOptions,
    getCategoryOptions,
    getDecisionApproachOptions,
    getFinancialApproachOptions,
    getFinancialProfileData,
    getPersonalityTraitOptions,
    getProfileOptions,
    getRiskProfileTypeOptions,
    getSpendingHabitsOptions,
    getSpentOnInsuranceOptions,
    getSpentOnRealEstateOptions,
    updateFinancialProfileData
} from '../../OnboardingActions';

import FinancialProfileView from './FinancialProfileView';

import {isEmpty, isEmptyNumeric} from "../../../utils/validations";
import {setValuesForDropdown} from "../../OnboardingHelper";
import store from "../../../store";
import {showToastMessage} from "../../../AppComponents/AppActions";


const FinancialProfile = (props) => {

    const [financialProfileState, setFinancialProfileState] = useState({
        accountInvestment: {
            objective: '',
            initialInvestmentAmount: '',
            initialSIPAmount: ''
        },
        accountGoal: {
            longTermGoal: '',
            midTermGoal: '',
            shortTermGoal: '',
        },
        accountFinancial: {
            primaryHolderIncomeCode: '',
            primaryHolderLiabilities: '',
            primaryHolderExpectedRetirementYear: '',
            primaryHolderMonthlyRequirementPostRetirement: ''
        },
        accountCategorization: {
            categoryId: '',
            riskProfileId: '',
            maximumAmount: '',
            financialProfileId: '',
            cashflowTypeId: '',
            spendingHabitId: '',
            realEstateToNetworthRatioId: '',
            insuranceToIncomeRatioId: '',
            decisionMakingStyleId: '',
            investmentStyleId: '',
            moneyManagementStyleId: '',
            detailOrientation: null,  //detailOrientation
            CRMNote: ''
        },

        errors: {
            accountInvestment: {
                objective: '',
                initialInvestmentAmount: '',
                initialSIPAmount: '',
            },
            accountGoal: {
                longTermGoal: '',
                midTermGoal: '',
                shortTermGoal: '',
            },
            accountFinancial: {
                primaryHolderIncomeCode: '',
                primaryHolderLiabilities: '',
                primaryHolderExpectedRetirementYear: '',
                primaryHolderMonthlyRequirementPostRetirement: '',
            },
            accountCategorization: {
                categoryId: '',
                riskProfileId: '',
                maximumAmount: '',
                financialProfileId: '',
                cashflowTypeId: '',
                spendingHabitId: '',
                realEstateToNetworthRatioId: '',
                insuranceToIncomeRatioId: '',
                decisionMakingStyleId: '',
                investmentStyleId: '',
                moneyManagementStyleId: '',
                detailOrientation: '',
                CRMNote: '',
            },
        },

        dropdownValues: {
            riskProfiles: [],
            applicableIncome: [],
            categories: [],
            yearsForRetirement: [],
            industries: [],
            politicallyExposedValues: [],
            emailPhoneBelongsTo: [],
            profiles: [],
            personalityTraits: [],
            decisionApproaches: [],
            financialApproaches: [],
            spentOnRealEstateOptions: [],
            spentOnInsuranceOptions: [],
            cashFlowOptions: [],
            spendingHabitsOptions: [],
        },
        responseErrors: [],
        totalErrors: 0,
        scrollToFieldId: '',
        isLoaderVisible: false
    });

    useEffect(() => {
        getMasterData()
    }, []);

    const getMasterData = () => {
        const {profile, accountId} = props;
        let advisoryId = profile?.advisoryId;
        setFinancialProfileState(prevState => ({
            ...prevState,
            isLoaderVisible: true
        }));

        getRiskProfileTypeOptions(advisoryId)
            .then(riskProfilesRes => {
                if (riskProfilesRes?.success) {
                    const riskProfilesData = riskProfilesRes?.data?.riskProfileType;

                    getCategoryOptions(advisoryId)
                        .then(categoryRes => {
                            if (categoryRes.success) {
                                const categoryData = categoryRes?.data?.categoryType;

                                getApplicableIncomeOptions(advisoryId)
                                    .then(applicableIncomeRes => {
                                        if (applicableIncomeRes?.success) {
                                            let applicableIncomeData = applicableIncomeRes?.data?.applicableIncome;
                                            applicableIncomeData = applicableIncomeData.sort((a, b) => a.code - b.code);
                                            getProfileOptions(advisoryId)
                                                .then(profileRes => {
                                                    if (profileRes?.success) {
                                                        let profileData = profileRes?.data?.financialProfile;
                                                        const years = getYearsForRetirement();


                                                        getPersonalityTraitOptions(advisoryId)
                                                            .then(personalityRes => {
                                                                if (personalityRes?.success) {
                                                                    let personalityTrait = personalityRes?.data?.decisionMakingStyle;

                                                                    getDecisionApproachOptions(advisoryId)
                                                                        .then(decisionApproachRes => {
                                                                            if (decisionApproachRes?.success) {
                                                                                let decisionApproach = decisionApproachRes?.data?.investmentStyle;

                                                                                getFinancialApproachOptions(advisoryId)
                                                                                    .then(financialApproachRes => {
                                                                                        if (financialApproachRes?.success) {
                                                                                            let financialApproach = financialApproachRes?.data?.moneyManagementStyle;

                                                                                            getSpentOnRealEstateOptions(advisoryId)
                                                                                                .then(realEstateRes => {
                                                                                                    if (realEstateRes?.success) {
                                                                                                        let spentOnRealEstates = realEstateRes?.data?.realEstateToNetworthRatio;

                                                                                                        getSpentOnInsuranceOptions(advisoryId)
                                                                                                            .then(insuranceRes => {
                                                                                                                if (insuranceRes?.success) {
                                                                                                                    let spentOnInsurances = insuranceRes?.data?.insuranceToIncomeRatio;

                                                                                                                    getCashFlowOptions(advisoryId)
                                                                                                                        .then(cashFlowRes => {
                                                                                                                            if (cashFlowRes?.success) {
                                                                                                                                let cashFlowOptions = cashFlowRes?.data?.cashflowType;

                                                                                                                                getSpendingHabitsOptions(advisoryId)
                                                                                                                                    .then(spendingHabitRes => {
                                                                                                                                        if (spendingHabitRes?.success) {
                                                                                                                                            let spendingHabitOptions = spendingHabitRes?.data?.spendingHabit;

                                                                                                                                            const dropDowns = {
                                                                                                                                                riskProfiles: setValuesForRiskProfileDropdown(riskProfilesData, 'name', 'id'),
                                                                                                                                                categories: setValuesForDropdown(categoryData, 'name', 'id'),
                                                                                                                                                profiles: setValuesForDropdown(profileData, 'name', 'id'),
                                                                                                                                                applicableIncome: setValuesForDropdown(applicableIncomeData, 'description', 'code'),
                                                                                                                                                yearsForRetirement: setValuesForDropdown(years, 'label', 'value'),
                                                                                                                                                personalityTraits: setValuesForDropdown(personalityTrait, 'name', 'id'),
                                                                                                                                                decisionApproaches: setValuesForDropdown(decisionApproach, 'name', 'id'),
                                                                                                                                                financialApproaches: setValuesForDropdown(financialApproach, 'name', 'id'),
                                                                                                                                                spentOnRealEstateOptions: setValuesForDropdown(spentOnRealEstates, 'name', 'id'),
                                                                                                                                                spentOnInsuranceOptions: setValuesForDropdown(spentOnInsurances, 'name', 'id'),
                                                                                                                                                cashFlowOptions: setValuesForDropdown(cashFlowOptions, 'name', 'id'),
                                                                                                                                                spendingHabitsOptions: setValuesForDropdown(spendingHabitOptions, 'name', 'id'),
                                                                                                                                            };

                                                                                                                                            getFinancialProfileData(accountId, advisoryId)
                                                                                                                                                .then(fpResp => {
                                                                                                                                                    if (fpResp?.success) {
                                                                                                                                                        const updatedFPData = getMappedData(fpResp.data, dropDowns);

                                                                                                                                                        setFinancialProfileState(prevState => ({
                                                                                                                                                            ...prevState,
                                                                                                                                                            ...updatedFPData,
                                                                                                                                                            dropdownValues: dropDowns,
                                                                                                                                                            isLoaderVisible: false
                                                                                                                                                        }));

                                                                                                                                                    } else {
                                                                                                                                                        setFinancialProfileState(prevState => ({
                                                                                                                                                            ...prevState,
                                                                                                                                                            isLoaderVisible: false
                                                                                                                                                        }));
                                                                                                                                                    }
                                                                                                                                                });


                                                                                                                                        }})

                                                                                                                            }})

                                                                                                                }
                                                                                                            })

                                                                                                    }
                                                                                                })

                                                                                        }
                                                                                    })

                                                                            }
                                                                        })


                                                                }
                                                            })

                                                    }
                                                });
                                        }
                                    });
                            }
                        })
                }
            });
    };

    const setValuesForRiskProfileDropdown = (data, labelToken, valueToken) => {
        if (isEmpty(data)) {
            return;
        }
        let obj = [];

        obj = data.map((item) => {
            return {label: item?.[labelToken], value: item?.[valueToken], description: item?.description}
        });
        return obj
    };

    const getRiskProfile = (name) =>{
        const{dropdownValues} = financialProfileState;
        const{riskProfiles} = dropdownValues;
        if(isEmpty(name)){
            return;
        }
        let obj = riskProfiles.find((item)=>{
            return item.value === name;
        })

        return obj.label;
    }

    //GET CALL MAPPING
    const getMappedData = (response, dropDownValues) => {
        const {errors, accountInvestment, accountGoal, accountFinancial, accountCategorization} = financialProfileState;
        const {isFromOnboardingFlow} = props;

        if (isEmpty(response)) {
            let errorsObj = {
                ...errors,
                accountInvestment: isFromOnboardingFlow ? errors.accountInvestment : validateAccountInvestment({
                    accountInvestment: accountInvestment,
                    errors: errors.accountInvestment
                }, 'SUBMIT'),
                accountGoal: isFromOnboardingFlow ? errors.accountGoal : validateAccountGoal({
                    accountGoal: accountGoal,
                    errors: errors.accountGoal
                }, 'SUBMIT'),
                accountFinancial: isFromOnboardingFlow ? errors.accountFinancial : validateAccountFinancial({
                    accountFinancial: accountFinancial,
                    errors: errors.accountFinancial
                }, 'SUBMIT'),
                accountCategorization: isFromOnboardingFlow ? errors.accountCategorization : validateAccountCategorization({
                    accountCategorization: accountCategorization,
                    errors: errors.accountCategorization
                }, 'SUBMIT')
            };
            return {
                errors: errorsObj,
                scrollToFieldId: getFirstErrorFieldId(errorsObj),
            }
        }
        let accountInvestmentObj = mapAccountInvestment(response.accountInvestment);
        let accountGoalObj = mapAccountGoal(response.accountGoal);
        let accountFinancialObj = mapAccountFinancial(response.accountFinancial, dropDownValues);
        let accountCategorizationObj = mapAccountCategorization(response.accountCategorization);
        let errorsObj = {
            ...errors,
            accountInvestment: isFromOnboardingFlow ? errors.accountInvestment : validateAccountInvestment({
                accountInvestment: accountInvestmentObj,
                errors: errors.accountInvestment
            }, 'SUBMIT'),
            accountGoal: isFromOnboardingFlow ? errors.accountGoal : validateAccountGoal({
                accountGoal: accountGoalObj,
                errors: errors.accountGoal
            }, 'SUBMIT'),
            accountFinancial: isFromOnboardingFlow ? errors.accountFinancial : validateAccountFinancial({
                accountFinancial: accountFinancialObj,
                errors: errors.accountFinancial
            }, 'SUBMIT'),
            accountCategorization: isFromOnboardingFlow ? errors.accountCategorization : validateAccountCategorization({
                accountCategorization: accountCategorizationObj,
                errors: errors.accountCategorization
            }, 'SUBMIT')
        };
        return {
            errors: errorsObj,
            scrollToFieldId: getFirstErrorFieldId(errorsObj),
            accountInvestment: accountInvestmentObj,
            accountGoal: accountGoalObj,
            accountFinancial: accountFinancialObj,
            accountCategorization: accountCategorizationObj,
        };
    };

    const mapAccountInvestment = (accountInvestment) => {
        return {
            objective: isEmpty(accountInvestment.objective) ? '' : accountInvestment?.objective,
            initialInvestmentAmount: (isEmptyNumeric(accountInvestment.initialInvestmentAmount)) ? '' : accountInvestment.initialInvestmentAmount,
            initialSIPAmount: isEmptyNumeric(accountInvestment.initialSIPAmount) ? '' : accountInvestment.initialSIPAmount
        };
    };

    const mapAccountGoal = (accountGoal) => {
        return {
            longTermGoal: isEmpty(accountGoal.longTermGoal) ? '' : accountGoal.longTermGoal,
            midTermGoal: isEmpty(accountGoal.midTermGoal) ? '' : accountGoal.midTermGoal,
            shortTermGoal: isEmpty(accountGoal.shortTermGoal) ? '' : accountGoal.shortTermGoal
        };
    };

    const mapAccountFinancial = (accountFinancial) => {
        return {
            primaryHolderIncomeCode: isEmpty(accountFinancial.primaryHolderIncomeCode) ? '' : accountFinancial.primaryHolderIncomeCode,
            primaryHolderLiabilities: isEmpty(accountFinancial.primaryHolderLiabilities) ? '' : accountFinancial.primaryHolderLiabilities,
            primaryHolderExpectedRetirementYear: isEmpty(accountFinancial.primaryHolderExpectedRetirementYear) ? '' : accountFinancial.primaryHolderExpectedRetirementYear,
            primaryHolderMonthlyRequirementPostRetirement: isEmpty(accountFinancial.primaryHolderMonthlyRequirementPostRetirement) ? '' : accountFinancial.primaryHolderMonthlyRequirementPostRetirement
        };
    };

    const mapAccountCategorization = (accountCategorization) => {
        return {
            maximumAmount: isEmpty(accountCategorization.maximumAmount) ? '' : accountCategorization.maximumAmount,
            CRMNote: isEmpty(accountCategorization.CRMNote) ? '' : accountCategorization.CRMNote,
            categoryId: isEmpty(accountCategorization.categoryId) ? '' : accountCategorization.categoryId,
            riskProfileId: isEmpty(accountCategorization.riskProfileId) ? '' : accountCategorization.riskProfileId,
            financialProfileId: isEmpty(accountCategorization.financialProfileId) ? '' : accountCategorization.financialProfileId,
            cashflowTypeId: isEmpty(accountCategorization.cashflowTypeId) ? '' : accountCategorization.cashflowTypeId,
            spendingHabitId: isEmpty(accountCategorization.spendingHabitId) ? '' : accountCategorization.spendingHabitId,
            decisionMakingStyleId: isEmpty(accountCategorization.decisionMakingStyleId) ? '' : accountCategorization.decisionMakingStyleId,
            investmentStyleId: isEmpty(accountCategorization.investmentStyleId) ? '' : accountCategorization.investmentStyleId,
            moneyManagementStyleId: isEmpty(accountCategorization.moneyManagementStyleId) ? '' : accountCategorization.moneyManagementStyleId,
            detailOrientation: isEmpty(accountCategorization.detailOrientation) ? null : accountCategorization.detailOrientation,
            realEstateToNetworthRatioId: isEmpty(accountCategorization.realEstateToNetworthRatioId) ? '' : accountCategorization.realEstateToNetworthRatioId,
            insuranceToIncomeRatioId: isEmpty(accountCategorization.insuranceToIncomeRatioId) ? '' : accountCategorization.insuranceToIncomeRatioId

        };
    };

    const getYearsForRetirement = () => {
        const startYear = moment().year();
        const endYear = moment().add(80, 'years').format('YYYY');
        const retirementYears = [];

        for (let year = startYear; year <= endYear; year++) {
            retirementYears.push({label: year + '', value: year + ''});
        }

        return retirementYears;
    };

    const validateFinancialProfile = () => {
        const {handleClickNext} = props;
        //    validate profile and submit form
        handleClickNext()
    };

    const checkIfValidateObj = () => {
        const {
            maximumAmount, categoryId, riskProfileId, financialProfileId, cashflowTypeId, spendingHabitId,
            investmentStyleId, decisionMakingStyleId, moneyManagementStyleId, realEstateToNetworthRatioId, insuranceToIncomeRatioId,
            CRMNote} = financialProfileState.accountCategorization;
        const {primaryHolderIncomeCode} = financialProfileState.accountFinancial;

        if (categoryId && riskProfileId && maximumAmount && primaryHolderIncomeCode && financialProfileId && cashflowTypeId && spendingHabitId
            && investmentStyleId && decisionMakingStyleId && moneyManagementStyleId && realEstateToNetworthRatioId && insuranceToIncomeRatioId && CRMNote) {
            return true;
        }
        return false;
    };

    const onNextClick = () => {
        const {profile, isProceedWithErrors, handleClickNext, accountId} = props;

        setFinancialProfileState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true
        }));

        const validateObject = validateChanges(financialProfileState, 'SUBMIT');

        let updatedData = {...financialProfileState};

        // updatedData.personId = 10;
        updatedData.accountId = accountId;
        delete updatedData.errors;
        delete updatedData.dropdownValues;

        let errorId = '';

        updateFinancialProfileData(updatedData, profile?.advisoryId)
            .then(res => {

                if (res.success) {
                    if (isProceedWithErrors) {
                        handleClickNext();
                        setFinancialProfileState(prevStep => ({
                            ...prevStep,
                            isLoaderVisible: false
                        }));
                        return;
                    }
                    if (isEmpty(res.data?.validationErrors) && checkIfValidateObj()) {
                        setFinancialProfileState(prevStep => ({
                            ...prevStep,
                            isLoaderVisible: false
                        }));
                        handleClickNext()
                    } else {
                        assignResponseError(res.data, validateObject)
                    }
                } else {
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                        errorId = 'page-level-errors'
                    }
                    setFinancialProfileState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                            ...validateObject,
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        scrollToFieldId: errorId,
                        isLoaderVisible: false
                    }));
                }
            })
    };

    const assignResponseError = (response, stateErrors) => {
        const errorResponse = response?.validationErrors;

        let newStateErrors = {...stateErrors};
        let responseErrors = [];

        if (isEmpty(errorResponse)) {
            //handled state if response status is failed

            setFinancialProfileState(prevStep => ({
                ...prevStep,
                isLoaderVisible: false,
                errors: {
                    ...prevStep.errors,
                    ...newStateErrors,
                },
                scrollToFieldId: getFirstErrorFieldId(stateErrors),
                responseErrors: [],
                totalErrors: 0,
            }));
            return;
        }

        errorResponse.forEach((error) => { //assign each field by section name
            const section = error.section;
            if (!newStateErrors[section]) {
                return;
            }
            if ((error.fields).length > 1) { //add page level validation error
                responseErrors.push(error.message)
            } else {
                //assign each field error to respected field and log if invalid field is found
                error.fields.forEach(field => {
                    if (!isEmpty(newStateErrors[section][field])) {
                        console.log(`Field ${field} in section ${section} not found`);
                    } else {
                        newStateErrors[section][field] = error.message;
                    }
                });
            }
        });

        let errors = {
            ...financialProfileState.errors,
            ...newStateErrors,
        };

        setFinancialProfileState(prevStep => ({
            ...prevStep,
            errors: errors,
            responseErrors: responseErrors,
            totalErrors: responseErrors.length,
            scrollToFieldId: isEmpty(responseErrors) ? getFirstErrorFieldId(errors) : 'page-level-errors',
            isLoaderVisible: false
        }));
    };

    const getFirstErrorFieldId = (errors) => {
        let sectionOrderToSort = ['accountInvestment', 'accountGoal', 'accountFinancial', 'accountCategorization'];

        for (const section of sectionOrderToSort) {
            if (errors[section]) {
                for (const key in errors[section]) {

                    const error = errors[section][key];
                    if (error && typeof error === 'object' && 'label' in error && error.label !== 'type') {
                        return section + '-' + error.label + '-id';
                    }
                    if (typeof error === 'string' && error && key !== 'type' && key !== 'parentType') {
                        return section + '-' + key + '-id';
                    }
                }
            }
        }
        return 'investment';
    };

    const handleChange = (name, value, block) => {
        let updatedObj = {...financialProfileState};
        if (block === 'accountInvestment') {
            updatedObj.accountInvestment[name] = value
        } else if (block === 'accountGoal') {
            updatedObj.accountGoal[name] = value
        } else if (block === 'accountFinancial') {
            updatedObj.accountFinancial[name] = value
        } else if (block === 'accountCategorization') {
            updatedObj.accountCategorization[name] = value
        }

        const validateObject = validateChanges(updatedObj, name);

        setFinancialProfileState(prevStep => ({
            ...prevStep,
            ...updatedObj,
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));

    };

    const validateAccountInvestment = (updatedObj, name) => {
        const {objective, initialInvestmentAmount, initialSIPAmount} = updatedObj.accountInvestment;
        let errorObj = {...financialProfileState.errors.accountInvestment};

        // Validation for 'objective'
        if (name === 'objective' || name === 'SUBMIT') {
            if (objective.length > 300) {
                errorObj.objective = 'Please enter 300 characters only';
            } else {
                errorObj.objective = '';
            }
            if (name === 'objective') {
                return errorObj;
            }
        }

        // Validation for 'initialInvestmentAmount'
        if (name === 'initialInvestmentAmount' || name === 'SUBMIT') {
            if (initialInvestmentAmount?.length > 10) {
                errorObj.initialInvestmentAmount = 'Please enter 10 numbers only';
            } else {
                errorObj.initialInvestmentAmount = '';
            }
            if (name === 'initialInvestmentAmount') {
                return errorObj;
            }
        }

        // Validation for 'initialSIPAmount'
        if (name === 'initialSIPAmount' || name === 'SUBMIT') {
            if (initialSIPAmount?.length > 10) {
                errorObj.initialSIPAmount = 'Please enter 10 numbers only';
            } else {
                errorObj.initialSIPAmount = '';
            }
            if (name === 'initialSIPAmount') {
                return errorObj;
            }
        }

        return errorObj;
    };

    const validateAccountGoal = (updatedObj, name) => {
        const {shortTermGoal, midTermGoal, longTermGoal} = updatedObj.accountGoal;
        let errorObj = {...financialProfileState.errors.accountGoal};

        // Validation for 'shortTermGoal'
        if (name === 'shortTermGoal' || name === 'SUBMIT') {
            if (shortTermGoal.length > 300) {
                errorObj.shortTermGoal = 'Please enter 300 characters only';
            } else {
                errorObj.shortTermGoal = '';
            }
            if (name === 'shortTermGoal') {
                return errorObj;
            }
        }

        // Validation for 'midTermGoal'
        if (name === 'midTermGoal' || name === 'SUBMIT') {
            if (midTermGoal.length > 300) {
                errorObj.midTermGoal = 'Please enter 300 characters only';
            } else {
                errorObj.midTermGoal = '';
            }
            if (name === 'midTermGoal') {
                return errorObj;
            }
        }

        // Validation for 'longTermGoal'
        if (name === 'longTermGoal' || name === 'SUBMIT') {
            if (longTermGoal.length > 300) {
                errorObj.longTermGoal = 'Please enter 300 characters only';
            } else {
                errorObj.longTermGoal = '';
            }
            if (name === 'longTermGoal') {
                return errorObj;
            }
        }

        return errorObj;
    };

    const validateAccountFinancial = (updatedObj, name) => {
        const {primaryHolderLiabilities, primaryHolderMonthlyRequirementPostRetirement, primaryHolderIncomeCode} = updatedObj.accountFinancial;
        let errorObj = {...financialProfileState.errors.accountFinancial};

        if (name === 'primaryHolderIncomeCode' || name === 'SUBMIT') {
            if (isEmpty(primaryHolderIncomeCode)) {
                errorObj.primaryHolderIncomeCode = 'Please select primary holder income';
            } else {
                errorObj.primaryHolderIncomeCode = '';
            }
            if (name === 'primaryHolderIncomeCode') {
                return errorObj;
            }
        }
        // Validation for 'liabilities'
        if (name === 'primaryHolderLiabilities' || name === 'SUBMIT') {
            if (primaryHolderLiabilities.length > 51) {
                errorObj.primaryHolderLiabilities = 'Please enter 50 characters only';
            } else {
                errorObj.primaryHolderLiabilities = '';
            }
            if (name === 'primaryHolderLiabilities') {
                return errorObj;
            }
        }

        // Validation for 'monthlyRequirementPostRetirement'
        if (name === 'primaryHolderMonthlyRequirementPostRetirement' || name === 'SUBMIT') {
            if (primaryHolderMonthlyRequirementPostRetirement?.length > 10) {
                errorObj.primaryHolderMonthlyRequirementPostRetirement = 'Please enter 10 numbers only';
            } else {
                errorObj.primaryHolderMonthlyRequirementPostRetirement = '';
            }
            if (name === 'primaryHolderMonthlyRequirementPostRetirement') {
                return errorObj;
            }
        }

        return errorObj;
    };

    const validateAccountCategorization = (updatedObj, name) => {
        const {
            CRMNote, maximumAmount, categoryId, riskProfileId, financialProfileId, cashflowTypeId, spendingHabitId,
            investmentStyleId, decisionMakingStyleId, moneyManagementStyleId, detailOrientation, realEstateToNetworthRatioId,
            insuranceToIncomeRatioId
        } = updatedObj.accountCategorization;
        let errorObj = {...financialProfileState.errors.accountCategorization};

        // Validation for 'maximumAmount'
        if (name === 'maximumAmount' || name === 'SUBMIT') {
            if (isEmpty(maximumAmount)) {
                errorObj.maximumAmount = 'Please enter maximum amount';
            } else if (maximumAmount.length > 12) {
                errorObj.maximumAmount = 'Please enter 12 numbers only';
            } else {
                errorObj.maximumAmount = '';
            }
            if (name === 'maximumAmount') {
                return errorObj;
            }
        }

        // Validation for 'categoryId'
        if (name === 'categoryId' || name === 'SUBMIT') {
            if (isEmpty(categoryId)) {
                errorObj.categoryId = 'Please select category';
            } else {
                errorObj.categoryId = '';
            }
            if (name === 'categoryId') {
                return errorObj;
            }
        }

        // Validation for 'riskProfileId'
        if (name === 'riskProfileId' || name === 'SUBMIT') {
            if (isEmpty(riskProfileId)) {
                errorObj.riskProfileId = 'Please select risk Profile';
            } else {
                errorObj.riskProfileId = '';
            }
            if (name === 'riskProfileId') {
                return errorObj;
            }
        }

        if (name === 'financialProfileId' || name === 'SUBMIT') {
            if (isEmpty(financialProfileId)) {
                errorObj.financialProfileId = 'Please select profile';
            } else {
                errorObj.financialProfileId = '';
            }
            if (name === 'financialProfileId') {
                return errorObj;
            }
        }

        if (name === 'cashflowTypeId' || name === 'SUBMIT') {
            if (isEmpty(cashflowTypeId)) {
                errorObj.cashflowTypeId = 'Please select cashflow';
            } else {
                errorObj.cashflowTypeId = '';
            }
            if (name === 'cashflowTypeId') {
                return errorObj;
            }
        }

        if (name === 'spendingHabitId' || name === 'SUBMIT') {
            if (isEmpty(spendingHabitId)) {
                errorObj.spendingHabitId = 'Please select spending habit';
            } else {
                errorObj.spendingHabitId = '';
            }
            if (name === 'spendingHabitId') {
                return errorObj;
            }
        }

        if (name === 'decisionMakingStyleId' || name === 'SUBMIT') {
            if (isEmpty(decisionMakingStyleId)) {
                errorObj.decisionMakingStyleId = 'Please select decision making style';
            } else {
                errorObj.decisionMakingStyleId = '';
            }
            if (name === 'decisionMakingStyleId') {
                return errorObj;
            }
        }

        if (name === 'investmentStyleId' || name === 'SUBMIT') {
            if (isEmpty(investmentStyleId)) {
                errorObj.investmentStyleId = 'Please select investment style';
            } else {
                errorObj.investmentStyleId = '';
            }
            if (name === 'investmentStyleId') {
                return errorObj;
            }
        }

        if (name === 'moneyManagementStyleId' || name === 'SUBMIT') {
            if (isEmpty(moneyManagementStyleId)) {
                errorObj.moneyManagementStyleId = 'Please select money management style';
            } else {
                errorObj.moneyManagementStyleId = '';
            }
            if (name === 'moneyManagementStyleId') {
                return errorObj;
            }
        }

        if (name === 'detailOrientation' || name === 'SUBMIT') {
            if (isEmpty(detailOrientation)) {
                errorObj.detailOrientation = 'Please select detail oriented';
            } else {
                errorObj.detailOrientation = '';
            }
            if (name === 'detailOrientation') {
                return errorObj;
            }
        }

        if (name === 'realEstateToNetworthRatioId' || name === 'SUBMIT') {
            if (isEmpty(realEstateToNetworthRatioId)) {
                errorObj.realEstateToNetworthRatioId = 'Please select real estate to net worth ratio';
            } else {
                errorObj.realEstateToNetworthRatioId = '';
            }
            if (name === 'realEstateToNetworthRatioId') {
                return errorObj;
            }
        }

        if (name === 'insuranceToIncomeRatioId' || name === 'SUBMIT') {
            if (isEmpty(insuranceToIncomeRatioId)) {
                errorObj.insuranceToIncomeRatioId = 'Please select insurance to income ratio';
            } else {
                errorObj.insuranceToIncomeRatioId = '';
            }
            if (name === 'insuranceToIncomeRatioId') {
                return errorObj;
            }
        }

        // Validation for 'clientNotes'
        if (name === 'CRMNote' || name === 'SUBMIT') {
            if (isEmpty(CRMNote)) {
                errorObj.CRMNote = 'Please enter client notes';
            }else if (CRMNote.length > 300) {
                errorObj.CRMNote = 'Please enter 300 characters only';
            } else {
                errorObj.CRMNote = '';
            }
            if (name === 'CRMNote') {
                return errorObj;
            }
        }

        return errorObj;
    };

    const validateChanges = (updatedObj, name) => {
        let errorObj = {...financialProfileState.errors};
        errorObj.accountInvestment = validateAccountInvestment(updatedObj, name,);
        errorObj.accountGoal = validateAccountGoal(updatedObj, name);
        errorObj.accountFinancial = validateAccountFinancial(updatedObj, name);
        errorObj.accountCategorization = validateAccountCategorization(updatedObj, name);
        return errorObj;
    };

    const onSaveAndCloseClick = () => {
        const {profile, accountId, handleClickSaveAndClose, saveFailureErrorMessage} = props;

        setFinancialProfileState(prevStep => ({
            ...prevStep,
            isLoaderVisible: true
        }));

        const validateObject = validateChanges(financialProfileState, 'SUBMIT');

        let updatedData = {...financialProfileState};

        updatedData.accountId = accountId;
        delete updatedData.errors;
        delete updatedData.dropdownValues;

        let errorId = '';

        updateFinancialProfileData(updatedData, profile?.advisoryId)
            .then(res => {
                if (res.success) {
                    handleClickSaveAndClose()
                } else {
                    store.dispatch(showToastMessage('warning', saveFailureErrorMessage));
                    let responseErrors = [];
                    if (res?.__error) {
                        responseErrors.push(res?.__error);
                        errorId = 'page-level-errors'
                    }
                    setFinancialProfileState(prevStep => ({
                        ...prevStep,
                        errors: {
                            ...prevStep.errors,
                            ...validateObject,
                        },
                        responseErrors: responseErrors,
                        totalErrors: responseErrors.length,
                        scrollToFieldId: errorId,
                        isLoaderVisible: false
                    }));
                }
            })
    };

    return (
        <FinancialProfileView
            {...props}
            {...financialProfileState}
            handleChange={handleChange}
            validateFinancialProfile={validateFinancialProfile}
            onSaveAndCloseClick={onSaveAndCloseClick}
            onNextClick={onNextClick}
            getRiskProfile={getRiskProfile}
        />
    );
};


const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
    accountId: state.onboardingFlowState.accountId
});

export default connect(mapStateToProps, {})(FinancialProfile);

