import React, {useState} from 'react';
import {APP_CONST} from "../../../AppComponents/AppConstants";
import AddRemarkView from "./AddRemarkView";
import {isEmpty} from "../../../utils/validations";
import {leadAddRemark} from "../../LeadsActions";
import {connect} from "react-redux";

const AddRemark = (props) => {
    const [addRemarkState, setAddRemarkState] = useState({
        leadId: "",
        remark: "",
        isShowLoader: false,
        responseError: '',
        errors: {
            remarkError: ''
        }
    });

    const handleChange = (name, value) => {
        let updatedState = {...addRemarkState};
        updatedState[name] = value;

        const validateObject = validateChanges(name, updatedState);

        setAddRemarkState(prevStep => ({
            ...prevStep,
            ...updatedState,
            responseError: '',
            errors: {
                ...prevStep.errors,
                ...validateObject
            },
        }));
    };

    const validateChanges = (name, compState) => {
        const {remark} = compState;
        let errorObj = {...addRemarkState.errors};

        if (name === "remark" || name === APP_CONST.FORM_SUBMISSION) {

            if (isEmpty(remark)) {
                errorObj.remarkError = "Please enter remark";
            } else if (remark.length > 500) {
                errorObj.remarkError = "Maximum 500 characters allowed"
            }else {
                errorObj.remarkError = "";
            }

            if (name === 'remark') {
                return errorObj;
            }
        }

        return errorObj;
    };

    const handleSubmit = () => {
        const {profile, leadId, handleCloseAddRemarkModal,handleHistoryBlockRefresh} = props;
        let advisoryId = profile?.advisoryId;
        const validateObj = validateChanges(APP_CONST.FORM_SUBMISSION, addRemarkState);

        if (!isEmpty(validateObj?.remarkError)) {
            setAddRemarkState(prevStep => ({
                ...prevStep,
                errors: {
                    ...prevStep.errors,
                    ...validateObj
                }
            }));
            return;
        }
        setAddRemarkState((preState) => ({
                ...preState,
                isShowLoader: true,
            }
        ));

        let payload = {
            leadRequestId: leadId,
            remark: addRemarkState.remark,
        };

        leadAddRemark(payload, advisoryId).then(res => {
            if (res?.success) {
                handleHistoryBlockRefresh(true);
                setAddRemarkState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                }));
                handleCloseAddRemarkModal()
            } else {
                setAddRemarkState((prevStep) => ({
                    ...prevStep,
                    isShowLoader: false,
                    responseError: res.__error
                }));
            }
        })
    };

    return (
        <AddRemarkView
            {...props}
            {...addRemarkState}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
        />
    );
};

const mapStateToProps = (state) => ({
    isMobileView: state.appState.deviceInfo.isMobileView,
    profile: state.userState.profile,
    isMDView: state.appState.deviceInfo.isMDView,
    isLGView: state.appState.deviceInfo.isLGView,
    isXLView: state.appState.deviceInfo.isXLView,
    isSMView: state.appState.deviceInfo.isSMView,
    isXSView: state.appState.deviceInfo.isXSView,
});

export default connect(mapStateToProps, {})(AddRemark);
