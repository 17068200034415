import {getAuthorizationToken, httpGet, httpPost, httpPut} from "../../../utils/httpHelper";
import {getAppEnvironmentVariables} from "../../../utils/helperPlatform";

export const getFormAndReviewData = (id, advisoryId) => {
    return httpGet('/service/advisory/onboardingApplication/validate/errors?onboardingApplicationId=' + id, advisoryId)
        .then(res => {
            return res;
        })
};

export const validateFormAndReview = (data, advisoryId) => {
    return httpPost('/service/advisory/onboardingApplication/validate', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const getNSEStatusData = (id, advisoryId) => {
    return httpGet('/service/advisory/onboardingApplication/nse/activity/all?onboardingApplicationId=' + id, advisoryId)
        .then(res => {
            return res;
        })
};

export const submitData = (data, advisoryId) => {
    return httpPost('/service/advisory/onboardingApplication/nse/submit', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const regenerate = (data, advisoryId) => {
    return httpPost('/service/advisory/documentType/generateForms', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const markAsReview = (data, advisoryId) => {
    return httpPut('/service/advisory/form/markAsReview', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const eConsent = (data, advisoryId) => {
    return httpPut('/service/advisory/form', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const remark = (data, advisoryId) => {
    return httpPost('/service/advisory/form/remark', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const getIINFormData = (accountId, advisoryId) => {
    return httpGet('/service/advisory/form/accountForm/all?accountId=' + accountId, advisoryId)
        .then(res => {
            return res;
        })
};

export const downloadAndViewForm = (fileName, filePath, advisoryId, action) => {

    let url = '/service/media/download?filePath=' + filePath;

    const {REACT_APP_API_URL} = getAppEnvironmentVariables();

    let API_URL = REACT_APP_API_URL + url;

    let cloneData, flag;
    fetch(API_URL, {
        method: "GET",
        headers: {
            'Accept': 'application/*',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + getAuthorizationToken().token
        },
        responseType: 'arraybuffer',
        dataType: 'blob'
    })

        .then(async (response) => {
            cloneData = response.clone();
            await response.json()
                .then(res => {
                    // store.dispatch(setAppLoader(false));
                    if (res?.message) {
                        store.dispatch(showToastMessage('warning', res?.message))
                    }
                    if (res?.__error) {
                        store.dispatch(showToastMessage('warning', res?.__error))
                    }
                    flag = false;
                })
                .catch(() => {
                    flag = true;
                });
        }).then(result => {
        if (flag) {
            cloneData.blob().then(blob => {
                if (blob) {
                    let url = window.URL.createObjectURL(blob);
                    let a = document.createElement("a");
                    a.href = url;
                    if (action === 'Download') {
                        a.download = fileName;
                        a.click();
                    }
                    if (action === 'View') {
                        window.open(url, 'SingleSecondaryWindowName');
                    }

                } else {
                    store.dispatch(showToastMessage('warning', result.message));
                }
            });
        }
    });
};

export const getGenerateForms = (data, advisoryId) => {
    return httpPost('/service/advisory/documentType/generateForms', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const getViewActivityData = (id, advisoryId) => {
    return httpGet('/service/advisory/form/activities?id=' + id, advisoryId)
        .then(res => {
            return res;
        })
};

export const updateEKyc = (data, advisoryId) => {
    return httpPut('/service/advisory/person/kyc', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const getGenerateLink = (data, advisoryId) => {
    return httpPost('/service/advisory/person/ekyc/generateLink', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const getKYCForm = (id, advisoryId) => {
    return httpGet('/service/advisory/form/personForm?personId=' + id, advisoryId)
        .then(res => {
            return res;
        })
};

export const FATKARetrigger = (data, advisoryId) => {
    return httpPost('/service/advisory/onboardingApplication/nse/fatca/confirmationLink/send', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const IINRetrigger = (data, advisoryId,onboardingApplicationId) => {
    return httpPost('/service/advisory/onboardingApplication/nse/iin/confirmationLink/send?onboardingApplicationId='+onboardingApplicationId, data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const FATKASendToClient = (data, advisoryId) => {
    return httpPost('/service/advisory/onboardingApplication/nse/fatca/confirmationLink/send', data, advisoryId, true)
        .then(res => {
            return res;
        })
};
export const IINSendToClient = (data, advisoryId) => {
    return httpPost('/service/advisory/onboardingApplication/nse/iin/confirmationLink/send', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const getLeadHistoryData = (id, advisoryId) => {
    return httpGet('/service/advisory/lead/request/activities?leadRequestId=' + id, advisoryId)
        .then(res => {
            return res;
        })
};

export const getIINActivity = (id, advisoryId) => {
    return httpGet('/service/advisory/onboardingApplication/nse/iin/activity/all?onboardingApplicationId=' + id, advisoryId)
        .then(res => {
            return res;
        })
};

export const getBankMandateActivity = (id, advisoryId) => {
    return httpGet('/service/advisory/onboardingApplication/nse/ach/activity/all?onboardingApplicationId=' + id, advisoryId)
        .then(res => {
            return res;
        })
};

export const submitAcheData = (data, advisoryId) => {
    return httpPost('/service/advisory/onboardingApplication/nse/ach/submit', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const submitIINData = (data, advisoryId) => {
    return httpPost('/service/advisory/onboardingApplication/nse/iin/submit', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const sendToECSClient = (data, advisoryId) => {
    return httpPost('/service/advisory/onboardingApplication/nse/ecs/retrigger', data, advisoryId, true)
        .then(res => {
            return res;
        })
};

export const getAllBankChannelType = (advisoryId) => {
    return httpGet('/service/advisory/bank/channelType/all', advisoryId)
        .then(res => {
            return res
        })
};
export const setBankMandateChannelType = (data, advisoryId) => {
    return httpPut('/service/advisory/account/bankMandate/channelType', data, advisoryId, true)
        .then(res => {
            return res;
        })
};
