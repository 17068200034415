import React from 'react';

import {isEmpty} from "../../../../../utils/validations";
import appTheme from "../../../../../assets/appTheme";
import {getTimeFromNow, getTrimmedValue} from "../../../../../utils/helper";

import DLLoader from "../../../../../core/components/DLLoader/DLLoader";
import DLText from "../../../../../core/components/DLText/DLText";
import DLCheckbox from "../../../../../core/components/DLCheckbox/DLCheckbox";
import DLMenuOptions from "../../../../../core/components/DLMenuOptions/DLMenuOptions";

import Reviewed from "./reviewed/reviewed";
import AddRemark from "./addRemark/AddRemark";
import ViewActivity from "./viewActivity/ViewActivities";
import UploadBankMandate from "./upload/UploadBankMandate";
import BankMandateActivity from "./bankMandateActivity/bankMandateActivity"
import DLTooltip from "../../../../../core/components/DLTooltip/DLTooltip";
import DLButton from "../../../../../core/components/DLButton/DLButton";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import DLRadioGroup from "../../../../../core/components/DLRadioGroup/DLRadioGroup";


const BankMandate = (props) => {
    const {isShowLoader, regenerateForm, handleOpenMarkAsReviewCheckBox, fileName, filePath, isReviewed,setEscFormSubmitStatus,
        selectedOption, downloadAndViewDocument, escFormSubmitStatus, IINNumber,setActivityStatus, isECSSubmitted, submitECS,
        resetECSSubmissionStatus, isManagementUser, formId, isMobileView, setBankMandateStatus, bankMandateStatus} = props;

    const renderMarkAsReviewed = () => {
        let isDisable = isReviewed || isEmpty(IINNumber) || isManagementUser;
        return (
            <div className={"me-2"}>
                <div className='data-label'
                     style={{minWidth: '50px'}}>
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={(!isReviewed && !isManagementUser) ? "primary" : "gray"}
                            text={'Reviewed'}/>
                </div>
                <div className='d-flex align-items-center'>
                    <DLCheckbox
                        id={'mark-as-reviewed-dayCheck'}
                        label={''}
                        isChecked={isReviewed}
                        fontSize={"sm"}
                        disableHelperMessageHeight={true}
                        onChangeValue={() => {
                            handleOpenMarkAsReviewCheckBox()
                        }}
                        labelPlacement={'end'}
                        marginBottom={'none'}
                        isDisabled={isDisable || isManagementUser}
                        checkboxcolor={(!isReviewed && !isManagementUser) ? appTheme.appColor.black : appTheme.appColor.gray}
                    />
                </div>
            </div>

        );
    };

    const renderLatestActivityTime = () => {
        const {generatedTime, uploadedTime} = props;

        let generatedDate = new Date(generatedTime);
        let uploadedDate = new Date(uploadedTime);

        if (generatedDate > uploadedDate || (!isEmpty(generatedDate) && isEmpty(uploadedDate))) {
            let formatedGeneratedTime = getTimeFromNow(generatedTime, 'DD-MM-YYYY');
            return (
                <div>
                    <DLText id={''}
                            text={"Generated "}
                            fontSize={'xs'}
                            fontWeight={"normal"}
                            isInline={true}
                            marginBottom={'none'}
                    />
                    <DLText id={''}
                            text={formatedGeneratedTime}
                            fontSize={'xs'}
                            fontWeight={"normal"}
                            fontColor={"disable"}
                            isInline={true}
                            marginBottom={'none'}
                    />
                </div>
            )
        }

        if (generatedDate < uploadedDate || (isEmpty(generatedDate) && !isEmpty(uploadedDate))) {
            let formatedUploadedTime = getTimeFromNow(uploadedTime, 'DD-MM-YYYY');
            return (
                <div>
                    <DLText id={''}
                            text={"Uploaded "}
                            fontSize={'xs'}
                            fontWeight={"normal"}
                            isInline={true}
                            marginBottom={'none'}
                    />
                    <DLText id={''}
                            text={formatedUploadedTime}
                            fontSize={'xs'}
                            fontWeight={"normal"}
                            fontColor={"disable"}
                            isInline={true}
                            marginBottom={'none'}
                    />
                </div>
            )
        }
        return ''
    };

    const renderFormPdf = () => {
        return (
            <div className={"me-2"}>
                <div className='data-label'
                     style={{minWidth: '50px'}}>
                    <DLText id={'kyc-Summary'}
                            fontSize={'xs'}
                            fontColor={'grayDark'}
                            text={'Bank Mandate Form'}/>
                </div>
                <div className='d-flex'>
                    <DLText id={''}
                            text={isEmpty(fileName) ? '-' : getTrimmedValue(fileName, 12)}
                            fontSize={'sm'}
                            fontWeight={"semi-bold"}
                            isClickable={!isEmpty(filePath)}
                            onClick={() => {
                                downloadAndViewDocument('View')
                            }}
                            fontColor={isEmpty(filePath) ? "black" : "darkBlue"}
                            marginBottom={'none'}
                    />
                    {renderButtons()}
                </div>
                {renderLatestActivityTime()}
            </div>
        )
    };

    const renderButtons = () => {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <div className="mx-3">
                    <i className={"fa-solid fa-download " + (isEmpty(filePath) ? ' disable-icon' : ' default-icon cursor')}
                       onClick={() => {
                           if (!isEmpty(filePath)) {
                               downloadAndViewDocument('Download')
                           }
                       }}
                    />
                </div>

                <div className="me-2">
                    {isEmpty(IINNumber) ?
                        <i className={"fa-solid fa-rotate"+ ((isEmpty(IINNumber) || isManagementUser) ? ' disable-icon' : ' default-icon cursor')}/>
                        :
                        <DLTooltip tooltipPlacement={'right'}
                                   tooltipLabel={
                                       <div onClick={() => {
                                           if(!isManagementUser){
                                               regenerateForm()
                                           }
                                       }}>
                                           <i className={"fa-solid fa-rotate" + ((isEmpty(IINNumber) || isManagementUser) ? ' disable-icon' : ' default-icon cursor')}/>
                                       </div>
                                   }
                                   id={''}
                                   customUi={true}
                                   tooltipContent={<DLText id={''}
                                                           fontSize={'xs'}
                                                           text={'Regenerate'}
                                                           marginBottom={'none'}/>}
                        />
                    }
                </div>
            </div>
        );
    };

    const renderUploadModal = () => {
        const {isUploadECSFormModalOpen} = props;

        if (isUploadECSFormModalOpen) {
            return (
                <UploadBankMandate {...props}/>
            )
        }

    };

    const renderMarkAsReviewedModal = () => {
        const {isMarkAsReviewedModalOpen} = props;

        if (isMarkAsReviewedModalOpen) {
            return (
                <Reviewed {...props} />
            )
        }

    };


    const renderAddRemarkModal = () => {
        const {isAddRemarkModalOpen} = props;

        if (isAddRemarkModalOpen) {
            return (
                <AddRemark {...props}/>
            )
        }
    };

    const renderBankMandateStatus = () =>{
        const {escFormSubmitStatus} = props;
        let status = escFormSubmitStatus;
        if(status !== '-' && !isEmpty(status)){
            if(status === 'ACCEPTED'){
                return <span style={{maxWidth: 'max-content'}} className={'badge-green mx-0'}>Accepted</span>
            }else if(status === 'REJECTED'){
                return <span style={{maxWidth: 'max-content'}} className={'badge-red mx-0'}>Rejected</span>
            }else if(status === 'CANCELLED'){
                return <span style={{maxWidth: 'max-content'}} className={'badge-amber mx-0'}>Cancelled</span>
            }else if(status === 'PENDING'){
                return <span style={{maxWidth: 'max-content'}} className={'badge-amber mx-0'}>Pending</span>
            }else{
                return <span style={{maxWidth: 'max-content'}} className={'badge-amber mx-0'}>{status}</span>
            }
        }
    };

    const renderHeader = () => {

        return (
            <div>
                <div className={'d-flex justify-content-between align-items-center  pt-3' + (isMobileView ? ' px-0 ' : ' px-3')}>
                    <div className='d-flex align-items-end' style={{gap: 4}}>
                   <DLText id={'iin-form-id'}
                                text={"Bank Mandate (ECS)"}
                                fontSize={"lg"}
                                marginBottom={'none'}
                                fontWeight={"semi-bold"}
                        />
                    </div>
                    <div className='d-flex align-items-center'>
                        <div className={isMobileView ? 'px-2':''}>
                            {(!isEmpty(IINNumber)) && !isEmpty(formId) && <DLMenuOptions
                                onSelect={(value) => selectedOption(value)}
                                options={
                                    isManagementUser ? [
                                            {label: "View Activities", value: "View Activities"}
                                        ] :
                                        [{label: "Add a Remark", value: "Add a Remark"},
                                            {label: "View Activities", value: "View Activities"}]}
                                id={"IINFormAction"}
                            />}
                        </div>
                    </div>
                </div>
                <div className={' pb-3 ' + (isMobileView ? '' : ' px-3 border-bottom')}>
                    {renderBankMandateStatus()}
                </div>
            </div>
        );
    };

    const renderViewActivity = () => {
        const {isMobileView, handleCloseViewActivitiesModal, isViewActivitiesModalOpen} = props;
        if (isViewActivitiesModalOpen) {
            if (isMobileView) {
                return <div className="modal-overlay" style={{zIndex: 1100}} onClick={handleCloseViewActivitiesModal}>
                    <div className="modal-content box-shadow " onClick={(e) => e.stopPropagation()}>
                        <ViewActivity {...props} isOverlay={true}/>
                    </div>
                </div>
            } else {
                return (
                    <ViewActivity {...props}/>
                )
            }
        }
    };

    const renderLoader = () => {
        return (<DLLoader type={"screen"} isVisible={isShowLoader}/>);
    };

    if (props.UIState === 'CONTENT_UNAVAILABLE' || isEmpty(props.UIState)) {
        return ''
    }

    const renderErrorUI = () => {
        const {responseErrors, isMobileView} = props;

        if (isEmpty(responseErrors)) {
            return '';
        }

        return (
            <div id='page-level-errors'
                 className={isMobileView ? '' : 'mb-2'}>
                <div className='pb-2 align-items-center d-flex message-block'>
                    <div className='error-vertical-line'/>
                    <div className='error-panel'>
                        {responseErrors}
                    </div>
                </div>
            </div>
        );
    };

    const renderSwitch = () => {
        const {electronicConsent, setElectronicConsent, onboardingStatus, channelTypeOpt, selectedChannelType, selectChannelType} = props;
        const {escFormSubmitStatus} = props;
        let isDisabled =  (isEmpty(IINNumber) || (bankMandateStatus === 'ACCEPTED') || (escFormSubmitStatus === 'PENDING' || escFormSubmitStatus === 'ACCEPTED'));

        return (
            <div>
                <div className="d-flex justify-content start align-items-center pb-3">
                    <div className={"me-2"}>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontColor={"primary"}
                                text={'Electronic Consent'}/>
                    </div>
                    <ButtonGroup style={{
                        textTransform: 'none',
                        maxHeight: '30px',
                        borderRadius: '25px',
                        backgroundColor: '#E8EEF8',

                    }}
                                 variant="outlined"
                                 className='btn-group '
                                 aria-label=" button group">
                        <Button id={'major-toggle'}
                                className={electronicConsent ? " bg-white m-1 py-1" : "py-1"}
                                style={{
                                    textTransform: 'none',
                                    border: 'none',
                                    fontFamily: appTheme.default.fontFamily,
                                    fontWeight: electronicConsent ? 500 : 400,
                                    color: isDisabled ? appTheme.appColor.disable : electronicConsent ? 'var(--primary)' : 'var(--gray-dark)',
                                    borderRadius: '25px',
                                    fontSize: 14,
                                    cursor: isDisabled? 'auto': 'pointer'
                                }}
                                onClick={() => {
                                    if(!isDisabled){
                                        setElectronicConsent(true)
                                    }
                                }}
                        >
                            Yes
                        </Button>
                        <Button id={'minor-toggle'}
                                className={!electronicConsent ? " bg-white m-1 py-1" : "py-1"}
                                style={{
                                    textTransform: 'none',
                                    border: 'none',
                                    fontFamily: appTheme.default.fontFamily,
                                    fontWeight: !electronicConsent ? 500 : 400,
                                    color:isDisabled ? appTheme.appColor.disable : !electronicConsent ? 'var(--primary)' : 'var(--gray-dark)',
                                    borderRadius: '25px',
                                    fontSize: 14,
                                    cursor: isDisabled? 'auto': 'pointer'
                                }}
                                onClick={() => {
                                    if(!isDisabled){
                                        setElectronicConsent(false)
                                    }
                                }}
                        >
                            No
                        </Button>
                    </ButtonGroup>

                </div>
                {electronicConsent ?
                    <div className={"me-2"}>
                        <DLText id={''}
                                text={"The investor will give consent electronically via one of the following methods"}
                                fontSize={'sm'}
                                fontWeight={"normal"}
                                marginBottom={'none'}
                        />
                        <div className='mt-3'/>
                        <DLText id={'kyc-Summary'}
                                fontSize={'xs'}
                                fontColor={"primary"}
                                marginBottom={"none"}
                                text={'Electronic Consent Method'}/>
                        <div className='ps-2'>
                            <DLRadioGroup
                                id={'hours-radioBtn'}
                                label={''}
                                isDisabled={isDisabled}
                                type={'inline'}
                                radioType={'multiple'}
                                options={channelTypeOpt}
                                value={selectedChannelType}
                                disableHelperMessageHeight
                                disableDescriptionMessageHeight
                                marginBottom={'xs'}
                                labelFontColor={'black'}
                                helperMessageColor={'danger'}
                                isRequired={true}
                                onChangeValue={(e) => {
                                    selectChannelType(e)
                                }}
                            />
                        </div>
                    </div>
                    :
                    <div className={"me-2 mb-2"}>
                        <DLText id={''}
                                text={"In this mode please upload the form with investor signature"}
                                fontSize={'sm'}
                                fontWeight={"normal"}
                                marginBottom={'md'}
                        />
                        <DLButton id={'delete-button'}
                                  buttonSize={'sm'}
                                  fontSize={'sm'}
                                  sideMargin={'none'}
                                  styleType={"outlined"}
                                  isDisabled={isDisabled || isManagementUser}
                                  onClick={() => {
                                      selectedOption('Upload')
                                  }}
                                  label={"Upload Signed Form"}/>
                    </div>
                }
            </div>
        )
    };


    return (
        <div className={isMobileView ? 'mx-0' : "mx-0 panel p-0"}>
            {renderHeader()}

            <div className={'panel-container' + (isMobileView ? 'px-0' : '')}
                 style={{
                     maxHeight: isMobileView ? 'auto' : isEmpty(escFormSubmitStatus) ? 'calc(100dvh - 280px)' : 'calc(100dvh - 304px)',
                     minHeight: isMobileView ? 'auto' : isEmpty(escFormSubmitStatus) ? 'calc(100dvh - 280px)' : 'calc(100dvh - 304px)',
                     overflow: "auto"
                 }}>
                {renderErrorUI()}
                {(!isEmpty(fileName)) ? <div className="w-100">
                        <div className="d-flex align-items-start justify-content-between pb-3">
                            <div className=''>
                                {renderFormPdf()}
                            </div>
                            <div className=''>
                                {renderMarkAsReviewed()}
                            </div>
                        </div>
                        <div className='pb-3 pt-3 border-top'>
                            {renderSwitch()}
                        </div>
                    </div> :
                    <div className='pb-4 mb-1 pt-3'>
                        <DLButton id={'lost-button'}
                                  type={"black"}
                                  styleType={"outlined"}
                                  isDisabled={isEmpty(IINNumber) || isManagementUser}
                                  onClick={() => {
                                      regenerateForm()
                                  }}
                                  sideMargin={'none'}
                                  padding={'none'}
                                  label={'Generate Form'}
                        />
                    </div>}

                <div>
                    <BankMandateActivity {...props}
                                         isECSSubmitted={isECSSubmitted}
                                         resetECSSubmissionStatus={resetECSSubmissionStatus}
                                         setActivityStatus={setActivityStatus}
                                         setBankMandateStatus={setBankMandateStatus}
                                         setEscFormSubmitStatus={setEscFormSubmitStatus}
                                         submitECS={submitECS}
                                         IINNumber={props.IINNumber}/>
                </div>

                {renderLoader()}
                {renderUploadModal()}
                {renderAddRemarkModal()}
                {renderMarkAsReviewedModal()}
                {renderViewActivity()}
            </div>
        </div>);
};


export default BankMandate;